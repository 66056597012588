const config = {
  id: "setting_1",
  menuIcon: "fa-solid fa-gears",
  label: "Setting",
  path: "/setting-ui",
  children: {
    project: {
      id: "project-settings",
      label: "Project",
      path: "project/:id",
      children: {
        /**
         * Project
         */
        project: {
          id: "setting/project",
          path: "",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Project Info",
          label: "Project Info",
        },

        /**
         * Permission
         */
        permission: {
          id: "permission_1",
          path: "permission",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Permission",
          label: "Permission",
        },

        /**
         * Template
         */
        template: {
          id: "setting/template",
          path: "template",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Template",
          label: "Template",
        },

        /**
         * Common
         */
        common: {
          id: "setting/project/common",
          path: "common",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Common",
          label: "Common",
          children: {
            client: {
              id: "common/client",
              path: "",
              label: "Client",
            },
            company: {
              id: "common/company",
              path: "company",
              label: "Company",
            },
          },
        },

        schedule: {
          id: "setting/schedule",
          path: "schedule",
          label: "Schedule",
          children: {
            activityBlock: {
              id: "schedule/activity_block",
              path: "",
              label: "Activity Block",
            },
          },
        },

        // scheduleItem: {
        //   id: "scheduleItem",
        //   path: "schedule-item",
        //   label: "Schedule Item",
        //   children: {
        //     activityBlock: {
        //       id: "activityBlock",
        //       path: "activity-block",
        //       label: "Activity Block",
        //     },
        //   },
        // },
      },
    },

    tenant: {
      id: "tenant-settings",
      path: "tenant/:id",
      label: "Settings",
      children: {
        permission: {
          id: "permission",
          path: "",
          label: "Permission",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Permission",
        },
        client: {
          id: "tenant/client",
          path: "client",
          label: "Client",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Permission",
          children: {
            view: {
              id: "tenant/client/view",
              path: "",
              label: "",
            },
            form: {
              id: "tenant/client/form",
              path: "form",
              label: "",
            },
          },
        },
        company: {
          id: "tenant/company",
          path: "company",
          label: "Company",
          toolbarLabel: "Settings",
          breadcrumbLabel: "Permission",
        },
      },
    },

    elements: {
      id: "ui-elements",
      path: "elements",
      label: "Elements",
    },

    profile: {
      id: "profile",
      path: "profile",
      label: "Profile",
      toolbarLabel: "Profile Settings",
      children: {
        basic: {
          id: "profile/basic",
          label: "Personal Info",
          path: "",
        },
        myProfile: {
          id: "profile/my-profile",
          label: "My Profile",
          path: "my-profile",
        },
        identification: {
          id: "profile/identification",
          label: "Identification",
          path: "identification",
        },
        visaInformation: {
          id: "profile/visa-information",
          label: "Visa Information",
          path: "visa-information",
        },
        businessRegistration: {
          id: "profile/business-registration",
          label: "Business Registration",
          path: "business-registration",
        },
        rateCard: {
          id: "profile/rate-card",
          label: "Rate Cards",
          path: "rate-cards",
        },
        // travelDocuments: {
        //   id: "profile/travel-documents",
        //   label: "Travel Documents",
        //   path: "travel-documents",
        // },
        healthDeclarations: {
          id: "profile/health-declarations",
          label: "Health Declarations",
          path: "health-declarations",
        },
        invoicing: {
          id: "profile/invoicing",
          label: "Invoicing",
          path: "invoicing",
        },
        subscription: {
          id: "profile/subscription",
          label: "Subscription",
          path: "subscription",
        },
        systemPreferences: {
          id: "profile/system-preferences",
          label: "System Preferences",
          path: "system-preferences",
        },
      },
    },
  },
};

export default config;
