import API_ENDPOINTS from "../../../../../services/api-endpoints";
import API_Project from "../../../../../services/api-project";
import { toast } from "react-toastify";

import { hideAddToProjectDialog } from "../../Onboarding/AddToProjectDialog/store";
import { updateProjectMemberStatus } from "../../../../../Actions/Project";

export const getInvitationDetails = (ProjectId, ProjectMemberId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Project.get(
      `${endpoints.PROJECT_GET_Invitation_Details_By_Project_And_MemberId}/${ProjectId}/${ProjectMemberId}`
    )
      .then((res) => {
        console.info("SUCCESS: getInvitationDetails :::", res);
        resolve(res.data.result);
      })
      .catch((err) => {
        console.error("ERROR : getInvitationDetails :::", err);
        reject({ error: err });
      });
  });
};

/*
Update-parameters

{
  "projectInvitationId": 0,
  "mailReference": "string",
  "projectId": 0,
  "projectMemberId": 0,
  "roleId": 0,
  "startDate": "2025-01-08T15:58:07.945Z",
  "endDate": "2025-01-08T15:58:07.945Z",
  "assignedEpisodeIds": [
    0
  ]
}

*/
export const updateProjectMemberInvitation = async (
  projectInvitationId,
  mailReference,
  projectId,
  projectMemberId,
  roleId,
  startDate,
  endDate,
  assignedEpisodeIds,
  memberPrevStatus,
  memberUserId
) => {
  try {
    const endpoints = new API_ENDPOINTS();
    const response = await API_Project.put(
      endpoints.PROJECT_UPDATE_ONBOARDING_DETAILS,
      {
        projectInvitationId: projectInvitationId,
        mailReference: mailReference || "",
        projectId: projectId,
        projectMemberId: projectMemberId,
        roleId: roleId || null,
        startDate: startDate || null,
        endDate: endDate || null,
        assignedEpisodeIds: assignedEpisodeIds || [],
      }
    );

    if (response.data.isSuccess) {
      toast.success(response.data.displayMessage);
      hideAddToProjectDialog();
      console.info("Update Successful: ", response.data);

      const updateMemberStatusPayload = {
        projectId: projectId,
        userId: memberUserId,
        memberStatusId: memberPrevStatus,
      };
      updateProjectMemberStatus(updateMemberStatusPayload);

    } else {
      console.error("Update Failed: ", response.data.displayMessage);
    }
  } catch (err) {
    console.error("Error updating project member invitation: ", err);
  }
};
