import styled from "styled-components";

export const PageView = styled.div`
  /* max-width: 700px; */
  width: 100%;
  margin: 0 auto;

  .e-de-ctn {
    border: none;
  }

  .e-de-ctnr-properties-pane {
    height: 100%;
    border-bottom: none;
  }

  .e-de-status-bar {
    display: none !important;
  }
`;
