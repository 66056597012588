import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import parseISO from "date-fns/parseISO";

import { getAssignTemplatesByProjectId } from "../../../../../Actions/Setting/Templates";
import FieldGroup from "../../../../../common/components/Form/FieldGroup";
import SelectField from "../../../../../common/components/Form/SelectField";
import SelectionField from "../../../../../common/components/Form/SelectionField";
import TextAreaField from "../../../../../common/components/Form/TextAreaField";
import Button from "../../../../../common/components/Buttons/UIButton";
import DatePickerField from "../../../../../common/components/Form/DatePickerField";
import { getAllJobTitles } from "../../../../../Actions/Common";
import {
  getInvitationDetails,
  updateProjectMemberInvitation,
} from "../../API/HrList";
import { getProjectEpisodes } from "../../API/Project";
import {
  getAndBindRoleList,
  AddMemberToProject,
} from "../../API/Onboarding/index";
import { useAddToProjectStore } from "./store";
import useDefaultTemplateDoc, {
  TEMPLATE_DOC_TYPES,
} from "../../../../../hooks/useDefaultTemplateDoc";

const initialValues = {
  role: null, // hr, admin etc..
  jobTitle: null, //colorist, editor, cameraman etc..
  startDate: "",
  endDate: "",
  episodes: [],
  isContractNotRequired: false,
  message: "",
};

const validationSchema = Yup.object().shape({
  role: Yup.object()
    .shape({ label: Yup.string(), value: Yup.string() })
    .nullable()
    .when("isContractNotRequired", {
      is: true,
      then: Yup.object().nullable(false).required("This field is required"),
      otherwise: Yup.object().nullable(true),
    }),
  jobTitle: Yup.object()
    .shape({ label: Yup.string(), value: Yup.string() })
    .nullable()
    .required("This field is required"),
  startDate: Yup.string().when("isContractNotRequired", {
    is: false,
    then: Yup.string().nullable(false).required("This field is required"),
    otherwise: Yup.string().nullable(true),
  }),
  endDate: Yup.string().when("isContractNotRequired", {
    is: false,
    then: Yup.string().nullable(false).required("This field is required"),
    otherwise: Yup.string().nullable(true),
  }),
  episodes: Yup.array().when("isContractNotRequired", {
    is: false,
    then: Yup.array()
      .nullable(false)
      .min(1, "At least one episode is required")
      .required("This field is required"),
    otherwise: Yup.array().nullable(true),
  }),
  isContractNotRequired: Yup.boolean(),
  message: Yup.string().when("isEditMode", {
    is: false,
    then: Yup.string().when("isContractNotRequired", {
      is: false,
      then: Yup.string().nullable(false).required("This field is required"),
      otherwise: Yup.string().nullable(true),
    }),
    otherwise: Yup.string().nullable(true),
  }),
});

const Form = (Project) => {
  const [isOnboardingByPass, setIsOnboardingByPass] = React.useState(false);
  const [episodesOptions, setEpisodesOptions] = React.useState([]);
  const [roleOptions, setRoleOptions] = React.useState([]);
  const [jobTitleList, setJobTitleList] = React.useState([]);
  const [jobTitleId, setJobTitleId] = React.useState(null);
  const [memberInvitationDetails, setMemberInvitationDetails] = React.useState(
    []
  );
  const selectedProjectMember = useAddToProjectStore((state) => state.meta);
  const isEditMode = useAddToProjectStore((state) => state.isEditMode);
  const project = useAddToProjectStore((state) => state.project);

  const ndaDefaultTemplateDoc = useDefaultTemplateDoc({
    jobTitleId: jobTitleId,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const role = values.role?.value || 0; // User role
        const jobTitle = values.jobTitle?.value || 0; // Job title
        const isOnboardingByPassResult = isOnboardingByPass;
        const _PROJECT_ = Project?.Project;
        const senderNote = values.message;
        const startDate = values.startDate;
        const endDate = values.endDate;
        const assignedEpisodes = values.episodes;
        const ndaDefaultDoc = ndaDefaultTemplateDoc;
        //const isContractNotRequired = values.isContractNotRequired;

        if (!isEditMode) {
          await AddMemberToProject({
            selectedProjectMember,
            role,
            isOnboardingByPassResult,
            ndaDefaultDoc,
            _PROJECT_,
            senderNote,
            startDate,
            endDate,
            assignedEpisodes,
            jobTitle,
          });
        } else {
          const projectInvitationId =
            memberInvitationDetails?.projectInvitationId;
          const mailReference = memberInvitationDetails?.mailReference;
          const projectId = memberInvitationDetails?.projectId;
          const projectMemberId = memberInvitationDetails?.projectMemberId;
          const assignedEpisodeIds = assignedEpisodes.map(
            (episode) => episode.value
          );
          const memberPrevStatus =
            memberInvitationDetails?.memberPreviousStatus;
          const memberUserId = selectedProjectMember?.memberUserId;
          console.info("memberInvitationDetails: ", memberInvitationDetails);
          console.info("selectedProjectMember: ", selectedProjectMember);

          await updateProjectMemberInvitation(
            projectInvitationId,
            mailReference,
            projectId,
            projectMemberId,
            jobTitle,
            startDate,
            endDate,
            assignedEpisodeIds,
            memberPrevStatus,
            memberUserId
          );
        }
      } catch (err) {
        console.error("Failed to add member", err);
        toast.error(
          "An error occurred while adding the member to the project."
        );
      }
    },
  });

  const ProjectId = Project?.Project?.projectId;

  React.useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const episodes = await getProjectEpisodes(ProjectId);
        setEpisodesOptions(
          episodes.map((ep) => ({
            label: ep.title || "UnNamed Episode",
            value: ep.episodeId,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch episodes: ", error);
      }
    };

    const fetchRoleList = async () => {
      try {
        const roleList = await getAndBindRoleList();
        setRoleOptions(roleList);
      } catch (error) {
        console.error("Failed to fetch role list: ", error);
      }
    };

    const fetchJobTitles = async () => {
      try {
        const jobTitles = await getAllJobTitles();
        setJobTitleList(jobTitles);
      } catch (error) {
        console.error("Failed to fetch JobTitles: ", error);
      }
    };

    fetchEpisodes();
    fetchRoleList();
    fetchJobTitles();
  }, []);

  React.useEffect(() => {
    const fetchInvitationDetails = async () => {
      try {
        const InvitationDetails = await getInvitationDetails(
          project?.projectId,
          selectedProjectMember?.projectMemberId
        );
        setMemberInvitationDetails(InvitationDetails);
      } catch (error) {
        console.error("Failed to fetch Invitation Details : ", error);
      }
    };

    if (isEditMode) {
      fetchInvitationDetails();
    }
  }, [selectedProjectMember, isEditMode]);

  React.useEffect(() => {
    if (memberInvitationDetails) {
      const role = jobTitleList.find(
        (option) => option.value === memberInvitationDetails.roleId
      );
      //const jobTitle = { value: selectedProjectMember?.projectRoleId, label: selectedProjectMember?.projectRoleName };
      const startDate = memberInvitationDetails.startDate;
      const endDate = memberInvitationDetails.endDate;
      const assignedEpisodes = memberInvitationDetails.assignedEpisodes;
      const transformedAssignedEpisodes = assignedEpisodes
        ? assignedEpisodes.map((episode) => ({
            label: episode.title,
            value: episode.episodeId,
          }))
        : [];

      if (isEditMode) {
        setFieldValue("jobTitle", role);
        setFieldValue("startDate", startDate);
        setFieldValue("endDate", endDate);
        setFieldValue("episodes", transformedAssignedEpisodes);
      }
    }
  }, [
    jobTitleList,
    memberInvitationDetails,
    selectedProjectMember,
    isEditMode,
  ]);

  React.useEffect(() => {
    if (project && isEditMode != true) {
      const startDate = project?.startDate;
      const endDate = project?.endDate;
      const jobTitle = jobTitleList.find(
        (option) => option.value === selectedProjectMember?.jobTitleId
      );
      setFieldValue("startDate", startDate);
      setFieldValue("endDate", endDate);
      setFieldValue("jobTitle", jobTitle);
      setJobTitleId(jobTitle?.value);
    }
  }, [project, selectedProjectMember, jobTitleList]);

  const { errors, values, touched, handleChange, handleSubmit, setFieldValue } =
    formik;

  return (
    <form
      className="css-grid"
      onSubmit={(e) => {
        e.preventDefault();
        console.log("Form onSubmit triggered");
        handleSubmit(e);
      }}
    >
      <div className="css-grid css-grid-cols-2 css-gap-16">
        <div className="css-col-span-2" hidden={!isOnboardingByPass}>
          <FieldGroup error={touched.role && errors.role}>
            <SelectField
              options={roleOptions}
              value={values.role}
              onChange={(val) => setFieldValue("role", val)}
            />
          </FieldGroup>
        </div>
        <div className="css-col-span-2">
          <FieldGroup label="Job Title" error={touched.role && errors.jobTitle}>
            <SelectField
              options={jobTitleList}
              value={values.jobTitle}
              onChange={(val) => {
                setFieldValue("jobTitle", val);
                setJobTitleId(val?.value);
              }}
            />
          </FieldGroup>
        </div>

        <div className="css-col-span-1" hidden={isOnboardingByPass}>
          <FieldGroup
            label="Start Date"
            error={touched.startDate && errors.startDate}
          >
            <DatePickerField
              value={values.startDate}
              toDate={parseISO(values.endDate)}
              onDateSelect={(d) => {
                setFieldValue("startDate", d);
              }}
            />
          </FieldGroup>
        </div>

        <div className="css-col-span-1" hidden={isOnboardingByPass}>
          <FieldGroup
            label="End Date"
            error={touched.endDate && errors.endDate}
          >
            <DatePickerField
              value={values.endDate}
              fromDate={parseISO(values.startDate)}
              onDateSelect={(d) => {
                setFieldValue("endDate", d);
              }}
            />
          </FieldGroup>
        </div>

        <div className="css-col-span-2" hidden={isOnboardingByPass}>
          <FieldGroup
            label="Assigned Episodes"
            error={touched.episodes && errors.episodes}
          >
            <SelectField
              isMulti
              options={episodesOptions}
              value={values.episodes || []}
              onChange={(val) => {
                setFieldValue("episodes", val);
              }}
            />
          </FieldGroup>
        </div>

        <div className="css-col-span-2" hidden={isEditMode}>
          <FieldGroup>
            <SelectionField
              text="Contract not required"
              name="isContractNotRequired"
              checked={isOnboardingByPass}
              onChange={(e) => {
                setIsOnboardingByPass(e.target.checked);
                setFieldValue("isContractNotRequired", e.target.checked);
              }}
            />
          </FieldGroup>
        </div>

        <div
          className="css-col-span-2"
          hidden={isOnboardingByPass || isEditMode}
        >
          <FieldGroup label="Message" error={touched.message && errors.message}>
            <TextAreaField
              name="message"
              value={values.message}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>
      </div>

      <div className="css-flex css-justify-between css-items-center css-mt-32">
        <Button
          variant={Button.Variant.Neutral}
          size={Button.Sizes.Small}
          type="button"
        >
          Cancel
        </Button>

        <Button type="submit" size={Button.Sizes.Small}>
          {isEditMode ? "Update" : "Add & Send Invitation"}
        </Button>
      </div>
    </form>
  );
};

export default Form;
