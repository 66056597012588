import React from "react";

import Form from "./Form";
import { BaseDialog } from "./styled";
import { hideAddToProjectDialog, useAddToProjectStore } from "./store";
import Avatar from "../../../../../common/components/UI/Avatar";

function AddToProjectDialog() {
  const isVisible = useAddToProjectStore((state) => state.visibility);
  const selectedProject = useAddToProjectStore((state) => state.project);
  const isEditMode = useAddToProjectStore((state) => state.isEditMode);
  const Title = "Add to project - " + selectedProject?.projectName;
  const EditModeTitle = "Edit Details - " + selectedProject?.projectName;

  return (
    <BaseDialog
      visibility={isVisible}
      title={isEditMode? EditModeTitle : Title}
      bodyComponent={({ props }) => <BodyComponent {...props} />}
      onClose={hideAddToProjectDialog}
    />
  );
}

function BodyComponent(props) {
  const meta = useAddToProjectStore((state) => state.meta);
  const selectedProject = useAddToProjectStore((state) => state.project);

  return (
    <div className="css-grid css-gap-16">
      {/* Member */}
      <div className="css-flex css-gap-16 css-items-center">
        <Avatar src={meta?.profileImage || null} size="size-60" />
        <div>
          <div className="css-font-base-600 css-text-black">
            {meta?.displayName}
          </div>
          <div className="css-font-label-400 css-text-neutral-100">
            {meta?.jobTitleName}
          </div>
        </div>
      </div>

      {/* Form */}
      <div>
        <Form Project={selectedProject}/>
      </div>
    </div>
  );
}

export default AddToProjectDialog;
