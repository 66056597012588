import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import LineInputGroup from "../../../Form/LineInputGroup";
import { useAppStore } from "../../../../../store/AppStore/AppStore";
import { TEMPLATE_TYPES } from "../../../../../constant/System";

// Form initial values
const initialValues = {
  role: "",
  template: "",
  reportOfficer: "",
  sDate: "",
  eDate: "",
  contractFee: "",
};

// Validation Schema
const validationSchema = Yup.object().shape({
  role: Yup.string().required("This field is required"),
  contractFee: Yup.string().required("This field is required"),
  template: Yup.string().required("This field is required"),
  reportOfficer: Yup.string().required("This field is required"),
  sDate: Yup.date().required("This field is required"),
  eDate: Yup.date()
    .required("This field is required")
    .when(
      "sDate",
      (started, yup) =>
        started && yup.min(started, "End Date cannot be before start date")
    ),
});

const GenerateContractForm = (props, ref) => {
  const { onFormSubmitted, projectDuration, contractInfo } = props;

  const jobTitles = useAppStore((state) => state.jobTitles);

  const projectAssignTemplates = useAppStore(
    (state) => state.projectAssignTemplates
  );
  console.log("projectAssignTemplates", projectAssignTemplates);
  const formRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    onSaveGenerateContract: () => {
      formik.handleSubmit();
      //   formRef.current.onSubmit()
    },
  }));

  const onSubmit = (values, actions) => {
    onFormSubmitted &&
      onFormSubmitted(values, () => {
        // formik.resetForm();
        // console.log(actions);
        // actions.resetForm();
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { errors, values, handleChange, touched, setFieldValue } = formik;

  const { start, end } = projectDuration;

  // const { contractFee, jobTitleId, hrInstruction, templateId } = contractInfo;

  React.useEffect(() => {
    if (contractInfo?.startDate) {
      setFieldValue(
        "sDate",
        contractInfo?.startDate.replace("T00:00:00", "") || ""
      );
    }

    if (contractInfo?.endDate) {
      setFieldValue(
        "eDate",
        contractInfo?.endDate.replace("T00:00:00", "") || ""
      );
    }

    if (contractInfo?.contractFee) {
      setFieldValue("contractFee", contractInfo?.contractFee || "");
    }

    if (contractInfo?.jobTitleId) {
      setFieldValue("role", contractInfo?.jobTitleId || "");
    }

    if (contractInfo?.templateId) {
      setFieldValue("template", contractInfo?.templateId || "");
    }

    if (contractInfo?.reportingPersonInfo) {
      setFieldValue("reportOfficer", contractInfo?.reportingPersonInfo || "");
    }
  }, [
    setFieldValue,
    contractInfo?.contractFee,
    contractInfo?.jobTitleId,
    contractInfo?.templateId,
    contractInfo?.reportingPersonInfo,
    contractInfo?.startDate,
    contractInfo?.endDate,
  ]);

  const filterContractTemplates = React.useMemo(() => {
    return projectAssignTemplates.filter(
      (t) => t.templateTypeId.toString() === TEMPLATE_TYPES.CONTRACT.toString()
    );
  }, [projectAssignTemplates]);

  console.log("values.sDate ", values.sDate);
  return (
    <form onSubmit={formik.handleSubmit} className="inputs__wr" ref={formRef}>
      <LineInputGroup label="Job Title" error={touched.role && errors.role}>
        <select
          className="select"
          name="role"
          value={values.role}
          onChange={handleChange}
        >
          <option value="">Select</option>
          {jobTitles.map((j, i) => (
            <option key={i} value={j?.jobTitleId}>
              {j?.jobTitleName}
            </option>
          ))}
        </select>
      </LineInputGroup>

      <LineInputGroup
        label="Template"
        error={touched.template && errors.template}
      >
        <select
          className="select"
          name="template"
          value={values.template}
          onChange={handleChange}
        >
          <option value="">Select Template</option>
          {filterContractTemplates.map((t, i) => (
            <option key={i} value={t?.templateId}>
              {t?.templateName}
            </option>
          ))}
        </select>
      </LineInputGroup>

      <LineInputGroup label="Start Date" error={touched.sDate && errors.sDate}>
        <input
          type="date"
          placeholder="Start Date"
          name="sDate"
          value={values.sDate}
          onChange={handleChange}
        />
      </LineInputGroup>

      <LineInputGroup label="End Date" error={touched.eDate && errors.eDate}>
        <input
          type="date"
          placeholder="End Date"
          name="eDate"
          value={values.eDate}
          onChange={handleChange}
        />
      </LineInputGroup>

      <LineInputGroup
        label="Report officer/s"
        error={touched.reportOfficer && errors.reportOfficer}
      >
        <input
          type="text"
          placeholder="Enter Report Officer/s"
          name="reportOfficer"
          value={values.reportOfficer}
          onChange={handleChange}
        />
      </LineInputGroup>

      <LineInputGroup
        label="Contract Fee"
        error={touched.contractFee && errors.contractFee}
      >
        <input
          className="text-align-right"
          type="text"
          placeholder="Enter Contract Fee"
          name="contractFee"
          value={values.contractFee}
          onChange={handleChange}
          maxLength={8}
        />
      </LineInputGroup>
    </form>
  );
};

export default React.forwardRef(GenerateContractForm);
