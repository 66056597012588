import GLOBAL_API from "../../../../../services/api";
import { PROFILE_UPDATE_ENDPOINTS } from "./profileUpdate.endpoints";

export const getAllMandatoryProfileDetails = async (__userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${PROFILE_UPDATE_ENDPOINTS.GET_MANDATORY_PROFILE_DETAILS}/${__userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveMandatoryProfileDetails = async (__data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await GLOBAL_API.put(
        `${PROFILE_UPDATE_ENDPOINTS.SAVE_MANDATORY_PROFILE_DETAILS}`,
        __data
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }
      console.log("saveMandatoryProfileDetails:: ", res);

      const data = res?.data;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
