import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import UIButton from "../../Buttons/UIButton/index";
import Modal from "react-modal";
import { ModalStyles, Container, ModalButtons, ImageWrapper } from "./styled";

Modal.setAppElement("#root");

const CropModal = ({ src, isOpen, onSave, onClose }) => {
  const [crop, setCrop] = useState({ aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageRef, setImageRef] = useState(null);

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const getCroppedImg = () => {
    console.log("img src before cropping: ", src);

    if (!imageRef) {
      console.error("Missing image reference");
      return null;
    }

    // full dimensions if user didnt crop
    const crop = completedCrop || {
      width: imageRef.naturalWidth,
      height: imageRef.naturalHeight,
      x: 0,
      y: 0,
    };
    const { width, height, x, y } = crop;

    if (!width || !height) {
      console.error("Invalid crop dimensions");
      return null;
    }

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;
    canvas.width = completedCrop ? width : imageRef.naturalWidth;
    canvas.height = completedCrop ? height : imageRef.naturalHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      imageRef,
      completedCrop ? x * scaleX : 0,
      completedCrop ? y * scaleY : 0,
      completedCrop ? width * scaleX : imageRef.naturalWidth,
      completedCrop ? height * scaleY : imageRef.naturalHeight,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Failed to create Blob from canvas"));
          }
        },
        "image/jpeg",
        0.95
      );
    });
  };

  const handleSave = async () => {
    const croppedBlob = await getCroppedImg();
    if (croppedBlob) {
      onSave(croppedBlob);
    } else {
      console.error("No image blob returned for saving");
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={ModalStyles}
      contentLabel="Crop Image"
    >
      <Container>
        <h2>Resize image</h2>
        <ImageWrapper>
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={(crop) => setCompletedCrop(crop)}
            aspect={1}
          >
            <img
              src={src}
              crossOrigin="anonymous"
              onLoad={(event) => {
                onImageLoaded(event.target);
              }}
              onError={() => console.error("Error loading image")}
            />
          </ReactCrop>
        </ImageWrapper>
        <ModalButtons>
          <UIButton
            type="button"
            variant={UIButton.Variant.Danger}
            size={UIButton.Sizes.Small}
            onClick={onClose}
          >
            Cancel
          </UIButton>
          <UIButton
            type="button"
            variant={UIButton.Variant.Primary}
            size={UIButton.Sizes.Small}
            onClick={handleSave}
          >
            Save
          </UIButton>
        </ModalButtons>
      </Container>
    </Modal>
  );
};

export default CropModal;
