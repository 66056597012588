import { differenceInDays } from "date-fns";
import Avatar from "../../../../../../../../../common/components/UI/Avatar";
import { useScheduleContext } from "../../../context/ScheduleContext";
import { AssignerBlockItem, AssignerElement } from "./styled";

function AssigneeItem({ data, onSelect, isSelected }) {
  const { dates } = useScheduleContext();
  console.log("AssigneeItem data:: ", data);
  return (
    <AssignerElement $isSelected={isSelected} onClick={onSelect}>
      <div className="assigner__header">
        <div className="assigner-avatar">
          <Avatar
            size="size-20"
            src={
              data?.profileImageUrl ||
              "https://showruncs.blob.core.windows.net/loop-profile-dev/09114d2c-6584-480b-919c-76ea94959452-2023-07-25-04-52-25-img_avatar.png"
            }
          />
        </div>
      </div>

      <div className="assigner__body">
        <div className="description">{data?.roleName}</div>
        <div className="presentation">
          {/* <AssignerBlockItem $pos={2} /> */}
          {data?.availability?.map((av, i) => {
            let postion = dates?.findIndex(
              (date) => date.dateId === av.startDateId
            );
            const blockHeight = differenceInDays(
              new Date(av?.endDate),
              new Date(av?.startDate)
            );
            console.log("blockHeight", blockHeight);

            return (
              <AssignerBlockItem
                key={i}
                $pos={postion}
                $daycount={blockHeight || 1}
              />
            );
          })}
        </div>
      </div>
    </AssignerElement>
  );
}

export default AssigneeItem;
