import React from "react";
import { useNavigate } from "react-router-dom";

import { useNotification } from "../../context/NotificationContext";
import { Container, Divider, NotificationItemBlock } from "./styled";
import SwitchField from "../../common/components/Form/SwitchField";
import PlainItem from "./NotificationItems/PlainItem";

const Notifications = () => {
  const {
    count,
    isVisible,
    notificationList,
    onReadNotification,
    onToggleNotification,
  } = useNotification();
  const navigate = useNavigate();

  const [isUnreadOnly, setIsUnreadOnly] = React.useState(true);

  const allNotifications = React.useMemo(() => {
    return notificationList.reduce((prev, current) => {
      return prev.concat(...current?.notifications);
    }, []);
  }, [notificationList]);

  const findNotificationItemByKey = React.useCallback(
    (key) => {
      if (!key) return;

      return allNotifications.find((d) => d?.dataKey === key) || null;
    },
    [allNotifications]
  );

  const readStatusHandler = React.useCallback(
    (key) => {
      const data = findNotificationItemByKey(key);

      if (data?.isRead) {
        return;
      }

      data?.notificationId && onReadNotification(data?.notificationId);
    },
    [findNotificationItemByKey, onReadNotification]
  );

  const handleOnView = React.useCallback(
    (key) => {
      const data = findNotificationItemByKey(key);

      // const origin = window.location.origin;
      // const path = url.replace(url.origin, "");
      const url = new URL(data?.url);
      navigate(url.pathname);
      onToggleNotification();

      // Update notification read status
      readStatusHandler(key);
    },
    [
      findNotificationItemByKey,
      readStatusHandler,
      navigate,
      onToggleNotification,
    ]
  );

  const handleStateSwitch = (e) => {
    setIsUnreadOnly(e.target.checked);
  };

  React.useEffect(() => {
    if (!isVisible) return;

    const viewBtns = Array.from(document.querySelectorAll(".btn-view"));
    const closeBtns = Array.from(document.querySelectorAll(".btn_n_remove"));

    const onView = (e) => {
      handleOnView(e.currentTarget.dataset?.key);
    };

    const onRemove = (e) => {
      readStatusHandler(e.currentTarget.dataset?.key);
    };

    const viewBtnsRemoveListener = (function () {
      const removeEvents = viewBtns.map((btn) => {
        btn.addEventListener("click", onView);

        return () => btn.removeEventListener("click", onView);
      });

      return () => removeEvents.forEach((d) => d());
    })();

    const closeBtnsRemoveListener = (function () {
      const removeEvents = closeBtns.map((btn) => {
        btn.addEventListener("click", onRemove);

        return () => btn.removeEventListener("click", onRemove);
      });

      return () => removeEvents.forEach((d) => d());
    })();

    return () => {
      viewBtnsRemoveListener();
      closeBtnsRemoveListener();
    };
  }, [isVisible, isUnreadOnly, handleOnView, readStatusHandler]);

  React.useEffect(() => {
    if (count > 0) {
      setIsUnreadOnly(true);
      return;
    }

    setIsUnreadOnly(false);
  }, [count]);

  return (
    <Container>
      <div className="notifications" key="notifications">
        <div className="notifications__header">
          <div className="title">Notifications</div>
          <div>
            <SwitchField
              checked={isUnreadOnly}
              onChange={handleStateSwitch}
              label="Unread only"
            />
          </div>
        </div>
        <div className="notifications__content">
          <div className="items">
            <NotificationItems
              data={notificationList}
              isUnreadOnly={isUnreadOnly}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

function NotificationItems({ data = [], isUnreadOnly }) {
  /**
   * Notification items filter with isUnreadOnly property
   * */
  const list = React.useMemo(() => {
    const filterItems = (d = []) => {
      if (isUnreadOnly) {
        return d.filter((notification) => !notification.isRead);
      }

      return d;
    };

    return data
      .map((dateObj) => ({
        ...dateObj,
        notifications: filterItems(dateObj.notifications),
      }))
      .filter((dateObj) => dateObj.notifications.length > 0);
  }, [data, isUnreadOnly]);

  /**
   * If tke list is empty return the default message
   */
  if (!list.length) {
    return (
      <PlainItem title="No Notification yet!">
        We'll notify you once we have something for you
      </PlainItem>
    );
  }

  return list.map((notificationGroup, i) => {
    const { date, notifications } = notificationGroup;
    return (
      <React.Fragment key={i}>
        <Divider>{date}</Divider>
        {notifications.map((notification, i) => {
          const { content } = notification;

          return (
            <NotificationItemBlock
              key={i}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          );
        })}
      </React.Fragment>
    );
  });
}

export default Notifications;
