import styled from "styled-components";

export const DocWrapper = styled.div`
  fieldset.formfield-wrapper {
    margin-top: 0 !important;
    border: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
    width: 100% !important;
  }
`;
