import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import AddToProjectDialog from "../../../shared/components/Modals/Onboarding/AddToProjectDialog";

const Wrapper = styled.div`
  .onb__page__container__iW.extended {
    margin-bottom: 8px;
  }

  .onb__page__container.top__info {
    padding: 24px 30px 0px;
  }

  .onb__page__container.top_bottom_gap,
  .onb__page__container.shortlisted {
    margin-top: 24px;
  }

  .onb__page__actionbar {
    margin-right: 0;
  }
`;

const OnboardingPage = () => {
  return (
    <Wrapper>
      <Outlet />

      <AddToProjectDialog />
    </Wrapper>
  );
};

export default OnboardingPage;
