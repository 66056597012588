/*
-------------------------
    Onboarding Status
-------------------------
*/
// export const ONBOARDING_STATUS = {
//   ShortListed: 1,
//   ProjectAdded: 2,
//   InvitationReceived: 3,
//   InvitationAccepted: 4,
//   InvitationRejected: 5,
//   ContractRequested: 6,
//   ContractChangeRequested: 7,
//   ContractGenerated: 8,
//   ContractSent: 9,
//   ContractSigned: 10,
//   ContractAccepted: 11,
//   ContractRejected: 12,
//   ContractExecuted: 13,
//   ContractRejectedIsAck: 14, // MemberRequestedContract
//   Onboarded: 15,
//   ContractAcceptingByHR: 16, // show only HR ,
//   ContractAcceptedByHR: 17, // Show Onboarding and HR
//   ContractApproved: 18, // Onboarding
//   MemberRequestedContract: 19,
//   MemberContractDeclined: 20,
// };

export const ONBOARDING_STATUS = {
  ShortListed: 1,
  ProjectAdded: 2,
  InvitationReceived: 3,
  InvitationAccepted: 4,
  InvitationRejected: 5,
  NDAGenerated: 6,
  NDAAccepted: 7,
  NDARejected: 8,
  NDAChangeRequested: 9,
  ContractRequested: 10,
  ContractChangeRequested: 11,
  ContractGenerated: 12,
  ContractSent: 13,
  ContractSigned: 14,
  ContractAccepted: 15,
  ContractRejected: 16,
  ContractExecuted: 17,
  ContractRejectedIsAck: 18,
  Onboarded: 19,
  ContractAcceptingByHR: 20,
  ContractAcceptedByHR: 21,
  ContractApproved: 22,
  MemberRequestedContract: 23,
  JoinRequestDeclined: 24,
  JoinDeclinedIsAcknowledged: 25,
  RatesAndPaymentScheduleRequested: 26,
  RatesAndPaymentScheduleChangeRequested: 27, //Depricated
  CommonChangeRequest: 28, //Depricated
  RatesAndPaymentScheduleAccepted: 29,
  RatesAndPaymentScheduleDeclined: 30,
};

export const VIEW_CONTRACT_SOURCES = {
  hr: "hr",
  onboarding: "onboarding",
  outside: "outside",
  // hr: "d947cb8c-f593-4c38-97e0-36237f5411ae",
  // onboarding: "3790d9e7-44f8-4a1d-9d7e-9efcbf8ae72e",
  // outside: "45a3512d-716c-48d2-99c8-e2d1e0525766",
};

export const SOURCE_TYPES = {
  HR: "HR",
  ONBOARDING: "ONBOARDING",
  OUTSIDE: "OUTSIDE",
};
export const INVITATION_STATUS = {
  Accept: 1,
  Reject: 2,
};
export const MONDATORY_DATA_CHECK_TYPE = {
  None: 0,
  NDA: 1,
  Contract: 2,
};
export const TEMPLATE_TYPES = {
  NDA: 1,
  Contract: 2,
  Job_Descriptions: 10,
};
