import React from "react";
import { getAssignTemplatesByProjectId } from "../Actions/Setting/Templates";
import { useGlobalStore } from "../store/GlobalStore";

export const TEMPLATE_DOC_TYPES = {
  NDA: 1,
  Contract: 2,
  Supplies: 3,
  Deliveries: 4,
};

const useDefaultTemplateDoc = ({ jobTitleId }) => {
  const [defaultTemplate, setDefaultTemplate] = React.useState(undefined);
  const [templateId, setTemplateId] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(undefined);

  const projectId = useGlobalStore((state) => state.selectedProjectId);

  React.useEffect(() => {
    if (!projectId || !jobTitleId) return;

    setIsLoading(true);

    getAssignTemplatesByProjectId(projectId)
      .then((data) => {
        setIsLoading(false);

        const matchingTemplate = data.find(
          (template) => template.projectRoleId === jobTitleId
        );
        if (matchingTemplate) {
          console.info("Found Project Template Matching with JobTitleId: ",jobTitleId,": ",matchingTemplate);
          setDefaultTemplate(matchingTemplate);
          setTemplateId(matchingTemplate.templateId);
          setTimeout(() => {
            setIsLoading(undefined);
          }, 100);
          return;
        }
  
        const defaultTemplate = data.find(
          (template) => template.projectRoleId === 0
        );
        if (defaultTemplate) {
          console.info(
            "No Project Template Found Matching with JobTitleId: ",jobTitleId,", Instead Found Default all type template: ",defaultTemplate);
          setDefaultTemplate(defaultTemplate);
          setTemplateId(defaultTemplate.templateId);
          setTimeout(() => {
            setIsLoading(undefined);
          }, 100);
          return;
        }
  
        setDefaultTemplate(null);
        setTemplateId(null);

        setTimeout(() => {
          setIsLoading(undefined);
        }, 100);
      })
      .catch(() => {
        setIsLoading(undefined);
        setTemplateId(undefined);
        setDefaultTemplate(null);
      });
  }, [projectId, jobTitleId]);

  return { defaultTemplate, isLoading, templateId };
};

export default useDefaultTemplateDoc;
