import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import FromGroup from "../../../../../../controls/FromGroup";
import { useTemplateGeneralStore } from "../../../../../../../store/Setting/Templates/TemplateGeneralStore";
import "./styles.scss";
import Button, { BUTTON_ICONS } from "../../../../../Buttons/Button";
import { useAppStore } from "../../../../../../../store/AppStore/AppStore";
import SelectionField from "./../../../../../../../common/components/Form/SelectionField/index";

const initialValues = {
  type: "",
  category: "",
  template: "",
  defaultTemplate: false,
};

// Validation Schema
const validationSchema = Yup.object().shape({
  type: Yup.string().required("This field is required"),
  template: Yup.string().required("This field is required"),
  // category: Yup.string().required("This field is required"),
});

const AssignProjectTemplateForm = (props) => {
  const templateTypeOptions = useTemplateGeneralStore(
    (state) => state.templateTypeOptions
  );

  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);

  const isEditMode = useTemplateGeneralStore((state) => state.isEditMode);
  const formData = useTemplateGeneralStore((state) => state.formData);
  const isFormSubmit = useTemplateGeneralStore((state) => state.isFormSubmit);
  const onResetState = useTemplateGeneralStore((state) => state.onResetState);

  const jobTitles = useTemplateGeneralStore(
    (state) => state.jobTitleOptions
  );
  const jobTitleOptions = [{ value: 0, label: "Apply To All", jobTitleId: 0 }, ...jobTitles];

  const templateOptions = useTemplateGeneralStore(
    (state) => state.templateOptions
  );

  const selectedProjectId = useTemplateGeneralStore(
    (state) => state.selectedProjectId
  );

  const onFormSubmit = useTemplateGeneralStore((state) => state.onFormSubmit);

  const onSubmitHandler = (values, actions) => {
    const payload = {
      projectID: selectedProjectId,
      templateTypeID: values?.type,
      templateId: values?.template,
      projectRoleID: values?.category,
      defaultTemplate: values?.defaultTemplate,
    };

    // Loader
    showLoader();

    onFormSubmit(payload)
      .then((res) => {
        // If not successful show error message
        if (!res?.isSuccess) {
          toast.error(res?.displayMessage);
          hideLoader();
          return;
        }

        // toast.success(res?.displayMessage);
        onClearForm();
        hideLoader();
      })
      .catch((err) => {
        toast.error(err);
        hideLoader();
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmitHandler,
  });

  const {
    errors,
    values,
    handleChange,
    touched,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = formik;

  function onClearForm() {
    resetForm();
    onResetState();
  }

  React.useEffect(() => {
    if (isEditMode) {
      const { typeId, categoryId, templateId, defaultTemplate } = formData;
      console.log("formData:::", formData);

      setFieldValue("type", typeId);
      setFieldValue("category", categoryId);
      setFieldValue("template", templateId);
      setFieldValue("defaultTemplate", defaultTemplate);
    }
  }, [isEditMode, setFieldValue, formData]);

  return (
    <div className="form__container">
      <form onSubmit={handleSubmit}>
        <div className="form__inline">
          <FromGroup
            label="Template Type"
            inputHeightAuto
            error={touched.type && errors.type}
          >
            <select
              name="type"
              className="form-control"
              value={values.type}
              onChange={handleChange}
            >
              <option value="">Select Type</option>
              {templateTypeOptions.map((type, i) => (
                <option key={i} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </FromGroup>

          <FromGroup
            label="Template"
            inputHeightAuto
            error={touched.template && errors.template}
          >
            <select
              name="template"
              className="form-control"
              value={values.template}
              onChange={handleChange}
            >
              <option value="">Select Template</option>
              {templateOptions.map((obj, i) => {
                return (
                  <option key={i} value={obj.value}>
                    {obj.label}
                  </option>
                );
              })}
            </select>
          </FromGroup>

          <FromGroup
            label="Job Category"
            inputHeightAuto
            error={touched.category && errors.category}
          >
            <select
              name="category"
              className="form-control"
              value={values.category}
              onChange={handleChange}
            >
              <option value="">Select job title</option>

              {jobTitleOptions.map((obj, i) => (
                <option key={i} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            </select>
          </FromGroup>

          {/* <FromGroup className="full-column">
            <SelectionField
              name="defaultTemplate"
              text="Mark as a default"
              checked={values.defaultTemplate}
              onChange={handleChange}
            />
          </FromGroup> */}

          <FromGroup className="actions">
            <Button
              leftIcon={BUTTON_ICONS.cancel}
              variant="secondary"
              onClick={onClearForm}
            >
              Cancel
            </Button>

            <Button
              disabled={isFormSubmit}
              type="submit"
              leftIcon={BUTTON_ICONS.done}
            >
              {isEditMode ? "Edit" : "Save"}
            </Button>
          </FromGroup>
        </div>
      </form>
    </div>
  );
};

export default AssignProjectTemplateForm;
