import React from "react";
import config from "./config";
import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";
import { permissionLoader } from "../../../routes/loaders";

const children = config.children;

const RateAndPaymentsPage = React.lazy(() =>
  import("../screens/RateAndPayments")
);
const ViewNDAPage = React.lazy(() => import("../screens/ViewNDA"));

export const ChildrenRoutes = [
  /**
   * Rate And Payments
   */
  {
    id: children.rateAndPayment.id,
    path: children.rateAndPayment.path,
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <RateAndPaymentsPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.rateAndPayment.label} />,
    },
    loader: async () => {
      // return true;
      return await permissionLoader("onboarding", children.rateAndPayment.id);
    },
  },

  /**
   * View NDA
   */
  {
    id: children.viewNDA.id,
    path: children.viewNDA.path,
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <ViewNDAPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.viewNDA.label} />,
    },
    loader: async () => {
      return true;
      // return await permissionLoader(children.planner.id);
    },
  },
];
