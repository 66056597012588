import React, { useState, useEffect } from "react";
import SelectField from "../../../../../../../common/components/Form/SelectField";
import SearchInput from "../../../../../../../common/components/Form/SearchInput";
import { StyledIconButton } from "../../../../../../../styled/Common/Buttons";
import { IconBlock } from "../../../../../../../styled/Common/Icons";
import { Close } from "../../../../../../../common/components/icons/Popup/Popovers";
import useTagTypes from "../../../../../hooks/useTagTypes";
import { getTagsByProjectId } from "../../../../../api/common/tags";
import { createTagRelationship } from "../../../../../api/dynamic-form";
import { getTagRelationshipByParentId } from "../../../../../api/common/tags";
import useIcon from "../../../../../../../common/components/IconSelector/hooks/useIcon";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./style.css";

const AddRelationshipPopup = ({ selectedTagData, onClose }) => {
  const [selectedTagType, setSelectedTagType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]); // filtered tags by tag type and Search
  const [headerText, setHeaderText] = useState("All Tags");
  const [relationships, setRelationships] = useState([]);

  const { tagTypeItems } = useTagTypes("tags-in-modal", {
    enabled: !!true,
  });

  const findIcon = useIcon();

  // Fetch all tags = on component mount || selectedTagData.projectid changes
  useEffect(() => {
    if (!selectedTagData?.projectId) return;
    console.warn("selected Tag data: ", selectedTagData);
    const fetchTags = async () => {
        setLoading(true);
        setError(null);
        try {
          const relationshipsData = await getTagRelationshipByParentId(selectedTagData.tagId);
          console.log("------ relationshipsData: ", relationshipsData);
          setRelationships(relationshipsData);

          const result = await getTagsByProjectId(selectedTagData?.projectId);
          let filteredTags = [];
          if(relationshipsData !== null){
            const childIds = relationshipsData.map((relationship) => relationship.childId);

            filteredTags = result.filter((tag) => tag.tagId !== selectedTagData.tagId && !childIds.includes(tag.tagId));
            console.log(" 11 FilteredTags--- ", filteredTags );
          }else{
            filteredTags = result.filter((tag) => tag.tagId !== selectedTagData.tagId);
            console.log(" 22 FilteredTags--- ", filteredTags );
          }
          
          setTags(filteredTags);
          setFilteredTags([]);
          setHeaderText("All Tags");
        } catch (err) {
          console.error("Error fetching tags:", err);
          setError("Failed to load tags.");
        } finally {
          setLoading(false);
        }
    };

    fetchTags();
  }, [selectedTagData]);

  // filter tags by selectedTagType and search query
  useEffect(() => {
    let filtered = tags;

    try{
        if (selectedTagType) {
            setHeaderText(selectedTagType.label+ " Tags");
            filtered = filtered.filter((tag) => tag?.tagTypeId === selectedTagType?.value);
        }
        
        if (searchQuery) {
            setHeaderText("Search Result:");
            filtered = filtered.filter((tag) =>
            tag.mainFieldValue.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

    }catch (err){
        console.error("Something went wrong:", err);
        setError("Something went wrong");
    }
    
    setFilteredTags(filtered);
  }, [selectedTagType, searchQuery, tags]);

  const handleTagClick = async (tagId) => {
    if (!selectedTagData?.tagId || !tagId) 
      {
        console.warn("No Chilc Tag Id");
        return;
      }

    const payload = {
      parentId: selectedTagData.tagId, //currently opened tag
      childId: tagId, // clicked tag
    };

    try {
      const response = await createTagRelationship(payload);
      console.log("Relationship created successfully:", response);
      
      toast.success("Relationship Added!", {
        autoClose: 2500,
      });

      onClose();
    } catch (error) {
      console.error("Error creating relationship:", error);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        {/*topHeader*/}
        <div className="popup-header">
          <h1>Add Relationship</h1>
          <StyledIconButton className="popup-close-btn" onClick={onClose}>
            <IconBlock fontSize="12px">
              <Close />
            </IconBlock>
          </StyledIconButton>
        </div>

        {/* dropdown nd search */}
        <div className="popup-controls">
          
          <SelectField
            className="popup-select"
            placeholder="Select tag type"
            value={selectedTagType || null}
            options={tagTypeItems.map((item) => ({
              value: item.__typeId,
              label: item.__typeName,
            }))}
            onChange={(value) => 
                {   console.log("value:::::", value);
                    setSelectedTagType(value)}}
          />
          
          <SearchInput
            className="popup-search"
            placeholder="Search"
            defaultExpanded={true}
            canExpandable={false}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <hr />

        {/*header text*/}
        {!loading && !error && headerText && <h2 className="popup-header-text">{headerText}</h2>}

        {/* tag list*/}
        <div className="popup-tag-list">
          {loading ? (
            <p>Loading tags...</p>
          ) : error ? (
            <p className="popup-error">{error}</p>
          ) : filteredTags.length > 0 ? (
            
            filteredTags.map((tag) => {
              const TagType = tagTypeItems.find((type) => type.__typeId === tag.tagTypeId);
              const TagTypeIcon = findIcon(TagType?.__icon || "research-access-1");
            
              return (
                <div 
                  key={tag.tagId} 
                  className="popup-tag-item" 
                  onClick={() => handleTagClick(tag.tagId)}
                >
                  {TagTypeIcon && 
                      <TagTypeIcon className="popup-tag-icon" />
                  }
                  <span>{tag.mainFieldValue}</span>
                </div>
              );
            })
          ) : (
            <p className="popup-no-results">No tags found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddRelationshipPopup;
