import React from "react";
import clsx from "clsx";

const BaseCard = ({
  headerStart,
  headerStartClass = "",
  headerEnd,
  headerEndClass = "",
  content,
  className,
  headerWrapperClass = "css-px-40 css-py-20",
}) => {
  return (
    <div
      className={clsx(
        "css-grid css-border css-border-solid css-border-primary-75 css-rounded-8 css-overflow-hidden",
        className
      )}
    >
      {/* Header */}
      <div className="css-w-full css-shadow-primary-5 css-border-0 css-border-b css-border-solid css-border-primary-75 css-bg-neutral-10 ">
        <div className={clsx("css-grid", headerWrapperClass)}>
          <div className="css-flex css-gap-16 css-justify-between css-items-center">
            {headerStart ? (
              <div className={headerStartClass}>{headerStart}</div>
            ) : null}

            {headerEnd ? (
              <div className={headerEndClass}>{headerEnd}</div>
            ) : null}
          </div>
        </div>
      </div>

      {/* content */}
      <div className="css-w-full css-px-40 css-py-16">{content}</div>
    </div>
  );
};

export default BaseCard;
