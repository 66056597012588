import React from "react";
import { Modal } from "./styled";
import { hideProfileUpdateDialog, useProfileUpdateModal } from "./store";
import Form from "./components/Form";
import { saveMandatoryProfileDetails } from "../API/ProfileUpdate";
import { toast } from "react-toastify";

const ProfileUpdateDialog = () => {
  const isVisible = useProfileUpdateModal((state) => state.isVisible);

  // API call for the Form submission
  const onSubmit = (val) => {
    console.log(val);
    const payload = {
      userId: val?.userId,
      personalInfo: {
        firstName: val?.firstName,
        lastName: val?.lastName,
        email: val?.email,
        address: val?.address1,
        addressOptional: val?.address2,
        city: val?.city,
        postCode: val?.postCode,
      },
      defaultUserIdentification: {
        userIdentificationId: 0,
        nationality: val?.nationality,
        identificationTypeId: val?.documentType?.value || 0,
        documentNumber: val?.documentNumber,
      },

      brRegistrationInfo: {
        name: val?.companyName,
        taxRegistrationNumber: val?.companyTaxNumber,
        address: val?.companyAddress1,
        addressOptional: val?.companyAddress2,
        city: val?.companyCity,
        postCode: val?.companyPostCode,
      },
    };

    saveMandatoryProfileDetails(payload).then((res) => {
      if (res?.result) {
        hideProfileUpdateDialog();
      } else {
        toast.error(res?.displayMessage);
      }
    });
  };

  return (
    <Modal
      visibility={isVisible}
      title={"Update Your Profile info"}
      onClose={hideProfileUpdateDialog}
      bodyComponent={(bodyProps) => <Form onSubmit={onSubmit} {...bodyProps} />}
    />
  );
};

export default ProfileUpdateDialog;
