const config = {
  id: "onboarding_1",
  menuIcon: "menu-budget-01",
  label: "Onboarding",
  path: "onboardings",
  children: {
    layout1: {
      id: "onboarding/layout1",
      path: "",
      label: "",
    },
    layout2: {
      id: "onboarding/layout2",
      path: "2",
      label: "",
    },
    allMembers: {
      id: "onboarding/all_members",
      path: "all",
      label: "All Members",
    },
    shortlist: {
      id: "onboarding/shortlist",
      path: "short-list",
      label: "Shortlist",
    },
    hr_list: {
      id: "onboarding/hr_list_1",
      path: "hr-list",
      label: "HR List",
    },
    nda_change_request: {
      id: "onboarding/nda_change_request",
      path: "nda-change-request/:projectId/:memberUserId/:projectMemberId",
      label: "NDA Change Request",
    },
    docView: {
      id: "onboarding/2/docView",
      path: "document-view/:docId",
      label: "Document View",
    },
  },
};

export default config;
