import { $createFormField, FieldType } from "../../models/FormModel/FormField";
import API_ENDPOINTS from "../../../../services/api-endpoints";
import API_Research from "../../../../services/api-research";
import { FieldTypes } from "../../../../constant/FieldTypes";
import { TAG_MODAL_MODE } from "../../store/ResearchBoardStore";
import { LOCK_STATUS } from "../../constant";
import produce from "immer";
import { toast } from "react-toastify";

const generateFormDataFields = (fields, formData, { disabled }) => {
  let fieldsList = {};

  fields.forEach((field) => {
    const input = $createFormField();
    const fieldType = new FieldType();
    const inputKeyName = `${field.fieldName}__${field.tagFieldId}`;

    const existingField =
      formData && formData?.find((f) => f.tagFieldId === field.tagFieldId);

    input.id(field.tagFieldId);
    input.tagSubTypeId(field.tagSubType.tagSubTypeId);
    input.tagSubTypeName(field.tagSubType.tagSubTypeName);
    input.tagSubTypeIcon(field.tagSubType.icon);
    input.name(inputKeyName);
    input.length(field.fieldLength);
    input.isRequired(field.isMandatory);
    input.isActive(field.isActive);
    input.validateRegEx(field.validateRegEx);
    input.isCustomValidation(field.isCustomValidation);
    input.label(field.displayName);
    input.errorMessage("This is a number only field");
    input.hint();
    input.displayTypeId(field.displayTypeId);
    input.visualTypeId(field?.visualTypeId || 0);
    input.layoutTypeId(field?.layoutTypeId || 0);
    input.disabled(disabled);
    input.order(field?.order || -1);
    input.isMainField(field?.isMainField || false);
    input.icon(field?.icon);
    input.isCombined(field?.isCombined || false);
    input.combinedRoot(field?.combinedRoot || 0);
    input.combinedOrder(field?.combinedOrder || 0);

    fieldType.id(field?.tagFieldType.fieldTypeId);
    fieldType.validateRegEx(field?.tagFieldType.validateRegEx);
    fieldType.allowMultiple(field?.tagFieldType.allowMultiple);
    fieldType.type(field?.tagFieldType.fieldTypeName);

    const inputType = String(field?.tagFieldType.fieldTypeId);

    // get existing field value
    const existingValue = existingField?.value;

    if (inputType === String(FieldTypes.File)) {
      input.value(existingValue || []);
      fieldType.validationType("array");
    } else if (inputType === String(FieldTypes.WebAddress)) {
      const val = typeof existingValue === "string" ? [] : existingValue;
      input.value(val || []);
      fieldType.validationType("array");
    } else if (inputType === String(FieldTypes.Select)) {
      input.value(existingValue || null);
      fieldType.validationType("object");
    } else if (inputType === String(FieldTypes.Schedule)) {
      input.value(existingValue || null);
      fieldType.validationType("object");
    } else if (inputType === String(FieldTypes.GroupFields)) {
      input.value(existingValue || null);
      fieldType.validationType("array");
    } else if (inputType === String(FieldTypes.TelephoneList)){
      input.value(existingValue || null);
      fieldType.validationType("array");
    }
    else {
      input.value(existingValue || "");
      fieldType.validationType("string");
    }

    input.fieldType(fieldType);

    const options =
      field.fieldOptions &&
      field.fieldOptions.map((f) => ({
        value: f.valueField,
        label: f.nameField,
      }));

    input.fieldOptions(options || []);

    // updateFieldData(input,)

    fieldsList = {
      ...fieldsList,
      [inputKeyName]: input,
    };
  });

  return fieldsList;
};

const genFileManagerInput = (inputs, unsortedFilesData) => {
  const result = [];

  if (!inputs.length) {
    return result;
  }

  const fileUploadsFields = inputs.filter(
    (input) => input.fieldType().id() === FieldTypes.File
  );

  const sectionIds = [
    ...new Set(fileUploadsFields.map((field) => field.tagSubTypeId())),
  ];

  sectionIds.forEach((secId, i) => {
    let defaultValue = [];

    const unsortedFiles =
      unsortedFilesData
        ?.filter((d) => d.tabSectionId === secId)
        .map((a) => a.value) || [];

    const sections = fileUploadsFields
      .filter((field) => secId === field.tagSubTypeId())
      .map((field) => {
        defaultValue = defaultValue.concat(field.value());

        return {
          fieldId: field.id(),
          value: field.name(),
          label: field.label(),
          order: field.order(),
          tagSubTypeId: field.tagSubTypeId(),
          disabled: field.disabled(),
        };
      });

    const fileField = $createFormField();
    const fileFieldType = new FieldType();

    fileFieldType.id(FieldTypes.FileUploadManager);
    fileFieldType.type("File Upload Manager");
    fileFieldType.validationType("object");
    fileField.value({
      tabSectionId: secId,
      sections,
      value: defaultValue.concat(...unsortedFiles),
    });

    fileField.name(`customFileUploadManager${i}__${secId}`);
    fileField.tagSubTypeId(secId);
    fileField.fieldType(fileFieldType);
    fileField.order(sections[0]?.order || 1);
    fileField.disabled(sections[0]?.disabled || false);
    fileField.label("Attachments");

    result.push(fileField);
  });

  return result;
};

const genGroupFieldInputs = (inputs) => {
  let result = [];

  if (inputs.length < 0) {
    return result;
  }

  const groupedInputs = inputs.reduce((accumulator, field) => {
    if (field.isCombined()) {
      const root = field.combinedRoot();
      if (!accumulator[root]) {
        accumulator[root] = [];
      }
      accumulator[root].push(field);
    }
    return accumulator;
  }, {});

  Object.entries(groupedInputs).forEach(([key, group], i) => {
    const sortedGroup = group.sort(
      (a, b) => a.combinedOrder() - b.combinedOrder()
    );
    let defaultValue = [];

    const groupFieldType = new FieldType();
    groupFieldType.id(FieldTypes.GroupFields);
    groupFieldType.type("Group Fields");
    groupFieldType.validationType("object");

    const groupField = $createFormField();
    groupField.name(`customGroupField__${key}`);
    groupField.tagSubTypeId(sortedGroup[0]?.tagSubTypeId());
    groupField.fieldType(groupFieldType);
    groupField.order(sortedGroup[0]?.order() || 1);
    groupField.disabled(sortedGroup[0]?.disabled() || false);
    groupField.isMainField(
      sortedGroup.some((field) => field.isMainField()) || false
    );
    groupField.isRequired(
      sortedGroup.some((field) => field.isRequired()) || false
    );
    const rootField = sortedGroup.find(
      (field) => field.id() === field.combinedRoot()
    );
    const rootFieldLabel = rootField ? rootField.label() : null;
    groupField.label(rootFieldLabel);
    groupField.value({
      tabGroupId: key,
      fields: sortedGroup,
      value: defaultValue,
    });

    result.push(groupField);
  });

  return result;
};

const getFieldCount = (data = [], id) => {
  const count = data.filter(
    (field) =>
      !field.isMandatory && String(field.tagSubType.tagSubTypeId) === String(id)
  ).length;

  return count || 0;
};

const subGroups = (data = [], tabItemStatus) => {
  const tabMap = new Map();

  data.forEach((field) => {
    const id = field.tagSubType.tagSubTypeId;
    const tabItem = tabItemStatus?.find((tab) => tab.id === id);

    const value = {
      id,
      name: field.tagSubType.tagSubTypeName,
      fieldCount: getFieldCount(data, id),
      icon: field?.tagSubType?.icon,
      order: field?.tagSubType?.order,
      visibility: tabItem?.visibility ? tabItem?.visibility : false,
    };

    tabMap.set(id, value);
  });

  return Array.from(tabMap, ([key, value]) => value);
};

const getFormDataByTagId = (tagId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve) => {
    API_Research.get(`${endpoints.GET_TAG_FORM_DATA_BY_TAG_ID}?tagId=${tagId}`)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          resolve(null);
          return;
        }

        const result = res?.data?.result || null;

        resolve(result);
      })
      .catch((err) => {
        resolve(null);
      });
  });
};

export const getFormFields = (tagTypeId, mode, tagId = null) => {
  if (!tagTypeId) return;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Research.get(`${endpoints.GET_TAG_FIELDS_BY_TAG_ID}/${tagTypeId}`)
      .then(async (res) => {
        try {
          if (!res?.data?.isSuccess) {
            resolve(null);
            return;
          }

          let formData = null;
          let customData = "";
          let entityLock = null;
          let isLocked = false;
          let tabItemStatus = [];
          let unsortedFiles = [];

          // If Tag Id is not null then get existing data
          if (mode === TAG_MODAL_MODE.Edit && tagId) {
            const formDataRes = await getFormDataByTagId(tagId);

            formData = formDataRes && JSON.parse(formDataRes?.formData);

            if (formDataRes && formDataRes?.customData.length > 0) {
              customData = JSON.parse(formDataRes?.customData);
            }

            tabItemStatus = customData?.sectionTabStatus || [];
            unsortedFiles = customData?.fileUploadManager?.unsortedFiles || [];
            entityLock = formDataRes?.entityLock;

            isLocked =
              entityLock?.lockStatus === LOCK_STATUS.Extended ||
              entityLock?.lockStatus === LOCK_STATUS.Locked
                ? true
                : false;
          }

          let fields = generateFormDataFields(
            res?.data?.result || [],
            formData,
            {
              disabled: isLocked ? true : false,
            }
          );

          // Build FileUpload Inputs with unsorted files data
          const fileManagerInputs = genFileManagerInput(
            Object.values(fields) || [],
            unsortedFiles
          );

          // Append file upload Input to the existing inputs list
          fields = produce(fields, (draft) => {
            fileManagerInputs.forEach((field) => {
              draft[field.name()] = field;
            });
          });

          const groupFieldInputs = genGroupFieldInputs(
            Object.values(fields) || []
          );

          fields = produce(fields, (draft) => {
            groupFieldInputs.forEach((field) => {
              draft[field.name()] = field;
            });
          });

          const tabItems = subGroups(res?.data?.result, tabItemStatus);
          const result = {
            fields: fields || null,
            tabItems: tabItems || [],
            unsortedFiles: unsortedFiles,
            entityLock,
          };

          resolve(result);
        } catch (error) {
          resolve(null);
        }
      })
      .catch((err) => {
        resolve(null);
      });
  });
};

/** Create Tag  */
const createTagPayload = {
  projectId: 0,
  tagTypeId: 0,
  mainFieldValue: "",
  formData: "",
  customData: "",
  /**
   * Pending = 1
   * Confirmed = 2
   * Updated = 3
   * WIP = 4
   * Denied = 5
   */
  tagStatusId: 1,
};

export const tagSaveOrUpdate = (data = createTagPayload, tagId = null) => {
  // CREATE_TAG
  const body = {
    ...createTagPayload,
    ...data,
  };

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const endpoint = `${endpoints.TAG_SAVE_OR_UPDATE}`;

    let API;

    if (tagId) {
      API = API_Research.put(`${endpoint}/${tagId}`, {
        ...body,
        tagStatusId: 3,
      });
    } else {
      API = API_Research.post(endpoint, body);
    }

    if (!API) {
      return;
    }

    API.then(async (res) => {
      resolve(res?.data);
    }).catch((err) => {
      reject(err);
    });
  });
};


const createTagRelationshipPayload = {
  parentId: 0, // ID of the opened tag
  childId: 0, // ID of the selected tag from add relationship to be linked
};


export const createTagRelationship = (data = createTagRelationshipPayload) => {
 
  const endpoints = new API_ENDPOINTS();
  const endpoint = `${endpoints.TAG_RELATIONSHIP_SAVE}`;

  return new Promise((resolve, reject) => {
    API_Research.post(endpoint, data)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteTagRelationship = (relationshipId) => {
 
  const endpoints = new API_ENDPOINTS();
  const endpoint = `${endpoints.TAG_RELATIONSHIP_DELETE_BY_RELATIONSHIP_ID}`;

  return new Promise((resolve, reject) => {
    API_Research.delete(`${endpoint}/${relationshipId}`)
      .then((res) => {
        if (!res.data.isSuccess) {
          reject(new Error("Unexpected response structure"));
        }

        resolve(res.data);
      })
      .catch((err) => {
        console.error("API call failed:", err);
        reject(err);
      });
  });
};

export const tagDeleteByTagId = (tagId) => {
  const endpoints = new API_ENDPOINTS();
  const { RESEARCH_TAG_DELETE } = endpoints;

  return new Promise((resolve, reject) => {
    API_Research.delete(`${RESEARCH_TAG_DELETE}/${tagId}`)
      .then((response) => {
        if (!response.data.isSuccess) {
          reject(new Error("Unexpected response structure"));
        }

        resolve(response.data);
      })
      .catch((error) => {
        console.error("API call failed:", error);
        reject(error);
      });
  });
};
