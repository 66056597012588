import PROJECT_API from "../../../../services/api-project";
import { NOTE_ENDPOINTS } from "./note.endpoints";

export const getNotes = async (__projectId, __projectMemberId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await PROJECT_API.get(
        `${NOTE_ENDPOINTS.GET_PROJECT_ONBOARD_NOTES}/${__projectId}/${__projectMemberId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};

/* 

Payload
{
  "projectOnboardNoteId": 0, // For CREATE = 0 
  "projectId": 2,
  "projectMemberId": 1,
  "note": "Note Added",
  "dateTime": "2024-12-23T14:01:19.061Z",
  "noteAddedByMemberId": 4 // current logged user memberId
}
*/
export const saveNote = async (__data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await PROJECT_API.post(
        `${NOTE_ENDPOINTS.SAVE_ONBOARD_NOTES}`,
        __data
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }
      console.log("saveProjectBrifeOnboardNote:: ", res);

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

/**
 * @param {Object} payload
 * @param {Number} projectId
 * @param {Number} projectMemberId
 * @param {Boolean} isRead
 * @param {Array} onboardNoteIds[]
 * @returns Promise
 */
export const updateNotesReadStatus = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await PROJECT_API.put(
        `${NOTE_ENDPOINTS.UPDATE_ONBOARD_NOTES_READ_STATE}`,
        payload
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
