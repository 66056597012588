import GLOBAL_API from "../../../services/api";
import PROJECT_API from "../../../services/api-project";
import { PROJECT_ENDPOINTS } from "./project.endpoints";

export const getProjectBrifeForProjectOrInvitaion = async (
  __projectId,
  __userId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await PROJECT_API.get(
        `${PROJECT_ENDPOINTS.PROJECT_BRIEF}/${__projectId}/${__userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const checkMandatoryDetailsIsComplted = async (__userId, __type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${PROJECT_ENDPOINTS.CHECK_MANDATORY_DETAILS}/${__userId}/${__type}`
      );

      if (!response?.data?.isSuccess) {
        resolve(false);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const acceptOrRejectInvitation = async (
  __invitaionId,
  __status,
  __remarks
) => {
  let params = {
    invitationId: __invitaionId,
    isAcceptOrReject: __status,
    acceptOrRejectRemarks: __remarks,
  };

  return new Promise(async (resolve, reject) => {
    try {
      let res = await PROJECT_API.post(
        `${PROJECT_ENDPOINTS.ACCEPT_OR_REJECT_INVITAION}`,
        params
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }
      console.log("acceptOrRejectInvitation:: ", res);

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const processNDA = async (_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await PROJECT_API.post(
        `${PROJECT_ENDPOINTS.GENERATE_NDA}`,
        _data
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }
      console.log("processNDA:: ", res);

      const data = res?.data;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const getProjectBrifeOnboardNotes = async (
  __projectId,
  __projectMemberId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await PROJECT_API.get(
        `${PROJECT_ENDPOINTS.GET_PROJECT_ONBOARD_NOTES}/${__projectId}/${__projectMemberId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveProjectBrifeOnboardNote = async (__data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await PROJECT_API.post(
        `${PROJECT_ENDPOINTS.ACCEPT_OR_REJECT_INVITAION}`,
        __data
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }
      console.log("saveProjectBrifeOnboardNote:: ", res);

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
