import produce from "immer";
import { create } from "zustand";

let store = (set, get) => ({
  visibility: false,
  meta: null,
  project: null,
  isEditMode: false,
});

export const useAddToProjectStore = create(store);

const state = useAddToProjectStore;

export const openAddToProjectDialog = (meta, project, isEditMode) => {
  state.setState(
    produce((draft) => {
      draft.visibility = true;
      draft.meta = meta;
      draft.project = project;
      draft.isEditMode = isEditMode;
    })
  );
};

export const hideAddToProjectDialog = () => {
  state.setState(
    produce((draft) => {
      draft.visibility = false;
      draft.meta = null;
    })
  );
};
