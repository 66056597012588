import React from "react";

import Button from "../../../../common/components/Buttons/UIButton";
import IconRight from "../../../../common/components/icons/Line/Arrow/right";
import IconLeft from "../../../../common/components/icons/Line/Arrow/left";
import { MBody } from "./styled";
import { navigationStatus, onNext, onPrevious } from "./store";
import useProfileStore from "./hooks/useProfileStore";

const Availability = React.lazy(() => import("./screens/Availability"));
const RateCard = React.lazy(() => import("./screens/RateCard"));

const ModalBody = ({ onSubmit }) => {
  const navStatus = navigationStatus();

  const { key } = useProfileStore();

  const renderSteps = React.useMemo(() => {
    switch (String(key)) {
      case "1": {
        return <Availability onSubmit={onSubmit} />;
      }
      case "2": {
        return <RateCard onSubmit={onSubmit} />;
      }
      default:
        return null;
    }
  }, [key, onSubmit]);

  return (
    <MBody>
      <>
        <React.Suspense fallback={<>Loading...</>}>
          {renderSteps}
        </React.Suspense>
      </>

      <div className="css-flex css-items-center css-justify-between css-mt-24">
        <div>
          {navStatus.hasPrevious ? (
            <Button
              type="button"
              variant={Button.Variant.Secondary}
              size={Button.Sizes.Small}
              startIcon={<IconLeft />}
              onClick={onPrevious}
            >
              Previous
            </Button>
          ) : null}
        </div>

        <div>
          {navStatus.hasNext ? (
            <Button
              type="button"
              size={Button.Sizes.Small}
              endIcon={<IconRight />}
              onClick={onNext}
            >
              Next
            </Button>
          ) : (
            <Button type="button" size={Button.Sizes.Small} onClick={onNext}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </MBody>
  );
};

export default ModalBody;
