import React from "react";
import Item from "./Item";

const Notes = ({ data }) => {
  return (
    <div className="css-grid css-gap-12">
      {data.map((d, i) => {
        return (
          <Item
            key={i}
            profileImage={d?.noteAddedProjectMember?.profileImage || null}
            commenterName={d?.noteAddedProjectMember?.memberName}
            commentedDate={d?.dateTime}
            comment={d?.note}
          />
        );
      })}
      {/* <Item comment="Hey Jenny, are you on your way here?" />
      <Item comment="Hey Jenny, are you on your way here? Hey Jenny, are you on your way here? It took the Hostage Support Partners, a pro-bono group ss in Somalia, 18 mote   " /> */}
    </div>
  );
};

export default Notes;
