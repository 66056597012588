const config = {
  id: "projectV2",
  menuIcon: "menu-budget-01",
  label: "Project",
  path: "project",
  children: {
    projectBrief: {
      id: "project/brief",
      path: "brief/:projectId",
      absolutePath: "/project/brief/:projectId",
      label: "Project Brief",
    },
  },
};

export default config;
