import styled from "styled-components";

export const TextAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: var(--layout-border-radius-4);
  background-color: var(--layout-background-white);
  border-radius: var(--layout-border-radius-4);
  border: 1px solid var(--font-tint-01);

  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return `
        background-color: var(--color-neutral-20);
      `;
    }
  }}

  &:focus-within {
    border-color: var(--color-accent-300);
  }

  .field__control__wrapper {
    background-image: none;
    width: 100%;
    background: transparent;

    textarea {
      background-color: transparent;
    }
  }

  .send-btn__wrapper {
    color: var(--color-white);
    padding: var(--layout-spacing-8);
    display: flex;
    align-self: flex-end;

    .icon__button__element {
      border-radius: var(--layout-border-radius-4);
      background-color: var(--color-accent-300);

      &:hover {
        background-color: var(--color-accent-400);
      }

      &:disabled {
        background-color: var(--color-neutral-50);
      }
    }
  }
`;
