import create from "zustand";
import { devtools } from "zustand/middleware";
import { getAllJobTitles } from "../../../Actions/Common";
import {
  assignTemplates,
  deleteTemplateByTemplateId,
  getAssignTemplatesByProjectId,
  getTemplatesList,
  getTemplateTypeList,
  updateTemplateByTemplateId,
} from "../../../Actions/Setting/Templates";

let TemplateGeneralStore = (set, get) => ({
  isEditMode: false,
  templateTypeOptions: [],
  jobTitleOptions: [],
  templateOptions: [],
  selectedProjectId: "",
  isFormSubmit: false,
  allTemplatesData: [],
  showForm: false,
  isFormExpanded: true,
  formData: {
    typeId: "",
    categoryId: "",
    templateId: "",
    defaultTemplate: false,
  },

  onToggleForm: () => set((state) => ({ showForm: !state.showForm })),

  onToggleAccordion: () =>
    set((state) => ({ isFormExpanded: !state.isFormExpanded })),

  onEdit: (id) => {
    // Get selected data by id
    const selectedForm = get().allTemplatesData.find((d) => d.id === id);

    if (selectedForm) {
      const {
        templateTypeId,
        templateId,
        projectRoleId,
        projectTemplateId,
        defaultTemplate,
      } = selectedForm;
      // update state
      set({
        isEditMode: true,
        isFormExpanded: true,
        formData: {
          typeId: templateTypeId,
          categoryId: projectRoleId,
          templateId: templateId,
          id: projectTemplateId,
          defaultTemplate: defaultTemplate,
        },
      });
    }
  },

  onDelete: async (id) => {
    const onLoadTemplatesByProjectId = get().onLoadTemplatesByProjectId;

    return new Promise((resolve, reject) => {
      deleteTemplateByTemplateId(id)
        .then((res) => {
          resolve(res);
          // Reload table
          onLoadTemplatesByProjectId();
        })
        .catch((err) => reject(err));
    });
  },

  onProjectSelected: (id) => {
    set({ selectedProjectId: id, isEditMode: false });

    // Load templates by project id
    //get().onLoadTemplatesByProjectId();
  },

  // Save or Edit
  onFormSubmit: async (values) => {
    let isEdit = get().isEditMode;

    const onAfterEdited = () => {
      set({
        isFormSubmit: false,
        isEditMode: false,
        isFormExpanded: false,
      });

      // Reload table  data
      get().onLoadTemplatesByProjectId();
    };

    const onAfterSaved = () => {
      set({
        isFormSubmit: false,
        isEditMode: false,
      });

      // Reload table  data
      get().onLoadTemplatesByProjectId();
    };

    return new Promise((resolve, reject) => {
      set({ isFormSubmit: true });

      // Call to API
      if (isEdit) {
        // Edit template
        let id = get().formData.id;

        let obj = {
          ...values,
          projectTemplateID: id,
        };

        return updateTemplateByTemplateId(id, obj)
          .then((res) => {
            resolve(res);
            onAfterEdited();
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        // Add New Templates
        return assignTemplates(values)
          .then((res) => {
            onAfterSaved();
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  clearForm: (callback) => {
    set({
      isEditMode: false,
      formData: {
        userId: null,
        roleIds: [],
        id: null,
      },
    });

    callback && callback();
  },

  // Load data for template types dropdown
  onLoadTemplateTypeOptions: async () => {
    getTemplateTypeList()
      .then((data) => {
        set({ templateTypeOptions: data });
      })
      .catch(() => {
        set({ templateTypeOptions: [] });
      });
  },

  // Load data for templates dropdown
  onLoadTemplatesOptions: async () => {
    getTemplatesList()
      .then((data) => {
        set({ templateOptions: data });
      })
      .catch(() => {
        set({ templateOptions: [] });
      });
  },

  // Load data for job titles dropdown
  onLoadJobCategoryOptions: async () => {
    getAllJobTitles()
      .then((data) => {
        set({ jobTitleOptions: data });
      })
      .catch(() => {
        set({ jobTitleOptions: [] });
      });
  },

  // Load data for job titles dropdown
  onLoadTemplatesByProjectId: async () => {
    const types = get().templateTypeOptions;
    const roles = get().jobTitleOptions;
    const templates = get().templateOptions;
    const projectId = get().selectedProjectId;

    if (!projectId) {
      return;
    }

    getAssignTemplatesByProjectId(projectId)
      .then((data) => {
 
        const formateData = data.map((item) => {
          return {
            ...item,
            id: item.projectTemplateId,
            action: item.projectTemplateId,
            projectId: item.projectId,
            type: types.find((d) => d.value === item.templateTypeId)?.["label"],
            category: item.projectRoleId === 0 ? 'Applied To All' : roles.find((d) => d.value === item.projectRoleId)?.[
              "label"
            ],
            template: templates.find((d) => d.value === item.templateId)?.[
              "label"
            ],
          };
        });

        set({ allTemplatesData: formateData || [] });
      })
      .catch(() => {
        set({ allTemplatesData: [] });
      });
  },

  onResetState: () => {
    set({
      isFormSubmit: false,
      isEditMode: false,
    });
  },
});

TemplateGeneralStore = devtools(TemplateGeneralStore);

export const useTemplateGeneralStore = create(TemplateGeneralStore);
