import styled from "styled-components";
import BaseFlexModal from "../../../../common/components/UI/Modal/BaseFlexModal";

export const BaseDialog = styled(BaseFlexModal)`
  .modal__content {
    .header__container {
      padding-bottom: var(--layout-spacing-16);
    }

    .user__info__wrapper {
      margin-bottom: var(--layout-spacing-16);
    }

    .dialog__actions {
      margin-top: var(--layout-spacing-16);
      display: flex;
      justify-content: space-between;
      gap: var(--layout-spacing-8);

      button.align-right {
        margin-left: auto;
      }
    }
  }
`;

export const BaseAddToProjectDialog = styled(BaseDialog)`
  --modal-max-width: 500px;
`;

export const StyledGenerateContractDialog = styled(BaseDialog)`
  --modal-max-width: 677px;
`;

export const StyledOnboardingDialog = styled(BaseDialog)`
  --modal-max-width: 600px;
`;

export const BaseNotesViewDialog = styled(BaseDialog)`
  --modal-max-width: 760px;
`;

export const GridView = styled.div`
  display: grid;
  gap: 0 var(--layout-spacing-16);
  grid-template-columns: repeat(2, 1fr);

  .w__100 {
    width: 100%;
  }

  .full-column {
    grid-column: 1/3;
  }

  .flex__item {
    display: flex;
    align-items: center;

    &--gap-8 {
      gap: var(--layout-spacing-8);
    }
  }
`;
