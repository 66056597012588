class API_ENDPOINTS {
  LOGIN = "/Global/AuthenicateUser";
  SIGNUP = "/Global/UserSignUp";
  FORGETPASSWORDTOKENREQUEST = "/Global/ResetPasswordTokenRequest";
  FORGETPASSWORDTOKENDETAILREQUEST = "/Global/ResetPasswordTokenInfoRequest";
  RESETPASSWORD = "/Global/ResetPassword";

  SETUPLANGUAGE = "/Global/Language_Create";
  UPDATELANGUAGE = "/Global/Language_Create";
  DELETELANGUAGE = "/Global/Language_Delete";
  GETALLLANGUAGES = "/Global/Language_GetAll";

  SETUPCURRENCY = "/Global/Currency_Create";
  UPDATECURRENCY = "/Global/Currency_Update";
  GETALLCURRENCIES = "/Global/Currency_GetAll";
  DELETECURRENCY = "/Global/Currency_Delete";

  SETUPCOUNTRY = "/Global/Country_Create";
  UPDATECOUNTRY = "/Global/Country_Update";
  GETALLCOUNTRIES = "/Global/Country_GetAll";
  DELETECOUNTRY = "/Global/Country_Delete";

  UPDATEGLOBLECOMPANY = "/Global/Company_Update";
  UPDATETANENTCOMPANY = "/Tenant/Company_Update";

  GETCOMPANYBYID = "/Tenant/Company_GetById";

  SETUPDEPARTMENT = "/Tenant/Department_Create";
  UPDATEDEPARTMENT = "/Tenant/Department_Update";
  GETALLDEPARTMENT = "/Tenant/Department_GetAll";
  DELETEDEPARTMENT = "/Tenant/Department_Delete";

  GETALLREGIONS = "/Global/Region_GetAll";

  SETUPREGIONCOUNTRIES = "/Global/RegionCountry_Create";
  UPDATEREGIONCOUNTRIES = "/Global/RegionCountryListUpdate";
  GETALLREGIONCOUNTRIES = "/Global/RegionCountry_GetAll";
  GETREGIONCOUNTRYBYID = "/Global/RegionCountry_GetById";
  DELETEREGIONCOUNTRIES = "/Global/RegionCountry_Delete";

  SETUPGLOBALCLIENT = "/Global/Client_Create";
  UPDATEGLOBALCLIENT = "/Global/Client_Update";
  GETALLGLOBALCLIENTS = "/Global/Client_GetAll";
  GETGLOBALCLIENTBYID = "/Global/Client_GetById";
  GETGLOBALCLIENTBYNAME = "/Global/Client_GetByName";
  DELETEGLOBALCLIENT = "/Global/Client_Delete";

  SETUPTENANTCLIENT = "/Tenant/Client_Create";
  UPDATETENANTCLIENT = "/Tenant/Client_Update";
  GETALLTENANTCLIENTS = "/Tenant/Client_GetAll";
  GETTENANTCLIENTBYNAME = "/Tenant/Client_GetByName";
  GETTENANTCLIENTBYID = "/Tenant/Client_GetById";
  DELETETENANTCLIENT = "/Tenant/Client_Delete";

  SETUPJOBTITLE = "/Global/JobTitle_Create";
  UPDATEJOBTITLE = "/Global/JobTitle_Update";
  GETALLJOBTITLES = "/Global/JobTitle_GetAll";
  DELETEJOBTITLE = "/Global/JobTitle_Delete";

  SETUPPLATFORM = "/Global/Platform_Create";
  UPDATEPLATFORM = "/Global/Platform_Update";
  GETALLPLATFORMS = "/Global/Platform_GetAll";
  DELETEPLATFORM = "/Global/Platform_Delete";

  SETUPCLIENTREGION = "/Global/ClientRegion_Create";
  UPDATECLIENTREGION = "/Global/ClientRegionListUpdate";
  GETALLCLIENTREGIONS = "/Global/ClientRegion_GetAll";
  DELETECLIENTREGION = "/Global/ClientRegion_Delete";

  SETUPCLIENTREGIONCOUNTRIES = "/Global/ClientRegionCountry_Create";
  UPDATECLIENTREGIONCOUNTRIES = "/Global/ClientCountryListUpdate";
  GETALLCLIENTREGIONCOUNTRIES = "/Global/ClientRegionCountry_GetAll";
  GETCLIENTREGIONCOUNTRYBYID = "/Global/ClientRegionCountry_GetById";
  DELETECLIENTREGIONCOUNTRIES = "/Global/ClientRegionCountry_Delete";

  SETUP_TENANT_CLIENTREGION = "/Tenant/ClientRegion_Create";
  UPDATE_TENANT_CLIENTREGION = "/Tenant/ClientRegionListUpdate";
  GETALL_TENANT_CLIENTREGIONS = "/Tenant/ClientRegion_GetAll";
  DELETE_TENANT_CLIENTREGION = "/Tenant/ClientRegion_Delete";

  SETUP_TENANT_CLIENTREGIONCOUNTRIES = "/Tenant/ClientRegionCountry_Create";
  UPDATE_TENANT_CLIENTREGIONCOUNTRIES = "/Tenant/ClientCountryListUpdate";
  GETALL_TENANT_CLIENTREGIONCOUNTRIES = "/Tenant/ClientRegionCountry_GetAll";
  GET_TENANT_CLIENTREGIONCOUNTRYBYID = "/Tenant/ClientRegionCountry_GetById";
  DELETE_TENANT_CLIENTREGIONCOUNTRIES = "/Tenant/ClientRegionCountry_Delete";

  GET_USER = "/users/";
  GETUSER_BYNAME = "/Global/GetUsersByName";
  GETALLUSER_BYTenant = "/Global/GetUsersByTenant";

  GENRES_GETALL = "/Global/Genere_GetAll";
  GENRES_CREATE = "/Global/Genere_Create";
  GENRES_UPDATE = "/Global/Genere_Update";
  GENRES_DELETE = "/Global/Genere_Delete";

  PROJECT_GETALL = "/Project/Project_GetAll";
  PROJECT_GETBYID = "/Project/ProjectGetById";
  PROJECT_CREATE = "/Project/Project_Create";
  PROJECT_UPDATE = "/Project/Project_Update";
  PROJECT_DELETE = "/Project/Project_Delete";
  PROJECT_GET_EPISODES = "/Project/ProjectEpisode/allprojectepisodes";
  PROJECT_DELETE_EPISODE = "/Project/ProjectEpisode";
  PROJECT_SAVE_EPISODES = "/Project/ProjectEpisode/bulk-insert-episodes";
  PROJECT_GET_PROJECT_TYPES = "/Global/Project_GetProjectType";
  PROJECT_GET_PROJECT_PRIVACY = "/Global/Project_GetProjectPrivacy";
  PROJECT_UPDATE_CHECKED_IN = "/Global/projectcheckin";
  PROJECT_SPECS_UPDATE = "/Project/Project_Update/Technical_Specs";
  PROJECT_SEPECS_GET_BY_PROJECT = "Project/GetProjectTechnicalSpec_ByProjectId";

  PROJECT_FEATURES_GETALL_BY_TENANT = "/Global/TenantProjectFeature_GetAll";
  PROJECT_GENRES_GETALL_BY_TENANT = "/Tenant/Genres_GetAll";
  PROJECT_FEATURES_ADD = "/Project/Project_FeaturesUpdate";
  PROJECT_GET_FEATURES_BY_PROJECTID = "/Project/Project_GetProjectFeatures";
  PROJECT_GENRES_ADD = "/Project/Project_GenresUpdate";
  PROJECT_GENRES_GETBY_PROJECTID = "/Project/Project_GetProjectGenres";

  PROJECT_ADD_USER_TO_SHORTLIST = "/Project/ProjectMemberShortList_Create";
  PROJECT_GET_PROJECT_MEMBER_SHORTLIST_BY_PROJECT =
    "/Project/ProjectMemberShortList_GetAll";
  PROJECT_REMOVE_USER_FROM_SHORTLIST = "/Project/ProjectMemberShortList_Delete";
  PROJECT_SHORTLIST_GET_MEMBER_BY_NAME =
    "/Project/ProjectMemberShortList_GetByName";
  PROJECT_ADD_USER_TO_PROJECT = "/Project/ProjectMember_Create";
  PROJECT_SEND_PROJECT_ADDED_USER_INVITAION =
    "/Project/ProjectMember_Invitation";
  PROJECT_GET_PROJECT_MEMBER_BY_NAME =
    "/ProjectMember_GetByName/{projectId}/{Name}";
  PROJECT_GET_PROJECT_MEMBER_ONBOARDINGLIST_BY_PROJECT =
    "/Project/ProjectOnBoardedMember_GetAll";
  PROJECT_GET_PROJECT_MEMBER_ONBOARDINGLIST_BY_NAME =
    "/Project/ProjectOnBoardedMember_GetByName";
  PROJECT_GET_EPISODES_BY_PROJECT_ID = "Project/ProjectEpisode/Get_Episodes";

  TEMPLATE_GET_TEMPLATE_TYPE = "/Template/TemplateType_GetAll";
  TEMPLATE_TYPE_GET_BY_ID = "/Template/TemplateType_GetById";

  TEMPLATE_GETALL = "/Template/Template_GetAll";
  TEMPLATE_GETBYNAME = "/Template/Template_GetByName";
  TEMPLATE_GETBYTYPE = "/Template/Template_GetByType";
  TEMPLATE_CREATE = "/Template/Template_Create";
  TEMPLATE_UPDATE = "/Template/Template_Update";
  TEMPLATE_DELETE = "/Template/Template_Delete";
  TEMPLATE_ACTIVITY = "/Template/Template_AddActivity";

  TEMPLATE_GET_TEMPLATE_DOC_BY_ID = "/Template/TemplateDoc_GetById";
  TEMPLATE_GET_BASE64_TEMPLATE_DOC_BY_ID = "Template/Base64TemplateDoc_GetById";
  TEMPLATE_GET_FORM_FIELDS = "/Template/Form_fields";
  TEMPLATE_GET_FORM_FIELD_VALUES_BY_USER_TENANT_ID =
    "/Template/Form_field_values";

  TEMPLATE_GET_RECENTLY_USED_BY_TEMPLATE_TYPE =
    "/Template/Template_GetRecentlyUsed";
  TEMPLATE_GET_FREQUENTLY_USED_BY_TEMPLATE_TYPE =
    "/Template/Template_GetFrequentlyUsed";

  GET_PROFILE_BASIC_DETAILS = "/Global/Profile_GetById";
  USER_PROFILE_UPDATE = "Global/Profile_Update";
  FILE_UPLOAD_ENDPOINT = "/AzureStorageApi/UploadFiles";
  FILE_STREAM_UPLOAD_ENDPOINT = "AzureStorageApi/UploadFileStream";
  DOCUMENT_UPLOAD_DOCUMENT_ENDPOINT = "DocumentApi/save";
  DOCUMENT_UPLOAD_ENDPOINT = "DocumentApi/Upload";

  PROJECT_GET_PROJECT_HR_LIST = "/Project/ProjectContractInitiated_GetAll";
  PROJECT_GET_PROJECT_HR_LIST_BY_NAME =
    "/Project/ProjectContractInitiated_GetByName";
  CONTRACT_GENARATION_REQUEST = "/Project/ProjectContractRequest";
  GET_USER_STATUS_CHANGE_HISTORY = "/Project/ProjectMemberHistory";

  PROJECT_UPDATE_ONBOARDING_DETAILS =
    "/Project/ProjectMember_invitation_details";
  PROJECT_GET_NDA_DETAILS = "/Project/ProjectMemberInvitationDetails";
  PROJECT_GET_Invitation_Details_By_Project_And_MemberId =
    "/Project/ProjectMember_invitation_details/";
  PROJECT_GET_MEMBER_NDA_DETAILS = "/Project/ProjectMemberNDADetails";
  PROJECT_NDA_ACCEPT_DECLINE = "/Project/ProjectMemberNDA_AcceptOrReject";
  PROJECT_MEMBER_INVITATION_ACCEPT_OR_REJECT =
    "/Project/ProjectMemberInvitation_AcceptOrReject";

  GET_NOTIFICATION_BY_USER = "/Notification/GetRenderedNotificationByUser";
  // GET_NOTIFICATION_BY_USER = "/Notification/getNotificationByUser";
  GET_NOTIFICATION_DETAIL_BY_ID = "/Notification/getNotificationDetail";
  READ_NOTIFICATION_BY_ID = "/Notification/readNotification";
  DELETE_NOTIFICATION_BY_ID = "/Notification/deleteNotification";

  SEND_EMAIL_VERIFICATION = "/Global/RequestEmailVerification";
  REQUEST_EMAIL_VERIFICATION_CODE = "/Global/RequestEmailVerificationCode";
  CHECK_IS_EMAIL_EXIST = "/Global/IsEmailExist";

  PERMISSION_CREATE_ROLE = "/Tenant/Role_Create";
  PERMISSION_ROLE_GET_ALL = "/Tenant/Role_GetAll";
  PERMISSION_DELETE_ROLE = "/Tenant/Role_Delete";

  PERMISSION_CREATE_USERROLE = "/Tenant/UserRole_Create";
  PERMISSION_USERROLE_GET_ALL = "/Tenant/UserRole_GetAll";
  PERMISSION_DELETE_USERROLE = "/Tenant/UserRole_Delete";
  PERMISSION_USERROLE_GET_BY_USERID = "Tenant/UserRole_GetByUserId";

  PERMISSION_CREATE_ROLERIGHT = "/Tenant/RoleRight_Create";
  PERMISSION_UPDATE_ROLERIGHT = "/Tenant/RoleRight_Update";
  PERMISSION_DELETE_ROLERIGHT = "/Tenant/RoleRight_Delete";
  PERMISSION_ROLERIGHT_GET_ALL = "/Tenant/RoleRight_GetAll";
  PERMISSION_ROLERIGHT_GET_BY_ROLE = "/Tenant/RoleRight_GetByRoleId";

  PERMISSION_CREATE_USERRIGHT = "/Tenant/UserRight_Create";
  PERMISSION_UPDATE_USERRIGHT = "/Tenant/UserRight_Update";
  PERMISSION_DELETE_USERRIGHT = "/Tenant/UserRight_Delete";
  PERMISSION_USERRIGHT_GET_ALL = "/Tenant/UserRight_GetAll";
  PERMISSION_USERRIGHT_GET_BY_USER = "/Tenant/UserRight_GetByUserId";

  PERMISSION_CREATE_ROLEMENU = "/Tenant/RoleMenu_Create";
  PERMISSION_UPDATE_ROLEMENU = "/Tenant/RoleMenu_Update";
  PERMISSION_ROLEMENU_GET_ALL = "/Tenant/RoleMenu_GetAll";
  PERMISSION_ROLEMENU_GET_BY_ROLE = "/Tenant/RoleMenu_GetByRoleId";
  PERMISSION_DELETE_ROLEMENU = "/Tenant/RoleMenu_Delete";

  PERMISSION_CREATE_USERMENU = "/Tenant/UserMenu_Create";
  PERMISSION_UPDATE_USERMENU = "/Tenant/UserMenu_Update";
  PERMISSION_USERMENU_GET_ALL = "/Tenant/UserMenu_GetAll";
  PERMISSION_USERMENU_GET_BY_ROLE = "/Tenant/UserMenu_GetByRoleId";
  PERMISSION_DELETE_USERMENU = "/Tenant/UserMenu_Delete";

  PERMISSION_RIGHT_GET_ALL = "/Tenant/Right_GetAll";

  PERMISSION_MENU_GET_ALL = "/Tenant/Menu_GetAll";

  PERMISSION_MODULE_GETALL = "/Tenant/Module_GetAll";

  PERMISSION_RIGHT_GET_BY_MODULE = "/Tenant/Right_GetByModuleId";
  PERMISSION_RIGHT_GET_BY_ID = "/Tenant/Right_GetById";

  PERMISSION_GET_USER_LIST = "/Global/GetTenantUserList";

  PERMISSION_GET_USER_MENU = "/Tenant/GetUserMenuPermission";
  GET_USER_PAGE_RIGHT_PERMISSION = "/Tenant/GetUserPageRightPermission";

  PROJECT_ASSIGN_TEMPLATE = "/Project/Project_AssignTemplate";
  GET_ASSIGN_TEMPLATE_BY_PROJECT_ID =
    "/Project/Project_GetAssigned_TemplateByProjectID";
  PROJECT_ASSIGN_TEMPLATE_UPDATE = "/Project/Project_AssignTemplate_Update";
  PROJECT_ASSIGNED_TEMPLATE_DELETE = "/Project/Project_AssignedTemplate_Delete";

  GET_PROJECT_CONTRACT_REQUEST_INFO = "/Project/GetProjectContractRequestInfo";

  GENERATE_USER_DOC = "/Template/Generate_UserDoc";
  CREATE_DOCUMENT = "/Document/Document_create";
  SET_CONTRACT_DOCUMENT_ID = "/Project/SetContractDocumentId";
  PROJECT_CONTRACT_GENERATE = "/Project/ProjectContractGenerate";
  DOCUMENT_DOC_GET_BY_ID = "/Document/DocumentDoc_GetById";
  PROJECT_CONTRACT_ACCEPT_OR_REJECT = "/Project/ProjectContract_AcceptOrReject";
  PROJECT_ONBOARDING_COMPLETE = "/Project/ProjectOnBoarding_Complete";
  PROJECT_ONBOARDING_COMPLETE_INFO = "/Project/ProjectOnBoarding_CompleteInfo";
  DOCUMENT_GET_BY_ID = "/Document/Document_GetById";
  DOCUMENT_GET_BASE64_DOC_BY_ID = "/Document/Base64_DocumentDoc_GetById";

  SWITCH_TENANT = "/Global/SetDefaultTenant";
  SET_PROJECT_MEMBER_STATUS = "/Project/SetProjectMemberStatus";
  UPDATE_PROJECT_MEMBER_STATUS = "/Project/UpdateProjectMemberStatus";
  UPDATE_NDA_DOCUMENT = "/Project/ProjectMember_NDA";
  GET_USER_TITLES = "/Global/Title_GetAll";
  PROJECT_MEMBER_GET_BY_ID = "/Project/ProjectMemberGetById";
  SET_PROJECT_MEMBER_STATUS_MANUALLY = "/Project/UpdateProjectMemberStatus";
  PROJECT_NDA_COMPLETE = "/Project/ProjectNDA_Complete";

  RESEARCH_DOCUMENTS = "/research/documents";

  RESEARCH_TAG_SEARCH = "/research/tags/search";
  GET_TAGS_BY_PROJECT = "/research/tags/by-project-id";
  GET_TAG_TYPES = "/research/tag-types";
  GET_TAG_FIELDS_BY_TAG_ID =
    "/research/tag-fields/tag-fields-with-option-values";
  GET_TAG_FORM_DATA_BY_TAG_ID = "/research/tags/formdata";
  TAG_SAVE_OR_UPDATE = "/research/tags";
  RESEARCH_TAG_DELETE = "/research/tags";
  GET_ALL_SUB_TAG_TYPES = "/research/tag-sub-types";
  GET_TAGS_BY_IDS = "/research/tags/by-ids";
  GET_TAGS_SEARCH = "/research/tags/SearchTagByTypeAndName";

  GET_TAG_RELATIONSHIPS_BY_PARENT_ID =
    "research/tag-relationship/fetch-all-by-parent-id";
  TAG_RELATIONSHIP_SAVE = "research/tag-relationship";
  TAG_RELATIONSHIP_DELETE_BY_RELATIONSHIP_ID = "research/tag-relationship";

  GET_ALL_DOCUMENT_TYPES = "/common/document-types/document-counts";
  SAVE_RESEARCH_DOCUMENT = "/research/documents";
  GET_ALL_RECENT_DOCUMENTS = "/research/documents/recent";

  GET_ALL_CHAT_GROUPS_BY_PROJECT = "/common/chat-groups/DetailsByProjectId";
  GET_ALL_CHAT_MEMBERS = "/common/chat-group-members";
  GET_GROUP_RELATED_MEMBERS = "/common/chat-group-members";
  ADD_NEW_GROUP = "/common/chat-groups";
  SEND_NEW_MESSAGE = "/common/chat-messages/SaveChatMessage";
  // GET_ALL_PROJECT_MEMBERS_FOR_CHAT = "common/chat-group-members/ByProjectId";
  ADD_NEW_GROUP_MEMBERS_FOR_CHAT = "/common/chat-group-members";
  GET_ALL_PROJECT_MEMBERS_FOR_CHAT = "/Project/ProjectMember_GetAll";
  GET_ALL_CHAT_GROUPS_BY_PROJECT_AND_USER =
    "/common/chat-groups/ProjectGroupDetailsByUserId";

  GET_ALL_SHOOTLEGS = "/callsheet/shootleg";
  CREATE_SHOOTLEG = "/callsheet/shootleg";
  GET_CALL_SHEETS_BY_SHOOTLEG = "/callsheet";
  CREATE_CALLSHEET = "/callsheet";
  GET_ALL_DAY_EMERGENCY_BY_CALLSHEET = "/callsheet/dayemergency/bycallsheetid";
  SAVE_DAY_EMERGENCY = "callsheet/dayemergency";

  GET_ALL_LOCATIONS_BY_CALLSHEET = "callsheet/location";
  CREATE_DAY_LOCATION = "callsheet/location";
  DELETE_DAY_LOCATION = "callsheet/location";

  GET_ALL_DAY_CREW_BY_CALLSHEET = "callsheet/daycrew";
  SAVE_DAY_CREW_MEMBER = "callsheet/daycrew";
  DELETE_DAY_CREW_MEMBER = "callsheet/daycrew";

  SAVE_CONTACT_LIST_MEMBER = "callsheet/contactlist/member";
  SAVE_CONTACT_LIST_MEMBERS_LIST = "callsheet/contactlist/memberList";
  DELETE_CONTACT_LIST_MEMBER_BY_ID = "callsheet/contactlist/member";

  GET_ALL_DAY_LOCAL_TEAM_BY_CALLSHEET = "callsheet/daylocalteam/all";
  SAVE_DAY_LOCAL_TEAM_MEMBER = "callsheet/daylocalteam";
  DELETE_DAY_LOCAL_TEAM_MEMBER = "callsheet/daylocalteam";
  GET_ONBORDED_MEMBERS = "callsheet/daycrew/members";

  GET_ALL_DAY_OFFICE_BY_CALLSHEET = "callsheet/dayoffice";
  SAVE_DAY_OFFICE = "callsheet/dayoffice";
  DELETE_DAY_OFFICE = "callsheet/dayoffice";

  SAVE_SHOOT_LEG = "callsheet/shootleg";
  SAVE_CALLSHEET = "callsheet";

  GET_ALL_DAY_NOTICE_BY_CALLSHEET = "callsheet/daynotice";
  CREATE_DAY_NOTICE = "callsheet/daynotice";
  DELETE_DAY_NOTICE = "callsheet/daynotice";

  GET_CALLSHEET_BY_ID = "callsheet";
  DELETE_CALLSHEET_BY_ID = "callsheet";
  GET_ALL_DAY_SCHEDULES_BY_CALLSHEET = "callsheet/dayschedule";
  SAVE_DAY_SCHEDULE = "callsheet/dayschedule";
  DELETE_DAY_SCHEDULE = "callsheet/dayschedule";

  GET_ALL_SCHEDULE_TYPES = "callsheet/scheduletype";

  GET_SHOOTLEG_BY_ID = "callsheet/shootleg";

  GET_ALL_DAY_DAYBREAKS_BY_CALLSHEET = "callsheet/daybreak";
  CREATE_DAY_DAYBREAKS = "callsheet/daybreak";
  DELETE_DAY_DAYBREAKS = "callsheet/daybreak";

  UPDATE_LOCATION_EMERGENCY = "callsheet/locationemergency";

  GET_ALL_COLUMN_TYPES = "schedule/columntype/allcolumntypes";
  GET_ALL_SCHEDULE_ITEM_TYPES = "schedule/itemtype/allitemtypes";
  GET_SCHEDULE_LEGEND_DATA = "schedule/columntype/legend";
  GET_ACTIVITY_ITEM_TYPE_BY_BLOCK = "schedule/activityitemtype/block";
  CRAETE_SCHEDULE_ITEM = "schedule/scheduleitem";

  GET_ALL_SCHEDULE_MILESTONES =
    "schedule/milestoneitemtype/allmilestoneitemtypes";

  GET_ALL_MILESTONES = "schedule/itemtype/allitemtypes";
  CRAETE_ALL_SCHEDULE_MILESTONES = "schedule/itemtype";
  UPDATE_SCHEDULE_MILESTONES = "schedule/itemtype";
  DELETE_SCHEDULE_MILESTONES = "schedule/itemtype/";

  GET_ALL_ACTIVITY_ITEMS = "schedule/itemtype/allitemtypes";
  CREATE_ACTIVITY_ITEMS = "schedule/itemtype";
  UPDATE_ACTIVITY_ITEMS = "schedule/itemtype";
  DELETE_ACTIVITY_ITEMS = "schedule/itemtype/";

  GET_ALL_COLUMN_TYPE = "schedule/columntype/allcolumntypes";

  GET_ALL_BLOCK_TYPE = "schedule/blocktype/allblocktypes";

  GET_ALL_CALENDER_DATES = "schedule/calendar";
  CRATE_DAY_CONFIGS = "Project/DayTypeConfig";
  GENERTE_CALENDER_DATES = "schedule/calendar/projectdates";
  CONFIG_EPISODE_FOR_COLUMN_TYPES = "Project/ProjectEpisode/byepisodecount";
  GET_PROJECT_EPISODE_IDS = "Project/ProjectEpisode/allprojectepisodes";
  DELETE_SCHEDULE_ITEM = "schedule/scheduleitem/scheduledelete";

  GET_ALL_CAHTS = "common/chat-groups/searchAllChat";
  CHECK_AND_GET_CHAT_DATA = "/common/chat-groups/ExistingChatByUserId";

  MOVE_SCHEDULER_ITEMS = "schedule/scheduleitem/move";
  DELETE_SCHEDULER_ITEMS = "schedule/scheduleitem/scheduledelete";
}
export default API_ENDPOINTS;
