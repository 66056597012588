import React from "react";
import Icon from "../../../../../../common/components/UI/Icon";
import { CardItemWrapper } from "./styled";

const CardItem = ({ icon, caption, content }) => {
  return (
    <CardItemWrapper className="css-w-full">
      <div className="css-flex css-gap-16">
        <div className="start css-mt-8">
          <Icon icon={icon} iconSize={28} size={28} />
        </div>
        <div className="end css-grid css-w-full">
          <div className="css-text-primary-300 css-font-small-600">
            {caption}
          </div>
          <div className="css-text-neutral-100 css-font-large-600">
            {content}
          </div>
        </div>
      </div>
    </CardItemWrapper>
  );
};

export default CardItem;
