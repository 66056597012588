import React, { useCallback, useState } from "react";
import { Container } from "./styled";
import Header from "./components/Header";
import ContentCard from "./components/ContentCard";
import { useParams } from "react-router-dom";
import useUserData from "../../../../hooks/useUserData";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import { toast } from "react-toastify";
import { getProjectBrifeForProjectOrInvitaion } from "../../api";
import { useQuery } from "react-query";
import { setGlobalProject } from "../../../../store/GlobalStore";
export const ProjectBriefDataKey = "ProjectBriefDataKey";

const ProjectBrief = () => {
  const { projectId } = useParams();
  const { userId } = useUserData();
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const [data, setData] = useState(null);

  /******************************************************************************
   * Moved the fetch function to a separate useCallback hook to memoize it and   *
   * prevent unnecessary re-renders.                                             *
   * Added projectId and userId to the query key to ensure proper cache          *
   * invalidation when these values change.                                      *
   * Utilized the isLoading state from useQuery instead of manually managing     *
   * loader state.                                                               *
   * Removed redundant console.log statements and replaced the error log with    *
   * console.error for better error tracking.                                    *            *
   * Used the onSettled callback to hide the loader, which runs after both       *
   * success and error cases.                                                    *
   * Replaced data with projectBriefData in the destructuring to avoid naming    *
   * conflicts.                                                                  *
   * Updated the localStorage.setItem call to use the fetched data directly      *
   * instead of potentially stale state data.                                    *
   * Added a user-friendly error toast message when the fetch fails.             *
   ******************************************************************************/

  const fetchProjectBrief = useCallback(() => {
    showLoader();
    return getProjectBrifeForProjectOrInvitaion(projectId, userId);
  }, [projectId, userId, showLoader]);

  useQuery([ProjectBriefDataKey, projectId, userId], fetchProjectBrief, {
    refetchOnWindowFocus: true,
    onSuccess: (fetchedData) => {
      if (!fetchedData) return;

      setData(fetchedData);
      setGlobalProject(projectId);
      localStorage.setItem(
        "selected_project",
        JSON.stringify(fetchedData.projectDetails)
      );
    },
    onError: (error) => {
      console.error("Error fetching project brief:", error);
      toast.error("Failed to fetch project brief. Please try again.");
    },
    onSettled: () => {
      hideLoader();
    },
  });

  return (
    <Container className="css-bg-neutral-10">
      <div className="css-container css-mx-auto css-px-20">
        <div className="css-mx-auto css-w-full lg:css-w-3/4">
          <div className="css-my-24 css-gap-36 css-grid ">
            <Header
              companyData={data?.companyBasicInfo || null}
              status={
                data?.projectMemberDetails?.userCurrentStatusInProject || "NA"
              }
            />

            <ContentCard data={data} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ProjectBrief;
