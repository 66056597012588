import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0051 3.75191C11.0051 1.68335 9.32175 0 7.25319 0C5.18464 0 3.50129 1.68335 3.50129 3.75191C3.50129 5.82046 5.18464 7.50381 7.25319 7.50381C9.32175 7.50381 11.0051 5.82046 11.0051 3.75191ZM5.00205 3.75191C5.00205 2.51128 6.01257 1.50076 7.25319 1.50076C8.49382 1.50076 9.50434 2.51128 9.50434 3.75191C9.50434 4.99254 8.49382 6.00305 7.25319 6.00305C6.01257 6.00305 5.00205 4.99254 5.00205 3.75191Z"
      fill="currentColor"
    />
    <path
      d="M5.77994 9.50483C2.81343 9.50483 0.33217 11.7285 0.00450406 14.675C-0.0405188 15.0852 0.257132 15.4579 0.667341 15.5029C1.08005 15.5454 1.45024 15.2477 1.49526 14.84C1.73788 12.6539 3.57882 11.0056 5.77994 11.0056H6.25268C6.66539 11.0056 7.00306 10.6679 7.00306 10.2552C7.00306 9.8425 6.66539 9.50483 6.25268 9.50483H5.77994Z"
      fill="currentColor"
    />
    <path
      d="M15.5074 11.756C15.5074 10.5153 14.4969 9.50483 13.2562 9.50483H10.2547C9.84199 9.50483 9.50432 9.8425 9.50432 10.2552C9.50432 10.6679 9.84199 11.0056 10.2547 11.0056H12.9461L9.22418 14.7275C8.93153 15.0201 8.93153 15.4954 9.22418 15.788C9.50682 16.0707 10.0021 16.0707 10.2847 15.788L14.0066 12.0661V14.7575C14.0066 15.1702 14.3443 15.5079 14.757 15.5079C15.1697 15.5079 15.5074 15.1702 15.5074 14.7575V11.756Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
