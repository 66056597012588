import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import FieldGroup from "../../../../../../common/components/Form/FieldGroup";
import SelectField from "../../../../../../common/components/Form/SelectField";
import InputField from "../../../../../../common/components/Form/InputField";
import { WallComponent } from "../../../../../../common/components/UI/Modal/BaseFlexModal";
import Button from "../../../../../../common/components/Buttons/UIButton";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { useQuery } from "react-query";
import useUserData from "../../../../../../hooks/useUserData";
import { getAllMandatoryProfileDetails } from "../../../API/ProfileUpdate";
import { getIdentificationTypes } from "../../../../../../pages/Setting/api/profile";

const initialValues = {
  // Basic Details Section
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  postCode: "",

  // Identification Section
  nationality: "",
  documentType: null,
  documentNumber: "",

  // Company Details Section
  companyName: "",
  companyTaxNumber: "",
  companyAddress1: "",
  companyAddress2: "",
  companyCity: "",
  companyPostCode: "",
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required"),
  // nationality: Yup.string()
  //   .required("This field is required")
  //   .max(75, "The value may not be longer than 75 characters."),
  // documentNumber: Yup.string()
  //   .required("This field is required")
  //   .max(30, "The value may not be longer than 30 characters."),
  // documentType: Yup.object()
  //   .shape({
  //     label: Yup.string(),
  //     value: Yup.string(),
  //   })
  //   .nullable()
  //   .required("This field is required"),
});
export const MandatoryProfileDetailsKey = "MandatoryProfileDetailsKey";
const Form = ({ onSubmit }) => {
  const [profileData, setProfileData] = React.useState(null);
  const [identificationTypesList, setidentIficationTypesList] =
    React.useState(null);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const { userId } = useUserData();

  // Formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, options) => {
      onSubmit && onSubmit(values, options);
    },
  });

  const { touched, values, errors, handleSubmit, setFieldValue, handleChange } =
    formik;

  const { data: __profileData } = useQuery({
    queryKey: [MandatoryProfileDetailsKey],
    queryFn: () => getAllMandatoryProfileDetails(userId || ""),
  });
  React.useEffect(() => {
    getIdentificationTypes().then((identificationTypes) => {
      setidentIficationTypesList(identificationTypes);
    });
  }, []);
  React.useEffect(() => {
    showLoader();

    setProfileData(__profileData);
    setFieldValue("userId", __profileData?.userId || "");
    setFieldValue("firstName", __profileData?.personalInfo?.firstName || "");
    setFieldValue("lastName", __profileData?.personalInfo?.lastName || "");
    setFieldValue("email", __profileData?.personalInfo?.email || "");
    setFieldValue("address1", __profileData?.personalInfo?.address || "");
    setFieldValue(
      "address2",
      __profileData?.personalInfo?.addressOptional || ""
    );
    setFieldValue("city", __profileData?.personalInfo?.city || "");
    setFieldValue("postCode", __profileData?.personalInfo?.postCode || "");

    setFieldValue(
      "nationality",
      __profileData?.defaultUserIdentification?.nationality
    );
    setFieldValue(
      "documentNumber",
      __profileData?.defaultUserIdentification?.documentNumber
    );
    setFieldValue(
      "documentType",
      identificationTypesList?.find(
        (i) =>
          i.value ===
          __profileData?.defaultUserIdentification?.identificationTypeId
      )
    );

    setFieldValue("companyName", __profileData?.brRegistrationInfo?.name || "");
    setFieldValue(
      "companyTaxNumber",
      __profileData?.brRegistrationInfo?.taxRegistrationNumber || ""
    );
    setFieldValue(
      "companyAddress1",
      __profileData?.brRegistrationInfo?.address || ""
    );
    setFieldValue(
      "companyAddress2",
      __profileData?.brRegistrationInfo?.addressOptional || ""
    );
    setFieldValue("companyCity", __profileData?.brRegistrationInfo?.city || "");
    setFieldValue(
      "companyPostCode",
      __profileData?.brRegistrationInfo?.postCode || ""
    );

    hideLoader();
  }, [
    __profileData,
    hideLoader,
    identificationTypesList,
    setFieldValue,
    showLoader,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <WallComponent>
        <div className="css-grid css-gap-28">
          {/* Basic Details Section */}
          <FormSection title="Basic Details Section">
            <div className="css-grid css-grid-cols-6 css-gap-16">
              <div className="css-col-span-3">
                <FieldGroup
                  label="First Name"
                  error={touched.firstName && errors.firstName}
                  isRequired
                >
                  <InputField
                    name="firstName"
                    placeholder="Enter your first name"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>

              <div className="css-col-span-3">
                <FieldGroup
                  label="Last Name"
                  error={touched.lastName && errors.lastName}
                  isRequired
                >
                  <InputField
                    placeholder="Enter your last name"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>

              <div className="css-col-span-6">
                <FieldGroup
                  label="Email"
                  error={touched.email && errors.email}
                  isRequired
                >
                  <InputField
                    placeholder="Enter your email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>

              <div className="css-col-span-6">
                <FieldGroup label="Address">
                  <div className="css-grid css-grid-cols-2 css-gap-16">
                    <InputField
                      placeholder="Street/house/apartment/unit*"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                    />
                    <InputField
                      placeholder="Apt/suite/etc (optional)"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                    />
                  </div>
                </FieldGroup>
              </div>

              <div className="css-col-span-3">
                <FieldGroup label="City">
                  <InputField
                    placeholder="Enter your city"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>

              <div className="css-col-span-3">
                <FieldGroup label="Post Code">
                  <InputField
                    placeholder="Enter post code"
                    name="postCode"
                    value={values.postCode}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>
            </div>
          </FormSection>

          {/* Identification Section */}
          <FormSection title="Identification Section">
            <div className="css-grid css-grid-cols-3 css-gap-16">
              <div className="css-col-span-1">
                <FieldGroup
                  label="Nationality"
                  error={touched.nationality && errors.nationality}
                  isRequired
                >
                  <InputField
                    placeholder="Enter your nationality"
                    name="nationality"
                    value={values.nationality}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>

              <div className="css-col-start-1 css-col-end-1">
                <FieldGroup
                  label="Document Type"
                  error={touched.documentType && errors.documentType}
                  isRequired
                >
                  <SelectField
                    options={identificationTypesList}
                    value={values.documentType}
                    onChange={(val) => {
                      setFieldValue("documentType", val);
                    }}
                  />
                </FieldGroup>
              </div>

              <div className="css-col-span-2">
                <FieldGroup
                  label="Document Number"
                  error={touched.documentNumber && errors.documentNumber}
                  isRequired
                >
                  <InputField
                    placeholder="Enter your document number"
                    name="documentNumber"
                    value={values.documentNumber}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>
            </div>
          </FormSection>

          {/* Company Details Section    */}
          <FormSection title=" Company Details Section">
            <div className="css-grid css-grid-cols-4 css-gap-16">
              <div className="css-col-span-4">
                <FieldGroup label="Company Name">
                  <InputField
                    placeholder="Enter company name"
                    name="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>

              <div className="css-col-span-4">
                <FieldGroup label="Tax Registration Number">
                  <InputField
                    placeholder="Enter tax registration number"
                    name="companyTaxNumber"
                    value={values.companyTaxNumber}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>

              <div className="css-col-span-4">
                <FieldGroup label="Address">
                  <div className="css-grid css-grid-cols-2 css-gap-16">
                    <InputField
                      placeholder="Street/house/apartment/unit*"
                      name="companyAddress1"
                      value={values.companyAddress1}
                      onChange={handleChange}
                    />
                    <InputField
                      placeholder="Apt/suite/etc (optional)"
                      name="companyAddress2"
                      value={values.companyAddress2}
                      onChange={handleChange}
                    />
                  </div>
                </FieldGroup>
              </div>

              <div className="css-col-span-2">
                <FieldGroup label="City">
                  <InputField
                    placeholder="Enter your city"
                    name="companyCity"
                    value={values.companyCity}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>

              <div className="css-col-span-2">
                <FieldGroup label="Post Code">
                  <InputField
                    placeholder="Enter post code"
                    name="companyPostCode"
                    value={values.companyPostCode}
                    onChange={handleChange}
                  />
                </FieldGroup>
              </div>
            </div>
          </FormSection>
        </div>
      </WallComponent>

      <div className="css-flex css-justify-end css-mt-24">
        <Button type="submit" size={Button.Sizes.Small}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default Form;

function FormSection({ title, children }) {
  return (
    <div className="css-grid css-gap-8">
      <div className="css-font-base-700 css-text-primary-400">{title}</div>
      <div className="css-grid">{children}</div>
    </div>
  );
}
