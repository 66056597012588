import React from "react";
import { Modal } from "./styled";
import ModalBody from "./ModalBody";
import {
  closeProfileInfoModal,
  onFinished,
  passToNext,
  useProfileInfoModal,
} from "./store";
import useProfileStore from "./hooks/useProfileStore";
import { assignRateCardToProjectMember, joinProject } from "../API/ProfileInfo";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import { toast } from "react-toastify";
import { ONBOARDING_STATUS } from "../../../../constant/Status";
import {
  setProjectMemberStatus,
  setProjectMemberStatusByManually,
} from "../../../../Actions/Project";
import { ProjectBriefDataKey } from "../../../../pages/Project/screens/ProjectBrief";
import { useQueryClient } from "react-query";
import useUserData from "../../../../hooks/useUserData";

const ProfileInfoModal = () => {
  const visibility = useProfileInfoModal((state) => state.isVisible);
  const { currentStep: current } = useProfileStore();
  const { metadata } = useProfileInfoModal();
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const queryClient = useQueryClient();
  const { userId } = useUserData();

  // API call for the Form submission
  const onSubmit = (key, val) => {
    switch (key) {
      case "1": {
        console.log("key 1", val);
        passToNext();
        return;
      }

      case "2": {
        console.log("key 2", val);

        const payload = {
          rateCardId: val?.rateCardId || 0,
          projectMemberId: metadata?.projectMemberDetails?.projectMemberId || 0,
        };
        showLoader();
        assignRateCardToProjectMember(payload)
          .then((res) => {
            if (res?.isSuccess) {
              const projectMemberStatusBody = {
                projectId: metadata?.projectDetails?.projectId || 0,
                userId: userId,
                memberStatusId:
                  ONBOARDING_STATUS.RatesAndPaymentScheduleRequested,
              };
              var base_url = window.location.origin;
              const joinProjectPayload = {
                projectId: metadata?.projectDetails?.projectId || 0,
                userId: userId,
                link:
                  base_url +
                  "/rate-and-payment/" +
                  (metadata?.projectMemberDetails?.projectMemberId || 0) +
                  "/" +
                  (metadata?.projectDetails?.projectId || 0),
              };

              joinProject(joinProjectPayload).then((res) => {
                if (res?.isSuccess) {
                  setProjectMemberStatusByManually(
                    projectMemberStatusBody
                  ).then(() => {
                    queryClient.refetchQueries([ProjectBriefDataKey]);
                    hideLoader();
                  });
                  hideLoader();
                  toast.success(
                    "Data Submitted & Joined to the Project successfully!"
                  );
                  onFinished();
                } else {
                  setProjectMemberStatusByManually(
                    projectMemberStatusBody
                  ).then(() => {
                    queryClient.refetchQueries([ProjectBriefDataKey]);
                    hideLoader();
                  });
                  hideLoader();
                  toast.success(
                    "Data Submitted & Joined to the Project successfully!"
                  );
                  onFinished();
                  hideLoader();
                  toast.error(res?.displayMessage);
                }
              });
            } else {
              hideLoader();
              toast.error("Oops something went wrong!");
            }
          })
          .catch((err) => {
            hideLoader();
            toast.error("Oops something went wrong!");
            console.log(err);
          });

        return;
      }

      default:
        return null;
    }
  };

  return (
    <Modal
      visibility={visibility}
      title={current?.title}
      subTitle={current?.subTitle}
      onClose={closeProfileInfoModal}
      bodyComponent={(bodyProps) => (
        <ModalBody onSubmit={onSubmit} {...bodyProps} />
      )}
    />
  );
};

export default ProfileInfoModal;
