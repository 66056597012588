import React, { useState, useEffect } from "react";
import FieldControl from "../FieldControl";
import styled from "styled-components";

const DropdownContainer = styled.div
`
  display: flex;
  align-items: center;
  background: white; 
`;

const CustomTimeDurationField = ({ name, value, onChange, disabled }) => {
  const [ProcessingTimeUnit, setProcessingTimeUnit] = useState(value?.unit || "days");
  const [ProcessingTimeValue, setProcessingTimeValue] = useState(value?.value || 1);

 // Parse the combined value into separate value and unit
 const parseCombinedValue = (combinedValue) => {
  if (typeof combinedValue === 'string') {
    console.log("Combined Value: ", combinedValue);
    const parts = combinedValue.split(' '); 
    const parsedValue = parseInt(parts[0], 10); 
    const unit = parts[1] || 'days'; 
    return { value: parsedValue, unit }; 
  } else if (typeof combinedValue === 'object' && combinedValue !== null) {
    // If it's an object, extract the value and unit directly
    const unit = combinedValue.unit || 'days'; 
    const value = combinedValue.value || 1; 
    return { value, unit };
  }
  return { value: 1, unit: 'days' }; // Default values if combinedValue is neither string nor obj

};

  useEffect(() => {
    const { value: parsedValue, unit } = parseCombinedValue(value);
      setProcessingTimeUnit(unit);
      setProcessingTimeValue(parsedValue);
      // console.log("Value: ", parsedValue);
      // console.log("Unit: ", unit);
    
  }, [value]);

  const getMaxValue = () => {
    switch (ProcessingTimeUnit) {
      case "days":
        return 31;
      case "weeks":
        return 52;
      case "months":
        return 12;
      default:
        return 31;
    }
  };

  const handleTimeUnitChange = (e) => {
    const newUnit = e.target.value;
    setProcessingTimeUnit(newUnit);

    const formattedValue = `${ProcessingTimeValue} ${newUnit}`;
    onChange(formattedValue);
  };

  const handleTimeValueChange = (e) => {
    const newValue = e.target.value;
    setProcessingTimeValue(newValue);

    const formattedValue = `${newValue} ${ProcessingTimeUnit}`;
    onChange(formattedValue);
  };

  return (
    <FieldControl className="custom-time-duration" isDisabled={disabled}>
     <DropdownContainer> 
      {/* Time Unit Dropdown */}
      <select
        value={ProcessingTimeUnit}
        onChange={handleTimeUnitChange}
        disabled={disabled}
        className="time-unit-dropdown"
        style={{
            width: "auto", // Allow the first dropdown to have auto width
            marginRight: "20px",
          }}
      >
        <option value="days">Days</option>
        <option value="weeks">Weeks</option>
        <option value="months">Months</option>
      </select>
      </ DropdownContainer>
      <DropdownContainer> 
      {/* Time Value Dropdown */}
      <select
        value={ProcessingTimeValue}
        onChange={handleTimeValueChange}
        disabled={disabled}
        className="time-value-dropdown"
        style={{   
            width: "132px", 
          }}
      >
        {[...Array(getMaxValue()).keys()].map((val) => (
          <option key={val + 1} value={val + 1}>
            {val + 1}
          </option>
        ))}
      </select>
      </ DropdownContainer> 
    </FieldControl>
  );
};

export default CustomTimeDurationField;
