import React from "react";
import TextAreaField from "../../../common/components/Form/TextAreaField";
import Notes from "./components/Notes";
import NoteContextProvider, { useNote } from "./context/NoteContext";
import { TextAreaWrapper } from "./styled";
import IconButton from "../../../common/components/Buttons/IconButton";
import IconSend from "../../../common/components/icons/Fill/Send/01";
import LoadingSpinner from "../../../common/components/UI/LoadingSpinner";
export const NOTE_TYPE = {
  None: 0,
  Note: 1,
  ChangeRequest: 2,
};
const NoteBoard = (
  {
    projectId,
    projectMemberId,
    textAreaLabel = "Enter your note",
    listAreaLabel = "Notes",
    textAreaPlaceholder = "Type your message here...",
    noteType = NOTE_TYPE.Note,
    hideTextArea,
    noteMarkAsReadOnMounted,
  },
  ref
) => {
  return (
    <NoteContextProvider
      projectId={projectId}
      memberId={projectMemberId}
      noteType={noteType}
      noteMarkAsReadOnMounted={noteMarkAsReadOnMounted}
    >
      <NoteInner
        fRef={ref}
        hideTextArea={hideTextArea}
        listAreaLabel={listAreaLabel}
        textAreaLabel={textAreaLabel}
        textAreaPlaceholder={textAreaPlaceholder}
      />
    </NoteContextProvider>
  );
};

function NoteInner({
  listAreaLabel,
  hideTextArea,
  textAreaLabel,
  textAreaPlaceholder,
  fRef,
}) {
  const { handleSubmit } = useNote();

  React.useImperativeHandle(fRef, () => {
    return {
      onSend: handleSubmit,
    };
  });

  return (
    <div className="css-grid css-gap-8">
      {/* history list */}
      <NotesList listAreaLabel={listAreaLabel} />

      {/*controller  */}
      {!hideTextArea ? (
        <NoteController
          textAreaLabel={textAreaLabel}
          textAreaPlaceholder={textAreaPlaceholder}
        />
      ) : null}
    </div>
  );
}

function NotesList({ listAreaLabel }) {
  const { notes } = useNote();
  const scrollListRef = React.useRef(null);

  React.useEffect(() => {
    if (scrollListRef.current) {
      scrollListRef.current.scrollTop = scrollListRef.current.scrollHeight;
    }
  }, [notes.length]);

  if (!notes.length) return null;

  return (
    <div className="css-grid">
      <div className="css-text-primary-300 css-font-small-600">
        {listAreaLabel}
      </div>

      {/* Notes list */}
      <div
        ref={scrollListRef}
        className="css-rounded-4 css-bg-neutral-30 css-p-16 css-max-h-[260px] css-overflow-y-auto"
      >
        <Notes data={notes} />
      </div>
    </div>
  );
}

// Text area
function NoteController({ textAreaLabel, textAreaPlaceholder }) {
  const {
    setFormValue,
    formValues,
    projectId,
    projectMemberId,
    isFormValid,
    textAreaRef,
    isNoteSubmitting,
    handleSubmit,
  } = useNote();

  const isTextAreaDisabled = !projectId || !projectMemberId;

  return (
    <div className="css-grid">
      <div className="css-text-primary-300 css-font-small-600">
        {textAreaLabel}
      </div>

      <div className="css-w-full">
        <TextAreaWrapper $isDisabled={isTextAreaDisabled || isNoteSubmitting}>
          <TextAreaField
            ref={textAreaRef}
            disabled={isTextAreaDisabled}
            value={formValues?.note}
            placeholder={textAreaPlaceholder}
            onChange={(e) => {
              setFormValue("note", e.target.value);
            }}
          />

          <div className="send-btn__wrapper">
            <IconButton
              data-tooltip-text="Send"
              icon={
                isNoteSubmitting ? (
                  <LoadingSpinner color="#2d5966" />
                ) : (
                  <IconSend />
                )
              }
              btnSize={32}
              btnIconSize={16}
              inheritParentColor
              disabled={!isFormValid || isTextAreaDisabled || isNoteSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </TextAreaWrapper>
      </div>
    </div>
  );
}

export default React.forwardRef(NoteBoard);
