import React from "react";
import BaseCard from "./BaseCard";
import CardItem from "./CardItem";
import NoteBoard from "../../../../../../shared/components/NoteBoard";

import IconTemplates from "../../../../../../common/components/icons/Line/all-templates";
import IconClapper from "../../../../../../common/components/icons/Line/clapper";
import IconCalendar from "../../../../../../common/components/icons/Fill/Calendar/01";
import Avatar from "../../../../../../common/components/UI/Avatar";

const MemberCard = ({ projectId, memberData, noteRef }) => {
  return (
    <BaseCard
      headerStart={<HeaderStart data={memberData} />}
      content={
        <Content noteRef={noteRef} _projectId={projectId} data={memberData} />
      }
    />
  );
};

function HeaderStart({ data }) {
  return (
    <div className="css-flex css-gap-16 css-items-center">
      <Avatar src={data?.profileImage} size="size-60" />
      <div className="css-grid">
        <div className="css-font-base-600 css-text-black">
          {data?.memberName}
        </div>
        <div className="css-font-label-600 css-text-neutral-100">
          {data?.jobTitleName}
        </div>
      </div>
    </div>
  );
}

function Content({ _projectId, data, noteRef }) {
  return (
    <div className="css-grid css-gap-16">
      {/* Join as */}
      <CardItem
        icon={<IconTemplates />}
        caption="Join as"
        content={data?.roleInProject}
      />

      {/* Start & End Dates */}
      <CardItem
        icon={<IconCalendar />}
        caption="Start & End Dates"
        content="August 17 - November 26"
      />

      {/* Assign to  */}
      <CardItem
        icon={<IconClapper />}
        caption="Assign to "
        content="01 & 03 Episodes"
      />

      {/* Notes */}
      <div>
        <NoteBoard
          ref={noteRef}
          projectId={_projectId || 0}
          projectMemberId={data?.projectMemberId || 0}
        />
      </div>
    </div>
  );
}

export default MemberCard;
