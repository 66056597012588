import React from "react";

import Button from "../../../../../common/components/Button";
import DropdownMenu from "../../../../../common/components/DropdownMenu";
import FavoriteButton from "../../../../../common/components/FavoriteButton";
import useSystemStatus from "../../../../../hooks/useSystemStatus";
import Avatar from "../../../../ui-components/Avatar";
import OnboardingStatusIcon from "../OnboardingStatusIcon";
import { StyledCard } from "./styled";
import IconButton from "../../../../../common/components/Buttons/IconButton";
import { ActionIcons } from "../../../../../pages/Onboarding/shared/ActionIcons";
import { openNotesDialog } from "../../../../../pages/Onboarding/components/Dialogs/NotesViewDialog/store";
import { ONBOARDING_STATUS } from "../../../../../constant/Status";

const CardItem = (props) => {
  const {
    viewType = "card",
    data,
    onActionClick,
    hasActionPermission,
    menuItems = [],
    pageType = "onboarding",
    onNotesMarkAsRead,
  } = props;

  const status = data?.statusId || 0;
  var base_url = window.location.origin;
  var RATE_AND_PAYMENT_SCHEDULE_URL = "/rate-and-payment/";
  var NDA_CHANGE_URL = "/onboardings/2/nda-change-request/";

  const { getOnboardingStatus } = useSystemStatus();

  const statusContent = React.useMemo(() => {
    if (status < 0) {
      return null;
    }

    return getOnboardingStatus(status);
  }, [status, getOnboardingStatus]);

  const { color, action } = statusContent;

  const getHslColor = React.useCallback(
    (val) => {
      const rex = /([0-9 %])+/g;
      const [h, s] = color.match(rex);
      return `hsl(${h},${s}, ${val})`;
    },
    [color]
  );

  const btnStyles = React.useMemo(
    () => ({
      bgColor: getHslColor("90%"),
      bgColorHover: getHslColor("82%"),
      bgColorDisabled: getHslColor("95%"),
      textColor: color,
      textColorHover: color,
      textColorDisabled: getHslColor("75%"),
    }),
    [color, getHslColor]
  );

  const { label, type: actionType, icon: ActionIcon } = action;

  const skills = React.useMemo(() => {
    const _skillList = data?.skillList || [];

    const all = _skillList?.map((skill) => {
      return skill.skillName;
    });

    return all?.join(", ");
  }, [data?.skillList]);

  const onActionHandler = React.useCallback(() => {
    if (!onActionClick) {
      return;
    }

    onActionClick({
      type: typeof actionType === "object" ? actionType[pageType] : actionType,
      payload: {
        member: data,
      },
    });
  }, [onActionClick, actionType, pageType, data]);

  const renderAction = React.useMemo(() => {
    if (!actionType) {
      return null;
    }

    let btnLabel = typeof label === "object" ? label[pageType] : label;

    if (!btnLabel) {
      return null;
    }

    return (
      <>
        {hasActionPermission ? (
          <>
            <div className="line"></div>
            <Button
              variant="custom"
              customBtnStyles={btnStyles}
              size="sm"
              onClick={onActionHandler}
            >
              <Button.LeftIcon>
                <ActionIcon />
              </Button.LeftIcon>
              {btnLabel}
            </Button>
          </>
        ) : null}
      </>
    );
  }, [
    actionType,
    label,
    hasActionPermission,
    btnStyles,
    onActionHandler,
    pageType,
  ]);

  return (
    <StyledCard statusColor={color} viewType={viewType}>
      <div className="card__inner">
        <div className="card__avatar">
          <Avatar
            hasShadow
            onMessageClick={() => {}}
            messageBgColor={color}
            size={viewType === "list" ? "size-44" : "size-60"}
            src={data?.profileImage}
          />

          {/* Favorite Button */}
          {viewType === "list" ? (
            <FavoriteButton isFavorite={false} onClick={() => {}} />
          ) : null}
        </div>

        <div className="card__options">
          <div className="card__options__inner">
            {/* Favorite Button */}
            {viewType === "card" ? (
              <FavoriteButton isFavorite={false} onClick={() => {}} />
            ) : null}

            {/* Onboarding Status */}
            <OnboardingStatusIcon status={status} />

            {viewType === "list" ? (
              <div className="css-flex css-items-center css-gap-16 css-ml-16">
                {/* Notes */}
                <NotifyButton
                  hasNotification={!!data?.unreadNoteCount}
                  tooltip="Notes"
                  icon={<ActionIcons.Note />}
                  onClick={() => {
                    onNotesMarkAsRead(data);
                    openNotesDialog({
                      projectId: data?.projectId || 0,
                      memberId: data?.projectMemberId || 0,
                    });
                  }}
                />

                <IconButton
                  data-tooltip-text="Rate Card Availability"
                  icon={<ActionIcons.RateCard />}
                  btnSize={20}
                  btnIconSize={16}
                  onClick={() => {
                    window.open(
                      base_url +
                        RATE_AND_PAYMENT_SCHEDULE_URL +
                        (data?.projectMemberId || 0) +
                        "/" +
                        (data?.projectId || 0),
                      "_blank"
                    );
                  }}
                />
                {data?.statusId === ONBOARDING_STATUS.NDAChangeRequested ? (
                  <NotifyButton
                    hasNotification={true}
                    tooltip="Change NDA"
                    icon={<ActionIcons.NDASign />}
                    onClick={() => {
                      window.open(
                        base_url +
                          NDA_CHANGE_URL +
                          (data?.projectId || 0) +
                          "/" +
                          (data?.memberUserId || 0) +
                          "/" +
                          (data?.projectMemberId || 0),
                        "_blank"
                      );
                    }}
                  />
                ) : null}

                {/* <IconButton
                  data-tooltip-text="Sign Contract"
                  icon={<ActionIcons.ContractSign />}
                  btnSize={20}
                  btnIconSize={16}
                />

                <IconButton
                  data-tooltip-text="Sign NDA"
                  icon={<ActionIcons.NDASign />}
                  btnSize={20}
                  btnIconSize={16}
                /> */}
              </div>
            ) : null}

            {/* More button */}
            {/* <KebabButton /> */}
            <DropdownMenu options={menuItems} />
          </div>
        </div>

        <div className="card__contents">
          {/* Name */}
          <div className="profile__name">
            <div className="name__1">{data?.displayName}</div>

            {/* divide */}
            {data?.displayName && data?.givenName ? (
              <div className="divide"></div>
            ) : null}

            <div className="name__2">
              {data?.givenName + " " + data?.surName}
            </div>
          </div>

          {/* Job title */}
          <div className="job__title">{data?.jobTitleName}</div>

          {/* Other skills */}
          {skills ? <div className="skills">{skills}</div> : null}
        </div>

        <div className="card__actions">{renderAction}</div>
      </div>
    </StyledCard>
  );
};

export default React.memo(CardItem);

function NotifyButton({ icon, tooltip, onClick, hasNotification }) {
  return (
    <div className="css-relative">
      {hasNotification ? (
        <span className="css-block css-w-8 css-h-8 css-rounded-8 css-z-10 css-bg-danger-200 css-absolute css-top-0 css-right-0"></span>
      ) : null}
      <IconButton
        data-tooltip-text={tooltip}
        icon={icon}
        btnSize={20}
        btnIconSize={16}
        onClick={onClick}
      />
    </div>
  );
}
