import React from "react";
import clsx from "clsx";

const Trademark = (props) => {
  const { size, className, src } = props;

  const sizeClz = React.useMemo(() => {
    switch (size) {
      case "32": {
        return `css-max-w-32 css-h-32`;
      }

      default: {
        return "css-max-w-60 css-h-60";
      }
    }
  }, [size]);

  return (
    <div
      className={clsx(
        "css-w-full css-flex css-items-center css-justify-center css-rounded-4 css-overflow-hidden",
        sizeClz,
        className
      )}
    >
      <img className="css-w-full css-max-w-full" src={src} alt="Trademark" />
    </div>
  );
};

export default Trademark;
