import React, { useState, useMemo } from "react";
import { DropDownMenu, List } from "./styled";
import UIButton from "../../../../../common/components/Buttons/UIButton";
import IconMenu from "../../../../../common/components/icons/Fill/menu";
import IconSetting from "../../../../../common/components/icons/Module/project-settings-01";
import IconButton from "../../../../../common/components/Buttons/IconButton";
import { PopoverDropdownMenuItem } from "../../../../../common/components/UI/PopoverDropdown";

const ProjectListView = (props) => {
  const {
    data,
    handleBrief,
    handleOnboarding,
    handleDelete,
    handleFeature,
    handleEdit,
    handleProjectSetting,
  } = props;

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = useMemo(() => {
    return data?.filter((project) =>
      project.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [data, searchQuery]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <div className="mb-4 mt-4">
        <input
          className="form-control w-full"
          type="text"
          placeholder="Search projects..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <List>
        {filteredData?.length > 0 ? (
          filteredData.map((row, i) => (
            <div className="list__item" key={i}>
              <div className="list__item__col mw-0">
                <div className="project-name">
                  <span>{row?.name}</span>
                </div>
              </div>
              <div className="list__item__col list__item__col--right gap-8">
                <div>
                  <UIButton
                    className="tb__button"
                    size={UIButton.Sizes.Small}
                    onClick={() => handleOnboarding && handleOnboarding(row)}
                  >
                    Onboarding
                  </UIButton>

                  {handleProjectSetting && (
                    <div className="css-flex css-items-center css-justify-center css-text-tint-4 hover:css-text-primary-300">
                      <IconButton
                        icon={<IconSetting />}
                        btnIconSize={16}
                        btnSize={24}
                        inheritParentColor
                        onClick={() => handleProjectSetting(row)}
                      />
                    </div>
                  )}

                  <DropDownMenu
                    customDropdownIcon={
                      <IconButton
                        as="span"
                        icon={<IconMenu />}
                        btnIconSize={16}
                        btnSize={24}
                        hover="var(--font-tint-04)"
                      />
                    }
                    options={[
                      {
                        label: "Project Brief",
                        onClick: () => handleBrief && handleBrief(row),
                        disabled: !handleBrief,
                      },
                      {
                        label: "Features ",
                        onClick: () => handleFeature && handleFeature(row),
                        disabled: !handleFeature,
                      },
                      {
                        label: "Edit",
                        onClick: () => handleEdit && handleEdit(row),
                        disabled: !handleEdit,
                      },
                      {
                        label: "Delete",
                        onClick: () => handleDelete && handleDelete(row),
                        disabled: !handleDelete,
                      },
                    ]}
                    render={(option, { closeDropdown }) => (
                      <PopoverDropdownMenuItem
                        label={option?.label}
                        onClick={() => {
                          closeDropdown();
                          option?.onClick && option?.onClick();
                        }}
                        isDisabled={option?.disabled}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="list__item">
            <div className="list__item__col">
              <div>
                {searchQuery
                  ? "No projects found matching your search."
                  : "No projects available."}
              </div>
            </div>
          </div>
        )}
      </List>
    </>
  );
};

export default ProjectListView;
