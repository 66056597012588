import produce from "immer";
import { create } from "zustand";

let store = () => ({
  isVisible: false,
  isSubmit: false,
  metadata: null,
  steps: [
    {
      key: "1",
      title: "Provide Your Availability",
      subTitle: "Click any date and add in your availability status",
      isActive: true,
      isCompleted: false,
      isEnabled: true,
      meta: null,
    },
    {
      key: "2",
      title: "Provide Your Rate Card",
      subTitle: "Select a rate card or create a new one",
      isActive: false,
      isCompleted: false,
      isEnabled: true,
      meta: null,
    },
  ],
});

export const useProfileInfoModal = create(store);
const state = useProfileInfoModal;

const navigate = (to = 1) => {
  state.setState(
    produce((draft) => {
      draft.isSubmit = false;
      const currentIndex = draft.steps.findIndex((d) => d?.isActive);
      draft.steps[currentIndex].isActive = false;
      draft.steps[currentIndex + to].isActive = true;
    })
  );
};

// outside functions

export const showProfileInfoModal = (data) => {
  state.setState(
    produce((draft) => {
      draft.isVisible = true;
      draft.steps[0].isActive = true;
      draft.metadata = data;
    })
  );
};

export const closeProfileInfoModal = () => {
  state.setState(
    produce((draft) => {
      draft.isVisible = false;
      draft.isSubmit = false;
      draft.metadata = null;
    })
  );
};

export const onFinished = () => {
  state.setState(
    produce((draft) => {
      draft.isVisible = false;
      draft.isSubmit = false;
    })
  );
};

export const getCurrentStep = () => {
  return state.getState()?.steps?.find((d) => d?.isActive);
};
export const onSubmitFailed = () => {
  state.setState(
    produce((draft) => {
      draft.isSubmit = false;
    })
  );
};
export const onNext = () => {
  state.setState(
    produce((draft) => {
      draft.isSubmit = true;
    })
  );
};

export const passToNext = () => {
  navigate(1);
};

export const onPrevious = () => {
  navigate(-1);
};

export const navigationStatus = () => {
  const current = state.getState()?.steps?.findIndex((d) => d?.isActive);

  return {
    hasPrevious: current > 0,
    hasNext: state.getState()?.steps.length - 1 !== current,
  };
};
