import IconChat from "../../../common/components/icons/Line/chat";
import IconNote from "../../../common/components/icons/Line/Notes/04";
import IconRateCard from "../../../common/components/icons/Line/Ratecard/01";
import IconContractSign from "../../../common/components/icons/Line/Contract/01";
import IconNDASign from "../../../common/components/icons/Line/NDA/01";
import IconDot from "../../../common/components/icons/Fill/menu";

export const ActionIcons = {
  Chat: IconChat,
  DotV: IconDot,
  Note: IconNote,
  RateCard: IconRateCard,
  ContractSign: IconContractSign,
  NDASign: IconNDASign,
};
