import API_ENDPOINTS from "../../services/api-endpoints";
import API from "../../services/api-project";

export const getNDADetails = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.get(`${endpoints.PROJECT_GET_NDA_DETAILS}/${id}`)
      .then((res) => {
        const data =
          { ...res?.data?.result, isSuccess: res?.data?.isSuccess } || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};

/**
 * Get Member NDA Details by projectID and memberId,
 * @param {{
 *  projectId: string,
 *  memberId:string
 * }} params - parameter object
 *
 * @returns {Promise<{projectInvitationDetails,ndaDetails}>}
 */
export const getMemberNDADetails = async ({ projectId, memberId }) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    if (!memberId || !projectId) {
      reject({ error: "Params not provided" });
    }

    API.get(
      `${endpoints.PROJECT_GET_MEMBER_NDA_DETAILS}/${projectId}/${memberId}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};

export const getNDADetails2 = (id) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.get(`${endpoints.PROJECT_GET_NDA_DETAILS}/${id}`)
      .then((res) => {
        const data = {
          isSuccess: res.data.isSuccess,
          displayMessage: res?.data?.displayMessage,
          result: res?.data?.result,
        };

        resolve(data || null);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitInvitation = (parms) => {
  const { id, status, remark } = parms;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.post(`${endpoints.PROJECT_MEMBER_INVITATION_ACCEPT_OR_REJECT}`, {
      invitationId: id,
      isAcceptOrReject: status,
      acceptOrRejectRemarks: remark,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};

export const submitNDA = (parms) => {
  const { id, status, remark } = parms;

  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API.post(`${endpoints.PROJECT_NDA_ACCEPT_DECLINE}`, {
      ndaReferenceId: id,
      acceptOrReject: status,
      acceptOrRejectRemarks: remark,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
