import React from "react";
// import { useDrag } from "react-dnd";

import Episode from "./Episode";
import { StyledCardWrapper, StyledTagType } from "./styled";
import Header from "./Header";
import Contents from "./Content";
// import SideMenu from "./SideMenu";
// import Popover from "../../../../../common/components/Popover";
// import { DragItemTypes } from "../../../constant";
import {
  TAG_MODAL_MODE,
  useResearchBoardStore,
} from "../../../store/ResearchBoardStore";
import IconButton from "../../../../../common/components/Buttons/IconButton";
import { getSubtitle, getVisualType } from "../../../utils/tagHelper";

export const RESEARCH_FILED_DISPLAY_TYPE = {
  NA: 0,
  TITLE: 1,
  SUBTITLE: 2,
  DISCRIPTION: 3,
  VISUAL: 4,
};

export const RESEARCH_TAG_VISUAL_TYPE = {
  NA: 0,
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  LOCATION: 4,
  SLIDER: 5,
  EMPTY: 100,
};

export const RESEARCH_TAG_WHO = {
  AUTHOR: "Author",
  EDITOR: "Editor",
};

const TagCard = (props) => {
  const {
    variant,
    hideSideMenu,
    showEpisodes,
    hideRightActions = false,
    tagdata,
    formdata,
    subTagsCategorizedTagFileds,
    iconComponent,
    // isContentScrolling,
    // canDraggable = false,
    afterEditActionFired,
    showTagTypeInfo = true,
  } = props;

  const onShowPreviewModal = useResearchBoardStore(
    (state) => state.onShowPreviewModal
  );
  const onShowTagModal = useResearchBoardStore((state) => state.onShowTagModal);

  // const { tagId, tagTypeId, mainFieldValue } = tagdata;

  // const [collected, drag, dragPreview] = useDrag(() => ({
  //   type: DragItemTypes.TagCard,
  //   item: {
  //     id: tagId,
  //     tagTypeId: tagTypeId,
  //     name: mainFieldValue,
  //   },
  //   canDrag: () => {
  //     return tagId && mainFieldValue.length > 0 && canDraggable ? true : false;
  //   },
  //   end: (item, monitor) => {
  //     const dropResult = monitor.getDropResult();
  //     if (item && dropResult) {
  //       console.log(item);
  //       console.log(dropResult);
  //     }
  //   },
  //   collect: (monitor) => {
  //     return {
  //       isDragging: monitor.isDragging(),
  //       handlerId: monitor.getHandlerId(),
  //     };
  //   },
  // }));

  // const [show, setShow] = React.useState(false);
  // const menuRef = React.useRef(null);
  const cardRef = React.useRef(null);

  // const onMouseLeave = (e) => {
  //   if (e.target !== cardRef.current || e.target !== menuRef.current) {
  //     setShow(false);
  //     return;
  //   }
  // };

  // const overlay = (
  //   <div
  //     className="side__menu__wr"
  //     ref={menuRef}
  //     onMouseEnter={() => setShow(true)}
  //     onMouseLeave={onMouseLeave}
  //   >
  //     <SideMenu className="side__menu" data={subTagsCategorizedTagFileds} />
  //   </div>
  // );

  const onEditHandler = React.useCallback(() => {
    if (!tagdata?.tagId) return;

    afterEditActionFired && afterEditActionFired();

    let previewPayload = {
      variant,
      visual,
      visualType,
      visualTypeImages,
      title,
      subTitle,
      showContent: visual === undefined ? false : true,
      tagData: tagdata,
      formData: formdata,
      subTagsCategorizedTagFileds,
    };

    onShowTagModal({
      mode: TAG_MODAL_MODE.Edit,
      tagTypeId: tagdata?.tagTypeId || null,
      tagId: tagdata?.tagId || null,
      tagData: tagdata,
      previewPayload: previewPayload,
    });
  }, [onShowTagModal, tagdata, afterEditActionFired]);

  function getValue(value) {
    if (value === Object(value)) {
      return value.label;
    } else {
      return value;
    }
  }

  let title = getValue(
    formdata?.filter(
      (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.TITLE
    )[0]?.value
  );
  let subTitle = getValue(getSubtitle(tagdata, formdata));

  let discription = getValue(
    formdata?.filter(
      (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.DISCRIPTION
    )[0]?.value
  );

  let visual = formdata?.filter(
    (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.VISUAL
  )[0]?.value;

  let visualType = getVisualType(tagdata, formdata);

  let visualTypeImages =
    formdata
      ?.filter((f) => f?.visualTypeId === 1)
      ?.map((d) => d?.value)
      ?.filter((d) => d?.length > 0)
      ?.map((s) => s?.map((f) => f?.filePath)) || [];

  // console.log("visualTypeImages", visualTypeImages);
  // console.log("visual-Type: ", visualType);

  let who = "";
  let name = "";
  if (tagdata?.sysCreatedBy !== "" && tagdata?.sysUpdatedBy === "") {
    who = RESEARCH_TAG_WHO.AUTHOR;
    name = tagdata?.sysCreatedUserName;
  } else if (
    tagdata?.sysCreatedBy.lenght === "" &&
    tagdata?.sysUpdatedBy.lenght !== ""
  ) {
    who = RESEARCH_TAG_WHO.EDITOR;
    name = tagdata?.sysUpdatedUserName;
  } else if (
    tagdata?.sysCreatedBy.lenght !== "" &&
    tagdata?.sysUpdatedBy.lenght !== ""
  ) {
    who = RESEARCH_TAG_WHO.EDITOR;
    name = tagdata?.sysUpdatedUserName;
  } else {
    who = "";
    name = "";
  }

  const handleOnPreviewClick = () => {
    let payload = {
      variant,
      visual,
      visualType,
      visualTypeImages,
      title,
      subTitle,
      showContent: visual === undefined ? false : true,
      tagData: tagdata,
      formData: formdata,
      subTagsCategorizedTagFileds,
    };

    onShowPreviewModal(payload);
  };

  const renderMainComponent = React.useMemo(() => {
    return (
      <StyledCardWrapper
        ref={(ref) => {
          cardRef.current = ref;
          // drag(ref);
          return ref;
        }}
        // onMouseEnter={() => setShow(true)}
        // onMouseLeave={onMouseLeave}
        className="tag__card__wrapper"
        // {...collected}
        onClick={() => {
          handleOnPreviewClick();
        }}
      >
        <div className="tag__card__inner">
          {/* Tag type icon and name */}
          {showTagTypeInfo && variant && (
            <TagTypeHeader name={variant} icon={iconComponent} />
          )}

          {/* Episode */}
          {showEpisodes ? (
            <Episode onChange={(currentIndex) => {}} numberOfEpisodes={10} />
          ) : null}

          {/* Card Header */}
          <Header
            hideRightActions={hideRightActions}
            title={title}
            subTitle={subTitle}
            discription={discription}
            // handleTitleClick={}
            onEditHandler={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onEditHandler();
            }}
          />

          {/* Card Content */}
          <Contents
            variant={variant}
            visual={visual}
            who={who}
            name={name}
            visualType={visualType}
            title={title}
            subTitle={subTitle}
            tagData={tagdata}
            formData={formdata}
            subTagsCategorizedTagFileds={subTagsCategorizedTagFileds}
            iconComponent={iconComponent}
          />
        </div>
      </StyledCardWrapper>
    );
  }, [
    formdata,
    tagdata,
    showTagTypeInfo,
    variant,
    iconComponent,
    showEpisodes,
    hideRightActions,
    subTagsCategorizedTagFileds,
    onEditHandler,
    onShowPreviewModal,
  ]);

  if (hideSideMenu) {
    return renderMainComponent;
  }

  // const c = document.querySelector(".column__content");

  return (
    <>
      {renderMainComponent}

      {/* {show && !isContentScrolling ? (
        <Popover
          anchorEl={cardRef.current}
          placement="right-start"
          hideOverlay={true}
          // appendTo=".column__content"
          modifiers={[
            {
              name: "preventOverflow",
              options: {
                boundary: c,
                altBoundary: true,
                tether: true,
              },
            },
          ]}
        >
          {overlay}
        </Popover>
      ) : null} */}

      {/* {DefaultComponent} */}
    </>
  );
};

function TagTypeHeader({ icon: ColumnIcon, name }) {
  return (
    <StyledTagType>
      {ColumnIcon && (
        <IconButton
          as="div"
          className="type__icon"
          btnIconSize={16}
          btnSize={16}
          icon={<ColumnIcon />}
        />
      )}

      {name && String(name).trim().length > 0 && (
        <div className="type__name">
          <span>{String(name).toLowerCase()}</span>
        </div>
      )}
    </StyledTagType>
  );
}

export default TagCard;
