import React from "react";
import { useQuery } from "react-query";
import { format, isEqual, isValid } from "date-fns";

import { CalendarType } from "../constant";
import getData from "../data/get-data.json";
import { getCalendar, saveCalendarEvent } from "../api";
import { useGlobalStore } from "../../../../store/GlobalStore";
import { useAppStore } from "../../../../store/AppStore/AppStore";

export const CalendarContext = React.createContext({
  isViewMode: false,
  isDialogVisible: false,
  dialogFormValues: null,
  selectTimeSlot: null,
  selectedEvent: null,
  currentSelectDate: new Date(),
  currentTimeSlots: [],
  calendarDates: [],
  type: CalendarType.Tag,
  meta: null,
  isDisabled: false,
  onDaySelect: (date, modifiers) => {},
  closeDialogHandler: (val) => {},
  selectTimeHandler: (val) => {},
  onEventSubmit: (val) => {},
  onMonthChange: (date) => {},
  onEventSelect: (data) => {},
  onSetDialogFormValues: (data) => {},
});

export const CalendarContextProvider = ({
  children,
  viewMode,
  calendarType,
  meta,
  disabled = false,
  onChange,
}) => {
  const [currentSelectDate, setCurrentSelectDate] = React.useState(new Date());

  const currentSelectMonth = React.useMemo(() => {
    return format(currentSelectDate, "yyyy-MM");
  }, [currentSelectDate]);

  /*
    If ViewModel is true,the calendar does not allow actionable things.
  */
  const [isViewMode, setViewMode] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [selectTimeSlot, setSelectTimeSlot] = React.useState(null);
  const [isDialogVisible, setDialogVisible] = React.useState(false);
  const [calendarDates, setCalendarDates] = React.useState([]);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [dialogFormValues, setDialogFormValues] = React.useState(null);
  const [currentTimeSlots, setCurrentTimeSlots] = React.useState([]);
  const projectId = useGlobalStore((state) => state.selectedProjectId) || 0;
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  /*
  Calendar Types
    Tag: 1,
    User: 2,
  */
  const [type, setType] = React.useState(null);

  React.useEffect(() => {
    setType(calendarType);
    setViewMode(viewMode);
  }, [calendarType, viewMode]);

  React.useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  React.useEffect(() => {
    if (!calendarDates.length) {
      return;
    }

    const slots = calendarDates?.find((d) => {
      return isEqual(
        new Date(format(currentSelectDate, "yyyy-MM-dd")),
        new Date(format(new Date(d?.startDate), "yyyy-MM-dd"))
      );
    });

    setCurrentTimeSlots(slots || []);
  }, [calendarDates, currentSelectDate]);

  const selectTimeHandler = React.useCallback((payload) => {
    setSelectTimeSlot(payload);
    setDialogVisible(true);
  }, []);

  const onDaySelect = React.useCallback((date, modifiers) => {
    // Set current select date
    // console.log("modifiers", modifiers);
    setCurrentTimeSlots([]);
    setCurrentSelectDate(date);
  }, []);

  const closeDialogHandler = React.useCallback((isShow) => {
    setDialogVisible(false);
    setSelectTimeSlot(null);
    setDialogFormValues(null);
  }, []);

  // API Call
  const isEnabled = !!type && !!currentSelectMonth && !isDisabled;

  useQuery({
    queryKey: ["calendar", type, currentSelectMonth],
    queryFn: () => {
      const date = `${currentSelectMonth}-01`;
      return getCalendar(type, date, meta);
    },
    onSuccess: (data) => {
      setCalendarDates(data?.calendarDates || []);
    },
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
  });

  // API call
  const onEventSubmit = React.useCallback(
    (value) => {
      showLoader();

      saveCalendarEvent(
        projectId,
        calendarType,
        dialogFormValues?.calendarId || 0,
        dialogFormValues?.groupId || "",
        meta,
        value?.value || null
      )
        .then((res) => {
          // console.log("saveCalendarEvent ::", res);
          setCalendarDates(res?.calendarDates || []);
          closeDialogHandler();
          hideLoader();
          onChange && onChange(res);
        })
        .catch((error) => {
          hideLoader();
          throw error;
        });
    },
    [
      showLoader,
      projectId,
      calendarType,
      dialogFormValues?.calendarId,
      dialogFormValues?.groupId,
      meta,
      closeDialogHandler,
      hideLoader,
      onChange,
    ]
  );

  const onMonthChange = React.useCallback((date) => {
    if (!isValid(date)) {
      return;
    }
    setCurrentTimeSlots([]);
    setCurrentSelectDate(date);
  }, []);

  const onEventSelect = React.useCallback((data) => {
    setSelectedEvent(data);
  }, []);

  const onSetDialogFormValues = React.useCallback((data) => {
    setDialogFormValues(data);
    setDialogVisible(true);
  }, []);

  const values = {
    isViewMode,
    currentSelectDate,
    isDialogVisible,
    selectTimeSlot,
    calendarDates,
    type,
    meta,
    selectedEvent,
    dialogFormValues,
    currentTimeSlots,
    isDisabled,
    onSetDialogFormValues,
    onEventSelect,
    onDaySelect,
    closeDialogHandler,
    selectTimeHandler,
    onEventSubmit,
    onMonthChange,
  };

  return (
    <CalendarContext.Provider value={values}>
      {children}
    </CalendarContext.Provider>
  );
};
