import * as React from "react";
import { memo } from "react";
const SvgDone = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Fill/Done">
      <path
        id="icon"
        d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 6.94942 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0ZM12.5408 6.5896L7.7408 10.9896C7.58883 11.1289 7.38892 11.2042 7.18278 11.1998C6.97664 11.1953 6.78019 11.1114 6.6344 10.9656L4.2344 8.5656C4.15799 8.4918 4.09705 8.40352 4.05512 8.30592C4.01319 8.20832 3.99113 8.10334 3.9902 7.99712C3.98928 7.89089 4.00952 7.78555 4.04974 7.68723C4.08997 7.58892 4.14937 7.4996 4.22449 7.42448C4.2996 7.34937 4.38892 7.28997 4.48724 7.24974C4.58556 7.20952 4.6909 7.18927 4.79712 7.1902C4.90335 7.19112 5.00832 7.21319 5.10593 7.25512C5.20353 7.29704 5.2918 7.35799 5.3656 7.4344L7.224 9.2928L11.4592 5.4104C11.6156 5.26697 11.8225 5.19153 12.0345 5.20069C12.2465 5.20984 12.4462 5.30283 12.5896 5.4592C12.733 5.61557 12.8085 5.82251 12.7993 6.03451C12.7902 6.2465 12.6972 6.44617 12.5408 6.5896Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
const Memo = memo(SvgDone);
export default Memo;
