import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import UIButton from "../../../../../common/components/Buttons/UIButton";
import FieldGroup from "../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../common/components/Form/InputField";
import * as LineIcon from "../../../../../common/components/icons/Line";
import * as FillIcon from "../../../../../common/components/icons/Fill";
import { ValidationMessage } from "../../../../../utility/validation-message";
import EndIcon from "../../../components/EndIcon";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(ValidationMessage.required)
    .email(ValidationMessage.email),
  password: Yup.string()
    .min(8, ValidationMessage.min(8))
    .required(ValidationMessage.required),
});

const LoginForm = ({ onSubmit }) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, values, errors, touched, handleChange } = formik;

  const handleEndIconClick = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form__content">
        <FieldGroup label="Email" error={touched.email && errors.email}>
          <InputField
            name="email"
            startIcon={<FillIcon.Email />}
            placeholder="Email"
            value={values.email}
            onChange={handleChange}
            data-qa="login_email"
          />
        </FieldGroup>

        <FieldGroup
          label="Password"
          error={touched.password && errors.password}
        >
          <InputField
            name="password"
            startIcon={<LineIcon.Access1 />}
            endIcon={<EndIcon handleOnClick={handleEndIconClick} />}
            placeholder="Password"
            type={isPasswordVisible ? "text" : "password"}
            value={values.password}
            onChange={handleChange}
            isEndIconActive={isPasswordVisible}
            data-qa="login_password"
          />
        </FieldGroup>
      </div>

      <div className="form__actions">
        <UIButton type="submit" fullWidth data-qa="login_submit_btn">
          Login
        </UIButton>
      </div>
    </form>
  );
};

export default LoginForm;
