import GLOBAL_API from "../../../services/api";
import { SETTINGS_ENDPOINTS } from "./settings.endpoints";
//Personal Data
export const getPersonalData = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_PERSONAL_DATA}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const savePersonalData = (userId, dataObj, values) => {
  let params = {
    userTenantId: dataObj?.userTenantId || 0,
    fullName: dataObj?.fullName || "",
    address: values?.address || "",
    idNumber: dataObj?.idNumber || "",
    givenName: values?.name || "",
    surName: values?.lastName || "",
    displayName: dataObj?.displayName || "",
    nickName: dataObj?.nickName || "",
    jobTitleId: values?.jobTitle?.value || 0,
    countryId: values?.country?.value || 0,
    titleId: values?.title?.value,
    city: values?.city || "",
    profileType: dataObj?.profileType || 0,
    employmentType: dataObj?.employmentType || 0,
    companyName: dataObj?.companyName || "",
    email: values?.email || "",
    phoneCode: values?.phone?.code || "",
    phoneNo: values?.phone?.number || "",
    dateOfBirth: dataObj?.dateOfBirth || "",
    gender: dataObj?.gender || 0,
    nationality: dataObj?.nationality || 0,
    agent: dataObj?.agent || "",
    profileImage: values?.profileImage || "",
    tenantId: dataObj?.tenantId || 0,
    userId: dataObj?.userId || "",
    addressOptional: values?.address2 || "",
    firstName: dataObj?.firstName || "",
    lastName: dataObj?.lastName || "",
    postCode: values?.postCode || "",
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(
      `${SETTINGS_ENDPOINTS.UPDATE_PERSONAL_DATA}/${userId}`,
      params
    )
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//My Profile
export const getMyProfile = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_MY_PROFILE}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveMyProfile = (userId, dataObj, values) => {
  const urlList = values?.urlList?.map((item) => {
    return {
      label: item.name,
      ...item,
    };
  });
  let params = {
    userProfileId: dataObj?.userProfileId || 0,
    userId: userId || "",
    bio: values?.bio || "",
    resume: values?.resume || "",
    socialLinks: urlList || [],
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(`${SETTINGS_ENDPOINTS.SAVE_MY_PROFILE}`, params)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Identification
export const getIdentificationTypes = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_IDENTIFICATIONS_TYPES}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const mappedData = response?.data?.result.map((item) => {
        return {
          label: item?.docType,
          value: item?.identificationTypeId,
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const getIdentificationsByUser = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_IDENTIFICATIONS_BY_USERID}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveIdentification = (userId, dataObj, values) => {
  let params = {
    userIdentificationId: dataObj?.userIdentificationId || 0,
    userId: userId || "",
    nationality: values?.nationality || "",
    identificationTypeId: values?.identificationType?.value || 0,
    documentNumber: values?.docNumber || "",
    issuedCountryId: values?.issuedCountry?.value || 0,
    issuedDate: values?.issuedDate || "",
    expiryDate: values?.expiresDate || "",
    document: values?.doc || "",
    passportPhoto: values?.passportPhoto || "",
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(`${SETTINGS_ENDPOINTS.SAVE_IDENTIFICATION}`, params)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const deleteIdentification = (userIdentificationId) => {
  return new Promise((resolve, reject) => {
    GLOBAL_API.delete(
      `${SETTINGS_ENDPOINTS.DELETE_IDENTIFICATION}/${userIdentificationId}`
    )
      .then((res) => {
        console.log("DELETE_IDENTIFICATION :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
//Business Registration
export const getBusinessRegistrationData = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_BUSINESS_REGISTRATION_BY_USER}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveBusinessRegistration = (userId, dataObj, values) => {
  let params = {
    businessRegistrationId: dataObj?.businessRegistrationId || 0,
    userId: userId,
    name: values?.name || "",
    brNumber: values?.brNumber || 0,
    email: values?.email || "",
    companyLogo: values?.companyLogo || "",
    countryId: values?.country?.value || 0,
    phoneCode: values?.phone?.code || "",
    phoneNumber: values?.phone?.number || "",
    address: values?.address || "",
    addressOptional: values?.address2 || "",
    city: values?.city || "",
    postCode: values?.postCode || "",
    companyProfile: values?.companyProfile || "",
    socialLinks: values?.urlList?.map((link) => {
      return {
        resourceId: typeof link?.id === "string" ? 0 : link?.id,
        resourceType: 1,
        url: link?.url,
        label: link?.name,
        extension: "",
        md5sum: "",
        isSorted: true,
      };
    }),
    attachments: values?.attachments?.map((file) => {
      return {
        resourceId: typeof file?.localId === "string" ? 0 : file?.localId,
        resourceType: 2,
        url: file?.filePath,
        label: file?.fileName,
        extension: file?.extension,
        md5sum: "",
        sectionValue: file?.sectionValue,
        isSorted: true,
      };
    }),
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(
      `${SETTINGS_ENDPOINTS.SAVE_BUSINESS_REGISTRATION_BY_USER}`,
      params
    )
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const deleteBusinessRegistration = (businessRegistrationId) => {
  return new Promise((resolve, reject) => {
    GLOBAL_API.delete(
      `${SETTINGS_ENDPOINTS.DELETE_BUSINESS_REGISTRATION}/${businessRegistrationId}`
    )
      .then((res) => {
        console.log("DELETE_BUSINESS_REGISTRATION :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
//Visa Informations
export const getVisaInformationByUser = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_VISA_INFORMATION_BY_USER}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const getVisaTypes = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_VISA_TYPES}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const mappedData = response?.data?.result.map((item) => {
        return {
          label: item?.name,
          value: item?.visaTypeId,
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const getIdentificationsByUserAndType = async (userId, Type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_IDENTIFICATIONS_BY_USER_AND_TYPE}/${userId}/${Type}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const mappedData = response?.data?.result.map((item) => {
        return {
          label: item?.documentNumber,
          value: item?.userIdentificationId,
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveVisaInformation = (userId, dataObj, values) => {
  let params = {
    userVisaInfoId: dataObj?.userVisaInfoId || 0,
    userId: userId,
    identificationTypeId: values?.identificationType?.value || 0,
    userIdentificationId: values?.identification?.value || 0,
    visaTypeId: values?.visaType?.value || 0,
    issuedCountryId: values?.issuedCountry?.value || 0,
    issuedDate: values?.issuedDate || "",
    expiryDate: values?.expiresDate || "",
    notes: values?.note || "",
    attachments: [
      {
        resourceId:
          dataObj !== null && dataObj?.attachments?.length !== 0
            ? dataObj.attachments[0]?.resourceId
            : 0,
        resourceType: 2,
        url: values?.attachment,
        label: "Attached Document",
        extension: "",
        md5sum: "",
        sectionValue: 1,
        isSorted: true,
      },
    ],
  };
  console.log("params", params);

  return new Promise((resolve, reject) => {
    GLOBAL_API.post(`${SETTINGS_ENDPOINTS.SAVE_VISA_INFORMATION}`, params)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const deleteVisaInformations = (userVisaInfoId) => {
  return new Promise((resolve, reject) => {
    GLOBAL_API.delete(
      `${SETTINGS_ENDPOINTS.DELETE_VISA_INFORMATION}/${userVisaInfoId}`
    )
      .then((res) => {
        console.log("DELETE_VISA_INFORMATION :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
//Subscription
export const getUserSubscription = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_USER_SUBSCRIPTION_BY_USER}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveUserSubscription = (userId, dataObj, values) => {
  let params = {
    userSubscriptionId: dataObj !== null ? dataObj?.userSubscriptionId : 0 || 0,
    userId: userId || "",
    name: values?.name || "",
    cardNumber: values?.cardNumber || "",
    cardExpiryDate: values?.exDate || "",
    cardCvv: values?.cvv || "",
    countryId: values?.country?.value || 0,
    address: values?.address || "",
    addressOptional: values?.address2 || "",
    city: values?.city || "",
    postCode: values?.postCode || "",
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(`${SETTINGS_ENDPOINTS.SAVE_USER_SUBSCRIPTION}`, params)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Invoicing
export const getUserInvoicingData = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_INVOICING_DATA_BY_USER}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveUserInvoicingData = (userId, dataObj, values) => {
  let params = {
    userInvoicingId: dataObj !== null ? dataObj?.userInvoicingId : 0 || 0,
    userId: userId || "",
    accHolderName: values?.name || "",
    accNumber: values?.accountNumber || "",
    swiftCode: values?.swiftCode || "",
    iban: values?.iban || "",
    routingNumber: values?.routingNumber || "",
    bankName: values?.bankName || "",
    branchCode: values?.branchCode || "",
    bankAddress: values?.bankAddress || "",
    notes: values?.note || "",
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(`${SETTINGS_ENDPOINTS.SAVE_USER_INVOICING}`, params)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//FreqFlyer
export const getFreqFlyerData = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_FREQ_FLYER_DATA_BY_USER}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveFreqFlyerData = (userId, dataObj, values) => {
  let params = {
    freqFlyerId: dataObj !== null ? dataObj?.freqFlyerId : 0 || 0,
    userId: userId || "",
    nearestAirport: values?.nearestAirport || "",
    freqFlyerMembershipId: values?.freqFlyerMembership?.value || 0,
    freqFlyerMembershipNumber: values?.freqFlyerMembershipNumber,
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(`${SETTINGS_ENDPOINTS.SAVE_FREQ_FLYER_DATA}`, params)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const getFreqFlyerMemberShips = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_FREQ_FLYER_MEMBERSHIPS}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const mappedData = response?.data?.result.map((item) => {
        return {
          label: item?.freqFlyerMembershipAlliance,
          value: item?.freqFlyerMembershipId,
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const deleteFreqFlyer = (freqFlyerId) => {
  return new Promise((resolve, reject) => {
    GLOBAL_API.delete(`${SETTINGS_ENDPOINTS.DELETE_FREQ_FLYER}/${freqFlyerId}`)
      .then((res) => {
        console.log("DELETE_FREQ_FLYER :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
//System Preferences
export const getUserSystemPreferences = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_USER_SYSTEM_PREF_BY_USER}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveUserSystemPreferences = (userId, dataObj, values) => {
  let params = {
    systemPreferenceId: dataObj !== null ? dataObj?.systemPreferenceId : 0 || 0,
    userId: userId || "",
    dateFormatId: values?.dateFormat?.value || 0,
    timeFormatId: values?.timeFormat?.value || 0,
    languageId: values?.language?.value || 0,
    theme: values?.theme || 0,
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(`${SETTINGS_ENDPOINTS.SAVE_USER_SYSTEM_PREF}`, params)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const getAllDateFormates = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_ALL_DATE_FORMATES}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const mappedData = response?.data?.result.map((item) => {
        return {
          label: item?.formatString,
          value: item?.dateFormatId,
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const getAllTimeFormates = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_ALL_TIME_FORMATES}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const mappedData = response?.data?.result.map((item) => {
        return {
          label: item?.formatString,
          value: item?.timeFormatId,
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const getAllLanguages = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_ALL_LANGUAGES}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const mappedData = response?.data?.result.map((item) => {
        return {
          label: item?.languageName,
          value: item?.languageId,
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
//Health Declarations
export const getUserHealthDeclarations = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_USER_HEALTH_DECLARATION_BY_USER}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveUserHealthDeclarations = (userId, dataObj, values) => {
  let params = {
    healthDeclarationId:
      dataObj !== null ? dataObj?.healthDeclarationId : 0 || 0,
    userId: userId || "",
    bloodType: values?.bloodType?.value || 0,
    allergies: values?.allergies || "",
    other: values?.otherConcerns || "",
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(
      `${SETTINGS_ENDPOINTS.SAVE_USER_HEALTH_DECLARATION}`,
      params
    )
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
//Health Declarations - Emergency Contats
export const getUserHealthEmergencyContactByUser = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_USER_EMERGENCY_CONATCT_PERSON_BY_USER}/${userId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveUserHealthEmergencyContac = (userId, dataObj, values) => {
  let params = {
    emergencyContactId: dataObj !== null ? dataObj?.emergencyContactId : 0 || 0,
    userId: userId || "",
    name: values?.name || "",
    relationshipId: values?.relation?.value || 0,
    phoneCode: values?.phone?.code || "",
    phoneNumber: values?.phone?.number || "",
  };
  return new Promise((resolve, reject) => {
    GLOBAL_API.post(`${SETTINGS_ENDPOINTS.SAVE_USER_EMERGENCY_CONATCT}`, params)
      .then((res) => {
        const data = res?.data || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const getAllRelationShips = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GLOBAL_API.get(
        `${SETTINGS_ENDPOINTS.GET_ALL_RELATIONSHIPS}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const mappedData = response?.data?.result.map((item) => {
        return {
          label: item?.name,
          value: item?.relationshipId,
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const deleteHealthEmergencyContact = (emergencyContactId) => {
  return new Promise((resolve, reject) => {
    GLOBAL_API.delete(
      `${SETTINGS_ENDPOINTS.DELETE_USER_EMERGENCY_CONATCT}/${emergencyContactId}`
    )
      .then((res) => {
        console.log("DELETE_USER_EMERGENCY_CONATCT :::", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};
