import styled from "styled-components";
import BaseFlexModal from "../../../../common/components/UI/Modal/BaseFlexModal";
import EventCalendar from "../../EventCalendar";

export const Modal = styled(BaseFlexModal)``;

export const MBody = styled.div``;

export const Calendar = styled(EventCalendar)`
  max-width: 100%;
`;
