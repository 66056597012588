import styled from "styled-components";
import { fontStyle } from "../../styled/utility";

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex: 1;

  .notifications {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--layout-spacing-20);
      padding-bottom: var(--layout-spacing-16);
      gap: var(--layout-spacing-4);

      .title {
        ${fontStyle("h6-semi-bold")};
        font-weight: 500;
        color: var(--font-color-shade-accent);
      }
    }

    &__content {
      flex: 1;
      width: 100%;

      overflow-y: auto;
      padding: var(--layout-spacing-20);
      padding-top: 0;

      .items {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: var(--layout-spacing-16);
      }
    }
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-tint-04);
  ${fontStyle("label-bold")};
  font-weight: 700;
  pointer-events: none;
`;

export const NotificationItemBlock = styled.div`
  background-color: var(--color-neutral-20);
  border-radius: var(--layout-border-radius-12);

  .n-button {
    outline: none;
    border-radius: var(--button-border-radius-sm);
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: var(--animate-1);
    width: auto;
    gap: var(--btn-spacing-8);
    border-width: var(--button-border-success-active-width, 1px);
    border-style: var(--button-border-success-active-style);
    font-size: var(--small-medium-font-size);
    font-family: var(--small-medium-font-family);
    font-weight: var(--small-medium-font-weight);
    line-height: var(--small-medium-line-height);
    padding: 0 var(--btn-spacing-12);
    height: var(--layout-size-32);
    color: var(--button-text-white);

    .button__ic {
      --icon-size: var(--layout-size-12);
      --icon-font-size: var(--layout-size-12);
      --icon-font-color: inherit;
      position: relative;
      display: flex;
      align-items: center;
      color: var(--icon-font-color);
      font-size: var(--icon-font-size);
      min-height: var(--icon-size);
      min-width: var(--icon-size);
      height: var(--icon-size);
      width: var(--icon-size);
    }

    &--secondary {
      border-color: var(--button-border-success-active-color);
      background-color: var(--button-success-active);

      &:hover {
        background-color: var(--button-success-hover);
        border-width: var(--button-border-success-hover-width, 1px);
        border-color: var(--button-border-success-hover-color);
        border-style: var(--button-border-success-hover-style);
      }
    }
  }

  .notification__item {
    padding: var(--layout-spacing-16);
    border-color: red;

    .text--500 {
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      color: var(--color-primary-400);
    }

    .text--400 {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      color: var(--color-black);
    }

    .text-color-light {
      color: var(--color-neutral-90);
    }

    .text-highlighted {
      color: var(--color-secondary-300);
    }
  }

  /* Onboarding item  */
  .n-onb {
    display: flex;
    max-width: 100%;
    min-width: 100%;
    gap: var(--layout-spacing-20);

    &__start {
      max-width: 100px;
      min-width: 100px;
    }

    &__end {
      flex: 1;
      display: grid;
      gap: var(--layout-spacing-8);
      grid-template-rows: 0fr;
    }

    &__trademark {
      width: 100%;
      overflow: hidden;
      border-radius: var(--layout-border-radius-8);

      img {
        max-width: 100%;
        width: 100%;
      }
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: var(--layout-spacing-8);
      padding-right: var(--layout-spacing-8);

      .v-line {
        padding: 8px 0px;
        height: 100%;
        width: 0.5px;
        background-color: var(--color-black);
      }
    }

    &__status {
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 18.2px */
      color: var(--font-tint-04);
      padding: var(--layout-spacing-2) var(--layout-spacing-8);
      border-radius: var(--layout-border-radius-4);
      border: 1px solid var(--color-neutral-50);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__body {
      display: flex;
      gap: var(--layout-spacing-8);
      justify-content: space-between;

      .left {
        flex: 1;
        display: grid;
        gap: var(--layout-spacing-4);
      }

      .bottom-content {
        display: flex;
        gap: var(--layout-spacing-4);
        align-items: center;
      }

      .inviter {
        margin-left: var(--layout-spacing-4);
      }
    }

    &__actions {
      display: flex;
      gap: var(--layout-spacing-8);
      align-items: flex-end;
    }
  }

  /* Plan item */
  .n-default {
    position: relative;

    &__inner {
      padding-right: var(--layout-spacing-28);
    }

    &__remove {
      position: absolute;
      right: 0;
      top: 0;

      button {
        --icon-button-size: var(--layout-size-24);
        --icon-button-icon-size: var(--layout-size-12);
        --icon-button-icon-color: var(--font-tint-04);
        width: var(--icon-button-size);
        height: var(--icon-button-size);
        min-width: var(--icon-button-size);
        min-height: var(--icon-button-size);
        transition: var(--animate-1);
        color: var(--icon-button-icon-color);
        padding: 0px;
        margin: 0px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        outline: none;
      }

      .button__icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--icon-font-color);
        font-size: var(--icon-font-size);
        min-height: var(--icon-size);
        min-width: var(--icon-size);
        height: var(--icon-size);
        width: var(--icon-size);

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &__header {
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 18.2px */
      color: var(--font-default-color);
    }

    &__content {
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 18.2px */
    }
  }
`;
