import React, { useState } from "react";
import "../../assets/css/onboarding.b2df17d8cd6cdf17a1d8fde4e23c2c97.css";
import API_Template from "../../../services/api-template";
import API_ENDPOINTS from "../../../services/api-endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Loader from "react-loader-spinner";
import "../../assets/css/template_builder.977bc20a47c0d58c09caf98ed89409cd.css";
import Container from "../../ui-components/Container";
import { Link, useRouteLoaderData } from "react-router-dom";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { TEMPLATE_PERMISSION_MODULES } from "../../../constant/template_permission_module";
import { Permission } from "../../../helpers/permission";
import "./styles.scss";

const TemplateSearch = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.templates.children["all-templates"].id
  );

  const moduleType = TEMPLATE_PERMISSION_MODULES.AllTemplates.actions;

  const permissions = new Permission(permission);

  const createTemplatePermission = permissions.findByName(
    moduleType.CreateTemplate.key
  );

  var inputtext = "";
  let TemplateList = [];
  let TemplateTypes = [];
  const [show, setShow] = useState(false); //Loading Indicator
  const [allTemplates, setAllTemplates] = useState([]);
  const [allCategoriesTemplates, setAllCategoriseTemplates] = useState([]);
  const [allTemplateTypes, setAllTemplateTypes] = useState([]);
  const [templateTypesDD, setTemplateTypesDD] = useState([]);
  const [recentTemplateDivView, setShowRecentTemplateDivView] = useState(false);
  const [frequentTemplateDivView, setShowFrequentTemplateDivView] =
    useState(false);
  const [templateTypeId, setTemplateTypeId] = useState(0);
  const [templateTitle, setTemplateTitle] = useState("All Templates");
  let RecentTemplateList = [];
  const [allRecentTemplates, setAllRecentTemplates] = useState([]);
  let FrequentTemplateList = [];
  const [allFrequentTemplates, setAllFrequentTemplates] = useState([]);
  const [FrequentTemplatesData, setFrequentTemplatesData] = useState([]);
  const [allTemplatesSortingOrder, setAllTemplatesSortingOrder] =
    useState("ASC");
  const [
    allFrequentTemplatesSortingOrder,
    setAllFrequentTemplatesSortingOrder,
  ] = useState("ASC");

  let USER = JSON.parse(localStorage.getItem("user"));

  React.useEffect(() => {
    getAllTemplateTypes();
  }, []);

  React.useEffect(() => {
    if (allTemplateTypes.length > 0) {
      searchAllTemplates();
      getRecentTemplates(templateTypeId);
      getFrequentTemplates(templateTypeId);
    }
  }, [allTemplateTypes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const TemplateItem = ({ data, name }) => {
    const createPath =
      ROUTE_OPTIONS.template_v2.children["edit-template-v2"].path;
    // ROUTE_OPTIONS.templates.children["create-templates"].path;

    const { templateId, templateName, templateTypeId } = data;

    return (
      <div className="tp__builder__recent__item">
        <Link
          className="tp__builder__recent__item__iW"
          to={`${createPath}`}
          state={{ isEditMode: true, templateId, templateName, templateTypeId }}
        >
          <div className="__recent__item__lock icon icon-lp-line-lock" />
          <div className="__recent__item__icon icon icon-lp-document-outline-badged" />
          <div className="__recent__item__small-text">
            {name} for
            <br />
            <span>{data.templateName}</span>
          </div>
          {/* <div className="__recent__item__client__logo netflix">N</div> */}
        </Link>

        <div className="builder__recent__item__hover">
          <button
            className="btn-base btn-space-0 btn-bg-none item_action_icon icon icon-lp-bx-duplicate"
            title="Duplicate Template"
          />

          <button
            className="btn-base btn-space-0 btn-bg-none item_action_icon icon icon-lp-history-line"
            title="View History"
          />

          <button
            className="btn-base btn-space-0 btn-bg-none item_action_icon icon icon-lp-share"
            title="Share Template"
          />
        </div>
      </div>
    );
  };

  const TemplateItemSmall = ({ data, name }) => {
    const createPath =
      ROUTE_OPTIONS.template_v2.children["edit-template-v2"].path;
    // ROUTE_OPTIONS.templates.children["create-templates"].path;

    const { templateId, templateName, templateTypeId } = data;

    return (
      <div className="tp__builder__fq__used__item">
        <Link
          className="tp__builder__fq__used__item__iW"
          to={`${createPath}`}
          state={{ isEditMode: true, templateId, templateName, templateTypeId }}
        >
          <div className="__fq__used__item__icon icon icon-lp-document-line" />
          <div className="__fq__used__item__small-text">
            {name} for
            <br />
            <span>{data.templateName}</span>
          </div>
        </Link>
      </div>
    );
  };

  const searchInputKeyDown = async (e) => {
    inputtext = e.target.value;
    if (e.key.length === 1) {
      inputtext = inputtext + e.key;
    } else {
      if (e.key === "Backspace") {
        inputtext = inputtext.slice(0, -1);
      }
    }

    console.log(inputtext);
    searchAllTemplatesByName(inputtext);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const searchAllTemplates = async () => {
    try {
      setShow(true);
      setShowRecentTemplateDivView(false);
      setShowFrequentTemplateDivView(false);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Template.get(
        endpoints.TEMPLATE_GETALL + "/1/200"
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          TemplateList = response.data.result;
          console.log("All Templates", TemplateList);
          setTemplateTitle("All Templates (" + TemplateList.length + ")");
          ////////////////////////////////////////////
          let categorizedTemplates = [];
          TemplateList.forEach((template) => {
            const __templateTypeId = Number(template.templateTypeId);
            // console.log("Template Type ID", __templateTypeId);
            let templates = TemplateList.filter(
              (m) => m.templateTypeId === template.templateTypeId
            );
            let typeName = allTemplateTypes.find(
              (t) => t.tenantTemplateTypeId === __templateTypeId
            )?.templateTypeName;
            //console.log("Template Type Name", typeName);
            categorizedTemplates.push({
              templateTypeId: template.templateTypeId,
              templateTypeName: typeName,
              templates: templates,
            });
          });
          categorizedTemplates = categorizedTemplates.filter(
            (ele, ind) =>
              ind ===
              categorizedTemplates.findIndex(
                (elem) => elem.templateTypeId === ele.templateTypeId
              )
          );
          console.log("cat-Templates", categorizedTemplates);
          //////////////////////////////////////////////////////////////////////
          const template_list = categorizedTemplates.map((item) => {
            return (
              <div className="tp__builder__all__section__wrapper">
                <div className="tp__builder__all__tp__section__title__w">
                  <div className="tp__builder__all__tp__section__title__iW">
                    {item.templateTypeName ? (
                      <div className="all__tp__section__title">
                        {item.templateTypeName}
                      </div>
                    ) : null}

                    <div className="all__tp__section__md__line" />
                    <a
                      className="all__tp__section__btn"
                      href="#"
                      onClick={() => onTemplateTypeChange(item.templateTypeId)}
                    >
                      View All {item.templateTypeName}
                    </a>
                  </div>
                </div>
                <div className="tp__builder__all__tp__template__container">
                  <div className="tp__builder__all__tp__template__iW">
                    <div className="tp__builder__all__tp__item__container">
                      <div className="tp__builder__all__tp__item__container__iW">
                        {item.templates.map((template) => {
                          return (
                            <div className="tp__builder__all__tp__item">
                              <a
                                className="tp__builder__all__tp__item__iW"
                                href="#"
                              >
                                <div className="__all__tp__item__icon icon icon-lp-document-line" />
                                <div className="__all__tp__item__small-text">
                                  {item.templateTypeName} for
                                  <br />
                                  <span>{template.templateName}</span>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          setShow(false);
          setAllTemplates(template_list);
          setAllCategoriseTemplates(categorizedTemplates);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const searchAllTemplatesByName = async (searchText) => {
    try {
      if (searchText.length === 0) {
        searchAllTemplates();
        setTemplateTypeId(0);
        return;
      }
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Template.get(
        endpoints.TEMPLATE_GETBYNAME +
          "/" +
          templateTypeId +
          "/" +
          searchText +
          "/1/10"
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          TemplateList = response.data.result;
          console.log("All Templates", TemplateList);

          ////////////////////////////////////////////
          let categorizedTemplates = [];
          TemplateList.forEach((template) => {
            const __templateTypeId = Number(template.templateTypeId);
            let templates = TemplateList.filter(
              (m) => m.templateTypeId === template.templateTypeId
            );

            let typeName = allTemplateTypes.find(
              (t) => t.tenantTemplateTypeId === __templateTypeId
            )?.templateTypeName;

            if (templateTypeId === 0) {
              setTemplateTitle("All Templates (" + TemplateList.length + ")");
            } else {
              setTemplateTitle(
                "All " + typeName + " (" + TemplateList.length + ")"
              );
            }

            categorizedTemplates.push({
              templateTypeId: template.templateTypeId,
              templateTypeName: typeName,
              templates: templates,
            });
          });
          categorizedTemplates = categorizedTemplates.filter(
            (ele, ind) =>
              ind ===
              categorizedTemplates.findIndex(
                (elem) => elem.templateTypeId === ele.templateTypeId
              )
          );
          console.log("cat-Templates", categorizedTemplates);
          //////////////////////////////////////////////////////////////////////
          const template_list = categorizedTemplates.map((item) => {
            if (templateTypeId === 0) {
              return (
                <div className="tp__builder__all__section__wrapper">
                  <div className="tp__builder__all__tp__section__title__w">
                    <div className="tp__builder__all__tp__section__title__iW">
                      {item.templateTypeName ? (
                        <div className="all__tp__section__title">
                          {item.templateTypeName}
                        </div>
                      ) : null}
                      <div className="all__tp__section__md__line" />
                      <a
                        className="all__tp__section__btn"
                        href="#"
                        onClick={() =>
                          onTemplateTypeChange(item.templateTypeId)
                        }
                      >
                        View All {item.templateTypeName}
                      </a>
                    </div>
                  </div>
                  <div className="tp__builder__all__tp__template__container">
                    <div className="tp__builder__all__tp__template__iW">
                      <div className="tp__builder__all__tp__item__container">
                        <div className="tp__builder__all__tp__item__container__iW">
                          {item.templates.map((template) => {
                            return (
                              <div className="tp__builder__all__tp__item">
                                <a
                                  className="tp__builder__all__tp__item__iW"
                                  href="#"
                                >
                                  <div className="__all__tp__item__icon icon icon-lp-document-line" />
                                  <div className="__all__tp__item__small-text">
                                    {item.templateTypeName} for
                                    <br />
                                    <span>{template.templateName}</span>
                                  </div>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="tp__builder__all__section__wrapper">
                  <div className="tp__builder__all__tp__section__title__w">
                    <div
                      className="tp__builder__all__tp__section__title__iW"
                      hidden={true}
                    >
                      {item.templateTypeName ? (
                        <div className="all__tp__section__title">
                          {item.templateTypeName}
                        </div>
                      ) : null}
                      <div className="all__tp__section__md__line" />
                      <a className="all__tp__section__btn" href="#">
                        View All {item.templateTypeName}
                      </a>
                    </div>
                  </div>
                  <div className="tp__builder__all__tp__template__container">
                    <div className="tp__builder__all__tp__template__iW">
                      <div className="tp__builder__all__tp__item__container">
                        <div className="tp__builder__all__tp__item__container__iW">
                          {item.templates.map((template) => {
                            return (
                              <div className="tp__builder__all__tp__item">
                                <a
                                  className="tp__builder__all__tp__item__iW"
                                  href="#"
                                >
                                  <div className="__all__tp__item__icon icon icon-lp-document-line" />
                                  <div className="__all__tp__item__small-text">
                                    {item.templateTypeName} for
                                    <br />
                                    <span>{template.templateName}</span>
                                  </div>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          });
          setShow(false);
          setAllTemplates(template_list);
          setAllCategoriseTemplates(categorizedTemplates);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getAllTemplateTypes = async () => {
    try {
      setShow(true);

      const endpoints = new API_ENDPOINTS();
      const response = API_Template.get(
        endpoints.TEMPLATE_GET_TEMPLATE_TYPE
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          TemplateTypes = response.data.result;
          setAllTemplateTypes(TemplateTypes);
          console.log("Template Types:", TemplateTypes);
          const templateType_list_dropdown = TemplateTypes.map((item) => {
            return (
              <option value={item.tenantTemplateTypeId}>
                {item.templateTypeName}
              </option>
            );
          });

          setTemplateTypesDD(templateType_list_dropdown);

          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const onTemplateTypeChange = async (templateTypeId) => {
    try {
      console.log("TTid", templateTypeId);

      if (templateTypeId === "0") {
        setTemplateTypeId(templateTypeId);
        searchAllTemplates();
        getRecentTemplates(templateTypeId);
        getFrequentTemplates(templateTypeId);
        return;
      }
      const __templateTypeId = Number(templateTypeId);
      setTemplateTypeId(templateTypeId);

      getRecentTemplates(templateTypeId);
      getFrequentTemplates(templateTypeId);

      let categorizedTemplates = [];
      //setShowRecentTemplateDivView(true);
      //setShowFrequentTemplateDivView(true);
      const endpoints = new API_ENDPOINTS();
      const response = API_Template.get(
        endpoints.TEMPLATE_GETBYTYPE + "/" + templateTypeId + "/1/10"
      ).then((response) => {
        console.log("on change type", response);
        if (response.data.isSuccess === true) {
          console.log("Template Types", allTemplateTypes);

          let typeName = allTemplateTypes.find(
            (t) => t.tenantTemplateTypeId === __templateTypeId
          ).templateTypeName;

          //let a = getTemplateTypeNameById(__templateTypeId);

          categorizedTemplates.push({
            templateTypeId: templateTypeId,
            templateTypeName: typeName,
            templates: response.data.result,
          });

          console.log("cat-Templates", categorizedTemplates);
          //////////////////////////////////////////////////////////////////////
          const template_list = categorizedTemplates.map((item) => {
            return (
              <div className="tp__builder__all__section__wrapper">
                <div className="tp__builder__all__tp__section__title__w">
                  <div
                    className="tp__builder__all__tp__section__title__iW"
                    hidden={true}
                  >
                    {item.templateTypeName ? (
                      <div className="all__tp__section__title">
                        {item.templateTypeName}
                      </div>
                    ) : null}

                    <div className="all__tp__section__md__line" />
                    <a className="all__tp__section__btn" href="#">
                      View All {item.templateTypeName}
                    </a>
                  </div>
                </div>
                <div className="tp__builder__all__tp__template__container">
                  <div className="tp__builder__all__tp__template__iW">
                    <div className="tp__builder__all__tp__item__container">
                      <div className="tp__builder__all__tp__item__container__iW">
                        {item.templates.map((template) => {
                          return (
                            <div className="tp__builder__all__tp__item">
                              <a
                                className="tp__builder__all__tp__item__iW"
                                href="#"
                              >
                                <div className="__all__tp__item__icon icon icon-lp-document-line" />
                                <div className="__all__tp__item__small-text">
                                  {item.templateTypeName} for
                                  <br />
                                  <span>{template.templateName}</span>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          setShow(false);
          setAllTemplates(template_list);
          setTemplateTitle(
            "All " + typeName + " (" + response.data.result.length + ")"
          );
          setAllCategoriseTemplates(categorizedTemplates);
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          return;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getRecentTemplates = async (templateTypeId) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API_Template.get(
        endpoints.TEMPLATE_GET_RECENTLY_USED_BY_TEMPLATE_TYPE +
          "/" +
          templateTypeId +
          "/1/20"
      ).then((response) => {
        console.log("Recent Templates", response);
        if (response.data.isSuccess === true) {
          RecentTemplateList = response.data.result;

          const recent_list = RecentTemplateList.map((item, i) => {
            let typeName = allTemplateTypes.find(
              (t) => t.tenantTemplateTypeId === item.templateTypeId
            )?.templateTypeName;

            return <TemplateItem key={i} name={typeName} data={item} />;
          });
          setAllRecentTemplates(recent_list);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getFrequentTemplates = async (templateTypeId) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      API_Template.get(
        endpoints.TEMPLATE_GET_FREQUENTLY_USED_BY_TEMPLATE_TYPE +
          "/" +
          templateTypeId +
          "/1/20"
      ).then((response) => {
        console.log("Frequent Templates", response);
        if (response.data.isSuccess === true) {
          FrequentTemplateList = response.data.result;
          setFrequentTemplatesData(FrequentTemplateList);

          const frequent_list = FrequentTemplateList.map((item, i) => {
            // Get Name
            let typeName = allTemplateTypes.find(
              (t) => t.tenantTemplateTypeId === item.templateTypeId
            )?.templateTypeName;

            //
            return <TemplateItemSmall key={i} name={typeName} data={item} />;
          });

          setAllFrequentTemplates(frequent_list);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  function getTemplateTypeNameById(templateTypeId) {
    try {
      const endpoints = new API_ENDPOINTS();
      const response = API_Template.get(
        endpoints.TEMPLATE_TYPE_GET_BY_ID + "/" + templateTypeId
      ).then((response) => {
        console.log("Type Data: ", response);
        if (response.data.isSuccess === true) {
          console.log("Type name: ", response.data.result[0].templateTypeName);
          return response.data.result[0].templateTypeName;
        } else {
          return "";
        }
      });
    } catch (err) {
      console.log(err);
      return "";
    }
  }

  const SortAllTemplates = async (e) => {
    try {
      const copyArray = [...allCategoriesTemplates]; // create a new array & not mutate state
      let hideViewAll = false;
      console.log("templateTypeId", templateTypeId);
      if (templateTypeId === "0") {
        hideViewAll = false;
      } else {
        hideViewAll = true;
      }
      if (allTemplatesSortingOrder === "ASC") {
        copyArray.forEach((templateArray) => {
          console.log(templateArray);
          templateArray.templates = [...templateArray.templates].sort((a, b) =>
            a.templateName > b.templateName ? -1 : 1
          );
        });

        const template_list = copyArray.map((item) => {
          return (
            <div className="tp__builder__all__section__wrapper">
              <div className="tp__builder__all__tp__section__title__w">
                <div
                  className="tp__builder__all__tp__section__title__iW"
                  hidden={hideViewAll}
                >
                  {item.templateTypeName ? (
                    <div className="all__tp__section__title">
                      {item.templateTypeName}
                    </div>
                  ) : null}
                  <div className="all__tp__section__md__line" />
                  <a
                    className="all__tp__section__btn"
                    href="#"
                    onClick={() => onTemplateTypeChange(item.templateTypeId)}
                  >
                    View All {item.templateTypeName}
                  </a>
                </div>
              </div>
              <div className="tp__builder__all__tp__template__container">
                <div className="tp__builder__all__tp__template__iW">
                  <div className="tp__builder__all__tp__item__container">
                    <div className="tp__builder__all__tp__item__container__iW">
                      {item.templates.map((template) => {
                        return (
                          <div className="tp__builder__all__tp__item">
                            <a
                              className="tp__builder__all__tp__item__iW"
                              href="#"
                            >
                              <div className="__all__tp__item__icon icon icon-lp-document-line" />
                              <div className="__all__tp__item__small-text">
                                {item.templateTypeName} for
                                <br />
                                <span>{template.templateName}</span>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
        setAllTemplates(template_list);
        setAllCategoriseTemplates(copyArray);

        setAllTemplatesSortingOrder("DESC");
        return;
      }
      if (allTemplatesSortingOrder === "DESC") {
        copyArray.forEach((templateArray) => {
          console.log(templateArray);
          templateArray.templates = [...templateArray.templates].sort((a, b) =>
            a.templateName > b.templateName ? 1 : -1
          );
        });

        const template_list = copyArray.map((item) => {
          return (
            <div className="tp__builder__all__section__wrapper">
              <div className="tp__builder__all__tp__section__title__w">
                <div
                  className="tp__builder__all__tp__section__title__iW"
                  hidden={hideViewAll}
                >
                  {item.templateTypeName ? (
                    <div className="all__tp__section__title">
                      {item.templateTypeName}
                    </div>
                  ) : null}
                  <div className="all__tp__section__md__line" />
                  <a
                    className="all__tp__section__btn"
                    href="#"
                    onClick={() => onTemplateTypeChange(item.templateTypeId)}
                  >
                    View All {item.templateTypeName}
                  </a>
                </div>
              </div>
              <div className="tp__builder__all__tp__template__container">
                <div className="tp__builder__all__tp__template__iW">
                  <div className="tp__builder__all__tp__item__container">
                    <div className="tp__builder__all__tp__item__container__iW">
                      {item.templates.map((template) => {
                        return (
                          <div className="tp__builder__all__tp__item">
                            <a
                              className="tp__builder__all__tp__item__iW"
                              href="#"
                            >
                              <div className="__all__tp__item__icon icon icon-lp-document-line" />
                              <div className="__all__tp__item__small-text">
                                {item.templateTypeName} for
                                <br />
                                <span>{template.templateName}</span>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
        setAllTemplates(template_list);
        setAllCategoriseTemplates(copyArray);

        setAllTemplatesSortingOrder("ASC");
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SortFrequentemplates = async (e) => {
    try {
      const copyArray = [...FrequentTemplatesData]; // create a new array & not mutate state
      console.log("feq sort");
      let FeqList = FrequentTemplatesData;

      if (allFrequentTemplatesSortingOrder === "ASC") {
        FeqList = [...FeqList].sort((a, b) =>
          a.templateName > b.templateName ? -1 : 1
        );

        const frequent_list = FeqList.map((item) => {
          let typeName = allTemplateTypes.find(
            (t) => t.tenantTemplateTypeId === item.templateTypeId
          )?.templateTypeName;
          return (
            <div className="tp__builder__fq__used__item">
              <a className="tp__builder__fq__used__item__iW" href="#">
                <div className="__fq__used__item__icon icon icon-lp-document-line" />
                <div className="__fq__used__item__small-text">
                  {typeName} for
                  <br />
                  <span>{item.templateName}</span>
                </div>
              </a>
            </div>
          );
        });
        setAllFrequentTemplates(frequent_list);
        setFrequentTemplatesData(FeqList);
        setAllFrequentTemplatesSortingOrder("DESC");
        return;
      }
      if (allFrequentTemplatesSortingOrder === "DESC") {
        FeqList = [...FeqList].sort((a, b) =>
          a.templateName > b.templateName ? 1 : -1
        );

        const frequent_list = FeqList.map((item) => {
          let typeName = allTemplateTypes.find(
            (t) => t.tenantTemplateTypeId === item.templateTypeId
          )?.templateTypeName;
          return (
            <div className="tp__builder__fq__used__item">
              <a className="tp__builder__fq__used__item__iW" href="#">
                <div className="__fq__used__item__icon icon icon-lp-document-line" />
                <div className="__fq__used__item__small-text">
                  {typeName} for
                  <br />
                  <span>{item.templateName}</span>
                </div>
              </a>
            </div>
          );
        });
        setAllFrequentTemplates(frequent_list);
        setFrequentTemplatesData(FeqList);
        setAllFrequentTemplatesSortingOrder("ASC");
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <div className="template__builder__page__container">
        <div className="template__builder__page__iW">
          <div className="template__builder__page__wrapper">
            <div className="template__builder__page__title__w">
              <i className="page__title__icon icon icon-lp-insert-template" />
              <div className="builder__page__title__container">
                <div className="builder__page__title__iW">
                  <div className="__page__main__title">Template</div>
                  <div className="__page__sub__title">
                    Create, edit &amp; share your contracts and agreements with
                    your partner, client or anyone
                  </div>
                </div>
              </div>
            </div>
            <div className="template__builder__options__container">
              <div className="template__builder__options__left">
                <select
                  className="form-select"
                  aria-label="Select Template"
                  value={templateTypeId}
                  onChange={(e) => onTemplateTypeChange(e.target.value)}
                >
                  <option selected value={0}>
                    All Templates
                  </option>
                  {templateTypesDD}
                </select>
              </div>
              <div className="template__builder__options__middle">
                <div className="col-auto">
                  {" "}
                  <label
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  >
                    Search
                  </label>
                  <div className="input-group">
                    <div className="input-before-text icon icon-lp-search" />
                    <input
                      className="form-control line-input"
                      type="text"
                      placeholder="Search"
                      id="autoSizingInputGroup"
                      onKeyDown={searchInputKeyDown}
                    />
                  </div>
                </div>
              </div>
              <div className="template__builder__options__right">
                <div className="__option__right__iW">
                  <div className="__option__right__sort"> </div>
                  <div className="__option__right__filter">
                    <div className="dropdown" hidden={true}>
                      <button
                        className="btn btn--primary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="dropdownMenu2"
                      >
                        Recent
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button className="dropdown-item" type="button">
                            Action
                          </button>
                          <button className="dropdown-item" type="button">
                            Action 2
                          </button>
                          <button className="dropdown-item" type="button">
                            Action 3
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp__builder__recent__container"
              hidden={recentTemplateDivView}
            >
              <div className="tp__builder__recent__title__W">
                Recent Templates
              </div>
              <div className="tp__builder__recent__template__container">
                <div className="tp__builder__recent__template__iW">
                  {createTemplatePermission.hasPermission ? (
                    <Link
                      className="tp__builder__create__new__item"
                      to={
                        ROUTE_OPTIONS.template_v2.children["create-template-v2"]
                          .path
                        // ROUTE_OPTIONS.templates.children["create-templates"]
                        //   .path
                      }
                    >
                      <div className="tp__builder__create__new__item__iW">
                        <div className="__create__new__item__icon icon icon-lp-document-add" />
                        <div className="__create__new__item__small-text">
                          Create New{" "}
                        </div>
                        <div className="__create__new__item__big-text">
                          Template
                        </div>
                      </div>
                    </Link>
                  ) : null}

                  <div className="tp__builder__recent__item__container">
                    <div className="tp__builder__recent__item__container__iW">
                      {allRecentTemplates}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp__builder__fq__used__container"
              hidden={frequentTemplateDivView}
            >
              <div className="tp__builder__fq__used__title__W">
                Frequently Used Templates
              </div>
              <div className="tp__builder__fq__used__filter">
                Sort by
                <span
                  className="event-pointer icon icon-lp-sort"
                  title="Sort By"
                  onClick={SortFrequentemplates}
                >
                  {allFrequentTemplatesSortingOrder}
                </span>
              </div>
              <div className="tp__builder__fq__used__template__container">
                <div className="tp__builder__fq__used__template__iW">
                  <div className="tp__builder__fq__used__item__container">
                    <div className="tp__builder__fq__used__item__container__iW">
                      {allFrequentTemplates}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tp__builder__all__tp__container">
              <div className="tp__builder__all__tp__title__W">
                {templateTitle}
              </div>
              <div className="tp__builder__all__tp__filter">
                Sort by
                <a
                  className="icon icon-lp-sort"
                  href="#"
                  title="Sort By"
                  onClick={SortAllTemplates}
                >
                  {allTemplatesSortingOrder}
                </a>
              </div>
              {allTemplates}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TemplateSearch;
