import produce from "immer";
import { create } from "zustand";

let store = () => ({
  isVisible: false,
  meta: null,
});

export const useProfileUpdateModal = create(store);
const state = useProfileUpdateModal;

// outside functions

export const openProfileUpdateDialog = (meta) => {
  state.setState(
    produce((draft) => {
      draft.isVisible = true;
      draft.meta = meta;
    })
  );
};

export const hideProfileUpdateDialog = () => {
  state.setState(
    produce((draft) => {
      draft.isVisible = false;
      draft.meta = null;
    })
  );
};
