import React from "react";
import IconButton from "../../Buttons/IconButton";

const Icon = ({
  as = "div",
  icon,
  iconSize,
  size,
  inheritParentColor,
  ...rest
}) => {
  return (
    <IconButton
      as={as}
      icon={icon}
      btnIconSize={iconSize}
      btnSize={size}
      inheritParentColor={inheritParentColor}
      {...rest}
    />
  );
};

export default Icon;
