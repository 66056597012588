import React from "react";
import { isSameDay, isDate } from "date-fns";

import { UIDatePickerFooter } from "../../../../common/components/DatePicker/styled";
import DaySelectOption from "../DaySelectOption";
import { useCalendar } from "../hooks/useCalendar";
import { DayPicker } from "./styled";

const Calendar = (props, ref) => {
  const {
    viewMode,
    handleModifiers,
    modifiers = {},
    handleDaySelect,
    onMonthChange,
    ...otherProps
  } = props;
  const { currentSelectDate, isDisabled } = useCalendar();
  const [showOptionMenu, setShowOptionMenu] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const [dayPayload, setDayPayload] = React.useState(null);

  const DateFooter = (
    <UIDatePickerFooter className="date-picker-footer">
      <div
        className="rdp__day day__na"
        data-tooltip-text="Not Available"
        data-tooltip-append-to=".date-picker-footer"
      >
        NA
      </div>

      <div
        className="rdp__day day__con"
        data-tooltip-text="Confirmed"
        data-tooltip-append-to=".date-picker-footer"
      >
        Con
      </div>

      <div
        className="rdp__day day__tent"
        data-tooltip-text="Tentative"
        data-tooltip-append-to=".date-picker-footer"
      >
        Tent
      </div>

      <div
        className="rdp__day day__pref"
        data-tooltip-text="Prefered"
        data-tooltip-append-to=".date-picker-footer"
      >
        Pre
      </div>

      <div
        className="rdp__day day__pub"
        data-tooltip-text="Public Holiday"
        data-tooltip-append-to=".date-picker-footer"
      >
        <i></i> Pub
      </div>
    </UIDatePickerFooter>
  );

  const handleDayClick = (day, _modifiers, event) => {
    // setTarget(event.target);
    // setShowOptionMenu(true);
    handleDaySelect && handleDaySelect(day, _modifiers);

    // setDayPayload({
    //   day,
    //   modifiers: _modifiers,
    //   selectedType:
    //     Object.keys(_modifiers).length > 0 ? Object.keys(_modifiers)[0] : null,
    // });
  };

  const removeDay = (type, date, days) => {
    const index = days[type]?.findIndex((selectedDay) =>
      isSameDay(
        date,
        isDate(selectedDay.date) ? selectedDay.date : new Date(selectedDay.date)
      )
    );

    if (index > -1) {
      days[type]?.splice(index, 1);
    }

    return days;
  };

  const onDayOptionSelectHandler = (data) => {
    setShowOptionMenu(false);

    const { day, selectedType, modifiers: _modifiers } = data;
    const modifiersDays = { ...modifiers };

    // If already existing modifiers
    if (_modifiers[selectedType] && !_modifiers?.public) {
      const modifiersDates = removeDay(selectedType, day, modifiersDays);
      handleModifiers(modifiersDates);
      return;
    }

    let updatedModifiersDays;

    for (let key in modifiersDays) {
      if (key === "public") {
        continue;
      }
      updatedModifiersDays = removeDay(key, day, modifiersDays);
    }

    if (Object(updatedModifiersDays).hasOwnProperty(selectedType)) {
      updatedModifiersDays[selectedType].push({ date: day });
    } else {
      updatedModifiersDays = {
        ...updatedModifiersDays,
        [selectedType]: [{ date: day }],
      };
    }

    handleModifiers(updatedModifiersDays);
  };

  const editModeProps = !viewMode
    ? {
        mode: "single",
        selected: currentSelectDate,
        // onSelect: setDays,
        onDayClick: handleDayClick,
        onMonthChange,
        ...otherProps,
      }
    : {
        ...otherProps,
      };

  const modifiersToStringArray = React.useMemo(() => {
    let updated = {};
    for (let key in modifiers) {
      updated = {
        ...updated,
        [key]: modifiers[key]?.map((data) => new Date(data.date)),
      };
    }
    return updated;
  }, [modifiers]);

  return (
    <div ref={ref}>
      <DayPicker
        disabled={isDisabled}
        // modifiers={modifiersToStringArray}
        modifiers={modifiers}
        modifiersClassNames={{
          notAvailable: "day--na",
          confirm: "day--con",
          public: "day--pub",
          tentative: "day--tent",
          prefered: "day--pref" ,
        }}
        showOutsideDays={false}
        footer={DateFooter}
        {...editModeProps}
      />

      {/* Option popover menu */}
      <DaySelectOption
        isShow={showOptionMenu}
        anchorEl={target}
        selectedDayPayload={dayPayload}
        onOptionSelect={onDayOptionSelectHandler}
        closeOutsideClick={() => setShowOptionMenu(false)}
      />
    </div>
  );
};

export default React.forwardRef(Calendar);
