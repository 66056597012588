import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8652 12.8096C11.5445 12.8096 11.2852 13.0689 11.2852 13.3896L11.3066 14.9032L1.1593 14.9247L1.13787 2.15932L11.2852 2.13789V3.94079C11.2852 4.26079 11.5445 4.5208 11.8652 4.5208C12.1859 4.5208 12.4452 4.26151 12.4452 3.94079V2.13789C12.4452 1.51073 11.9345 1 11.3073 1H1.13789C0.510731 1 0 1.51002 0 2.13789V14.9246C0 15.5518 0.510731 16.0625 1.13789 16.0625H11.3066C11.9338 16.0625 12.4445 15.5525 12.4445 14.9246V13.3896C12.4445 13.0696 12.1852 12.8096 11.8645 12.8096L11.8652 12.8096Z"
      fill="currentColor"
    />
    <path
      d="M15.5067 5.02669C14.8467 4.3681 13.776 4.3681 13.1182 5.02669L7.43449 10.7104C6.78019 11.2618 6.71304 12.554 6.52804 13.3225C6.49019 13.5126 6.54947 13.709 6.68662 13.8461C6.82376 13.984 7.02233 14.0426 7.2102 14.0047C7.9838 13.8161 9.26812 13.7533 9.82237 13.0983L15.5061 7.41458C16.1646 6.75598 16.1646 5.68526 15.5061 5.0266L15.5067 5.02669ZM14.6867 6.59457L9.00299 12.2783C8.74441 12.5811 8.19583 12.6147 7.83583 12.6968C7.92083 12.3333 7.95083 11.7904 8.25513 11.5297L13.9388 5.846C14.4281 5.361 15.176 6.10028 14.6874 6.59459L14.6867 6.59457Z"
      fill="currentColor"
    />
    <path
      d="M8.52013 4.85023C8.50942 4.4588 8.28942 4.09735 7.94726 3.90663C7.21796 3.56733 6.4658 2.79518 5.68217 3.24946L4.50143 3.90663C4.15928 4.09735 3.93928 4.45878 3.92857 4.85094C3.91285 5.47596 4.05928 7.01528 5.76723 7.79665C5.91081 7.86236 6.06795 7.89522 6.22438 7.89522C6.38153 7.89522 6.53795 7.86236 6.68082 7.79665C8.38871 7.01521 8.53586 5.47653 8.52013 4.85023ZM6.24936 6.74247C5.26078 6.2896 5.0722 5.48031 5.06505 4.91886L6.20222 4.26169L7.36081 4.9067C7.3701 5.50385 7.17296 6.29605 6.24936 6.74247Z"
      fill="currentColor"
    />
    <path
      d="M7.36152 9.09955C7.36152 8.77954 7.10223 8.51953 6.78151 8.51953H2.85365C2.53293 8.51953 2.27364 8.77883 2.27364 9.09955C2.27364 9.41956 2.53293 9.67957 2.85365 9.67957H6.78151C7.10223 9.67957 7.36152 9.42027 7.36152 9.09955Z"
      fill="currentColor"
    />
    <path
      d="M5.9972 11.3175C5.9972 10.9975 5.7379 10.7375 5.41718 10.7375H2.85347C2.53275 10.7375 2.27346 10.9967 2.27346 11.3175C2.27346 11.6375 2.53275 11.8975 2.85347 11.8975H5.41718C5.7379 11.8975 5.9972 11.6382 5.9972 11.3175Z"
      fill="currentColor"
    />
    <path
      d="M4.85999 12.7968H2.85418C2.09273 12.8097 2.09202 13.9433 2.85418 13.9561H4.85999C5.62144 13.9433 5.62215 12.8097 4.85999 12.7968Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
