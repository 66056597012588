import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getNotificationByUser,
  readNotification,
} from "../Actions/Notification";
import useUserData from "../hooks/useUserData";
import { hasClassName } from "../utility";
import produce, { current } from "immer";

export const NOTIFY_ME_KEY = "notify_time";
export const NOTIFICATION_KEY = "notify";

const notifySelectValues = [
  { key: 10, label: "10min" },
  { key: 15, label: "15min" },
  { key: 20, label: "20min" },
];

const initialState = {
  isVisible: false,
  count: -1,
  notificationList: [],
  notifyTimes: [],
  selectedNotifyTime: 10,
  onToggleNotification: () => {},
  onNotificationSubmit: (val) => {},
  onReadNotification: (id) => {},
};

export const NotificationContext = React.createContext(initialState);

export const useNotification = () => {
  return React.useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  // Local states
  const [isVisible, setIsVisible] = React.useState(false);
  const [notificationList, setNotificationList] = React.useState([]);
  const [notifyTimes] = React.useState(notifySelectValues);
  const [selectedNotifyTime, setSelectedNotifyTime] = React.useState();
  const [count, setCount] = React.useState(-1);

  // Hooks
  const { user } = useUserData();
  const queryClient = useQueryClient();

  // Variables
  const userId = user?.result?.userId;

  // Functions
  const onToggleNotification = () => {
    setIsVisible((prev) => !prev);
  };

  React.useEffect(() => {
    if (!isVisible) return;

    const onClick = (e) => {
      if (
        hasClassName(e.target, "notifications") ||
        hasClassName(e.target, "btn__notify") ||
        hasClassName(e.target, "btn_n_remove")
      ) {
        return;
      }
      onToggleNotification();
    };

    document.querySelector("body").addEventListener("click", onClick);

    return () => {
      document.querySelector("body").removeEventListener("click", onClick);
    };
  }, [isVisible]);

  const onNotificationSubmit = (val) => {
    localStorage.setItem(NOTIFY_ME_KEY, val);
    setSelectedNotifyTime(val);
  };

  // Get all notifications
  useQuery({
    queryKey: [NOTIFICATION_KEY, userId, selectedNotifyTime],
    queryFn: async () => {
      const res = await getNotificationByUser(userId);
      return res?.result;
    },
    enabled: !!userId,
    /*
      The query will continuously refetch at this frequency in milliseconds
      60000 = 1min
      By default selectedNotifyTime = 10
      then 60000 * 10 = 10min
    */
    refetchInterval: 60000 * selectedNotifyTime,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    onSuccess: (data) => {
      if (!data?.length) {
        return;
      }

      setNotificationList(data);
      setCount(
        data.reduce(
          (total, item) =>
            total + item.notifications?.filter((d) => !d.isRead).length,
          0
        )
      );
    },
  });

  // Update read status
  const notificationMutation = useMutation(
    async (id) => {
      return await readNotification(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [NOTIFICATION_KEY, userId, selectedNotifyTime],
        });
      },
    }
  );

  React.useEffect(() => {
    const time = localStorage.getItem(NOTIFY_ME_KEY);

    if (!time) {
      const defaultVal = notifySelectValues[0];
      localStorage.setItem(NOTIFY_ME_KEY, defaultVal.key);
      setSelectedNotifyTime(defaultVal.key);
      return;
    }

    setSelectedNotifyTime(time);
  }, []);

  const onReadNotification = React.useCallback(
    (id) => {
      setNotificationList(
        produce((draft) => {
          draft.forEach((d) => {
            d.notifications.forEach((n) => {
              if (n.notificationId === id) {
                n.isRead = true;
              }
            });
          });
        })
      );

      notificationMutation.mutate(id);
    },
    [notificationMutation]
  );

  const values = {
    isVisible,
    count,
    notificationList,
    notifyTimes,
    selectedNotifyTime,
    onToggleNotification,
    onNotificationSubmit,
    onReadNotification,
  };

  return (
    <NotificationContext.Provider value={values}>
      {children}
    </NotificationContext.Provider>
  );
};
