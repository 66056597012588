import React from "react";
import produce from "immer";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { getNotes, saveNote, updateNotesReadStatus } from "../api";
import useUserData from "../../../../hooks/useUserData";

export const NoteKeys = {
  GET_NOTE: "GET_NOTE",
  SAVE_NOTE: "SAVE_NOTE",
  READ_STATUS: "READ_STATUS",
};

const formInitialValue = { note: "" };

const NoteContext = React.createContext({
  notes: [],
  notesUnreadCount: 0,
  projectId: null,
  projectMemberId: null,
  formValues: formInitialValue,
  isNoteSubmitting: false,
  isFormValid: false,
  textAreaRef: null,
  onNotesInvalidate: () => {},
  setFormValue: (key, value) => {},
  handleSubmit: () => {},
});

export const useNote = () => React.useContext(NoteContext);

const NoteContextProvider = ({
  children,
  projectId,
  memberId,
  noteType,
  noteMarkAsReadOnMounted = false,
}) => {
  const { userId } = useUserData();
  const textAreaRef = React.useRef();

  const queryClient = useQueryClient();
  const [notes, setNotes] = React.useState([]);
  const [notesUnreadCount, setNotesUnreadCount] = React.useState(0);

  const [isFormValid, setIsFormValid] = React.useState(false);
  const [formValues, setNoteFormValues] = React.useState(formInitialValue);

  const [enabledNotesMarkAsRead, setIsNotesMarkAsRead] = React.useState(
    noteMarkAsReadOnMounted
  );

  const setFormValue = React.useCallback((key, value) => {
    setNoteFormValues(
      produce((draft) => {
        if (Object(draft).hasOwnProperty(key)) {
          draft[key] = value;
        }
      })
    );
  }, []);

  const getNotesQueryKey = React.useMemo(() => {
    return [NoteKeys.GET_NOTE, projectId, memberId];
  }, [memberId, projectId]);

  const isGetNotesEnabled = !!projectId && !!memberId;

  // Get Notes
  useQuery({
    queryKey: getNotesQueryKey,
    queryFn: () => getNotes(projectId, memberId),
    onSuccess: (data) => {
      setNotes(data?.projectOnboardNotes || []);
      setNotesUnreadCount(data?.unreadNoteCount || 0);
    },
    refetchIntervalInBackground: false,
    enabled: isGetNotesEnabled,
    refetchOnWindowFocus: false,
  });

  const onNotesInvalidate = React.useCallback(() => {
    if (!isGetNotesEnabled) {
      return;
    }

    queryClient.invalidateQueries({
      queryKey: getNotesQueryKey,
      exact: true,
    });
  }, [getNotesQueryKey, isGetNotesEnabled, queryClient]);

  const noteMutation = useMutation({
    mutationKey: [NoteKeys.SAVE_NOTE, projectId, memberId],
    mutationFn: (data) => saveNote(data),
    onSettled: (data, error, variables, context) => {
      // Clear the form values
      setNoteFormValues(formInitialValue);

      // Update notes list
      setNotes(data);

      // Invalidate the notes query
      onNotesInvalidate();

      textAreaRef.current?.focus();
    },
  });

  const readStatusMutation = useMutation({
    mutationKey: [NoteKeys.READ_STATUS, projectId, memberId],
    mutationFn: (data) => updateNotesReadStatus(data),
    onSettled: (data, error, variables, context) => {},
  });

  const handleSubmit = React.useCallback(() => {
    if (!formValues?.note) {
      return;
    }

    const payload = {
      projectOnboardNoteId: 0, // For CREATE = 0
      projectId,
      projectMemberId: memberId,
      note: formValues?.note,
      dateTime: new Date().toISOString(),
      noteAddedByUserId: userId, // current logged user id
      actionType: noteType,
    };

    //
    noteMutation.mutate(payload);
  }, [formValues?.note, memberId, noteMutation, noteType, projectId, userId]);

  React.useEffect(() => {
    if (!enabledNotesMarkAsRead) return;

    if (!notes.length) return;

    const payload = {
      projectId,
      projectMemberId: memberId,
      isRead: true,
      onboardNoteIds: notes.map((note) => note.projectOnboardNoteId),
    };

    // Reset the flag
    setIsNotesMarkAsRead(false);

    // Update the read status
    readStatusMutation.mutate(payload);
  }, [enabledNotesMarkAsRead, memberId, notes, projectId, readStatusMutation]);

  React.useEffect(() => {
    setIsFormValid(!!formValues.note?.length);
  }, [formValues.note]);

  const values = {
    isFormValid,
    notes,
    projectMemberId: memberId,
    projectId,
    formValues,
    isNoteSubmitting: noteMutation.isLoading,
    textAreaRef,
    notesUnreadCount,
    setFormValue,
    handleSubmit,
  };

  return <NoteContext.Provider value={values}>{children}</NoteContext.Provider>;
};

export default NoteContextProvider;
