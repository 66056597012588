import React from "react";
import { Wrapper } from "./FormStyled";
import { WallComponent } from "../../../../common/components/UI/Modal/BaseFlexModal";
import FieldGroup from "../../../../common/components/Form/FieldGroup";
import InputField from "../../../../common/components/Form/InputField";
import SelectField from "../../../../common/components/Form/SelectField";
import TextAreaField from "../../../../common/components/Form/TextAreaField";
import UIButton from "../../../../common/components/Buttons/UIButton";
import { useCalendar } from "../hooks/useCalendar";
import SelectionField from "../../../../common/components/Form/SelectionField";
import DatePickerField from "../../../../common/components/Form/DatePickerField";
import { AvailabilityStatus } from "../constant";
import { useFormik, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addHours, format } from "date-fns";
import { useQuery } from "react-query";
import { getEventFormData } from "../api";
import { useGlobalStore } from "../../../../store/GlobalStore";

const availabilities = [
  { label: "Not Available", value: AvailabilityStatus.NA },
  { label: "Confirmed", value: AvailabilityStatus.Confirm },
  { label: "Public Holiday", value: AvailabilityStatus.Public },
  { label: "Tentative", value: AvailabilityStatus.Tentative },
  { label: "Preferred", value: AvailabilityStatus.Preferred },
];

const initialValues = {
  title: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  availability: 2,
  isFullDay: false,
  note: "",
};

const validationSchema = Yup.object().shape({
  startTime: Yup.string().when("isFullDay", {
    is: false,
    then: Yup.string().required("Start Time is required"),
  }),
  endTime: Yup.string().when("isFullDay", {
    is: false,
    then: Yup.string().required("End Time is required"),
  }),
  availability: Yup.string().required("Availability is required"),
});

const Form = () => {
  const {
    closeDialogHandler,
    onEventSubmit,
    selectTimeSlot,
    isDialogVisible,
    dialogFormValues,
  } = useCalendar();

  const projectId = useGlobalStore((state) => state.selectedProjectId) || 0;

  const isEditMode = !!dialogFormValues;

  const formik = useFormik({
    initialValues,
    onSubmit: (value, options) => {
      if (!value.isFullDay && (!value.startTime || !value.endTime)) {
        if (!toast.isActive("timeError")) {
          toast.error(
            "Start Time and End Time are required when Full Day is not selected.",
            { toastId: "timeError" }
          );
        }
        return;
      }
      onEventSubmit &&
        onEventSubmit({
          value,
          options,
        });
    },
  });

  const { values, setFieldValue, handleChange, handleSubmit } = formik;

  React.useEffect(() => {
    if (!isDialogVisible) return;

    // If it is a new event
    if (!isEditMode) {
      const startDate = new Date(selectTimeSlot?.date);
      const startTime = new Date(
        `${startDate.toDateString()} ${selectTimeSlot?.time}`
      );
      const endTime = new Date(addHours(startTime, 1));

      setFieldValue("startTime", format(startTime, "HH:mm"));
      setFieldValue("endTime", format(endTime, "HH:mm"));
      setFieldValue("startDate", startDate);
      setFieldValue("endDate", startDate);
      return;
    }
  }, [selectTimeSlot, isDialogVisible, setFieldValue, isEditMode]);

  useQuery(
    {
      queryKey: ["get-event-form"],
      queryFn: () => {
        console.log("get frm");
        return getEventFormData(
          dialogFormValues?.calendarId || 0,
          dialogFormValues?.groupId || ""
        );
      },
      enabled: isEditMode,
      onSuccess: (data) => {
        console.log("form data", data);

        setFieldValue("startTime", data?.startTime);
        setFieldValue("endTime", data?.endTime);
        setFieldValue("startDate", data?.startDate);
        setFieldValue("endDate", data?.endDate);
        setFieldValue("title", data?.title);
        setFieldValue("availability", data?.availability);
        setFieldValue("note", data?.comment);
        setFieldValue("isFullDay", data?.isFullDay);
      },
    }
    // {
    //   enabled: isEditMode,
    // }
  );
  const onIsFulldaySelect = React.useCallback(
    (data) => {
      setFieldValue("isFullDay", data?.target?.checked);

      if (data?.target?.checked) {
        setFieldValue("startTime", "00:00");
        setFieldValue("endTime", "23:59");
      } else {
        setFieldValue("startTime", "");
        setFieldValue("endTime", "");
      }
    },
    [setFieldValue]
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Wrapper onSubmit={handleSubmit}>
        <WallComponent>
          <div className="form__inputs">
            <div className="column">
              <FieldGroup label="Title">
                <InputField
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
              </FieldGroup>
            </div>

            <div className="column column--2">
              <FieldGroup label="Start Date">
                <DatePickerField
                  name="startDate"
                  value={values.startDate}
                  onDateSelect={(date) => setFieldValue("startDate", date)}
                />
              </FieldGroup>

              <FieldGroup label="End Date">
                <DatePickerField
                  name="endDate"
                  value={values.endDate}
                  onDateSelect={(date) => setFieldValue("endDate", date)}
                />
              </FieldGroup>
            </div>

            <div className="column column--2">
              <FieldGroup label="Start Time">
                <InputField
                  type="time"
                  name="startTime"
                  value={values.startTime}
                  onChange={handleChange}
                  disabled={values.isFullDay}
                />
              </FieldGroup>

              <FieldGroup label="End Time">
                <InputField
                  type="time"
                  name="endTime"
                  value={values.endTime}
                  onChange={handleChange}
                  disabled={values.isFullDay}
                />
              </FieldGroup>
            </div>

            <div className="column">
              <SelectionField
                text="Full Day"
                name="isFullDay"
                checked={values.isFullDay}
                onChange={(d) => onIsFulldaySelect(d)}
              />
            </div>

            <div className="column">
              <FieldGroup label="Availability">
                <SelectField
                  value={availabilities.find(
                    (d) => String(d?.value) === String(values.availability)
                  )}
                  options={availabilities}
                  onChange={(select) => {
                    setFieldValue("availability", select?.value);
                  }}
                />
              </FieldGroup>
            </div>

            <div className="column">
              <FieldGroup label="Note">
                <TextAreaField
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                />
              </FieldGroup>
            </div>
          </div>
        </WallComponent>

        <div className="form__actions">
          <UIButton
            type="button"
            variant={UIButton.Variant.Danger}
            size={UIButton.Sizes.Small}
            onClick={closeDialogHandler}
          >
            Cancel
          </UIButton>
          <UIButton type="submit" size={UIButton.Sizes.Small}>
            {isEditMode ? "Update" : "Add"}
          </UIButton>
        </div>
      </Wrapper>
    </Formik>
  );
};

export default Form;
