import styled from "styled-components";
import BaseFlexModal from "../../../../../common/components/UI/Modal/BaseFlexModal";

export const BaseDialog = styled(BaseFlexModal)`
  --modal-max-width: 500px;

  .modal__content {
    .header__container {
      padding-bottom: var(--layout-spacing-16);
    }
  }
`;
