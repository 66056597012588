import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9664 2.27182L6.661 14.9319C6.63175 14.9747 6.5842 15 6.53366 14.9996C6.51917 15.001 6.50462 14.9986 6.49122 14.9929C6.42673 14.9755 6.37891 14.9192 6.37041 14.8506L5.6815 9.00487L0.0921178 6.08534C0.0307631 6.05394 -0.00556143 5.98662 0.000696976 5.91599C0.00994789 5.84479 0.0605558 5.78686 0.128033 5.77035L15.8002 2.00433C15.8672 1.98831 15.9366 2.0176 15.9739 2.07751C16.0112 2.13741 16.0083 2.21511 15.9667 2.27191L15.9664 2.27182Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
