import {
  RESEARCH_FILED_DISPLAY_NAME,
  RESEARCH_TAG_TYPE,
  RESEARCH_FILED_DISPLAY_TYPE,
} from "../constant";

export const getSubtitle = (tagdata, formdata) => {
  if (!tagdata || !formdata) {
    console.warn("Invalid inputs to getSubtitle");
    return undefined;
  }

  if (tagdata.tagTypeId === RESEARCH_TAG_TYPE.Contributor) {
    const designation = formdata.find(
      (f) =>
        f.displayName === RESEARCH_FILED_DISPLAY_NAME.DESIGNATION &&
        f.value?.trim()
    );
    if (designation) return designation.value;

    const organization = formdata.find(
      (f) =>
        f.displayName === RESEARCH_FILED_DISPLAY_NAME.ORGANIZATION &&
        f.value?.trim()
    );
    if (organization) return organization.value;
  } else if (tagdata.tagTypeId === RESEARCH_TAG_TYPE.TALENT) {
    const role = formdata.find(
      (f) =>
        f.displayName === RESEARCH_FILED_DISPLAY_NAME.ROLE && f.value?.trim()
    );
    if (role) return role.value;

    const organization = formdata.find(
      (f) =>
        f.displayName === RESEARCH_FILED_DISPLAY_NAME.ORGANIZATION &&
        f.value?.trim()
    );
    if (organization) return organization.value;
  } else if (
    tagdata.tagTypeId === RESEARCH_TAG_TYPE.ACCESS ||
    tagdata.tagTypeId === RESEARCH_TAG_TYPE.COMPLIANCE
  ) {
    const authority = formdata.find(
      (f) =>
        (f.displayName === RESEARCH_FILED_DISPLAY_NAME.AUTHORITY ||
          f.displayName ===
            RESEARCH_FILED_DISPLAY_NAME.AUTHORITY_ORGANIZATION) &&
        f.value?.trim()
    );

    const keyinfo_contact_person = formdata.find(
      (f) =>
        f.displayName === RESEARCH_FILED_DISPLAY_NAME.CONTACT_PERSON &&
        f.tagSubTypeId === 1 &&
        f.value?.trim()
    );

    let access_subtitle = "";
    if (authority && keyinfo_contact_person) {
      access_subtitle = authority.value + " | " + keyinfo_contact_person.value;
    } else if (authority) {
      access_subtitle = authority.value;
    } else if (keyinfo_contact_person) {
      access_subtitle = keyinfo_contact_person.value;
    }
    //console.warn(access_subtitle);
    return access_subtitle;
  }

  const subtitle = formdata.find(
    (f) =>
      f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.SUBTITLE &&
      f.value?.trim()
  )?.value;

  return subtitle || undefined;
};


export const getVisualType = (tagdata, formdata) => {
  const singleData = formdata?.filter(
    (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.VISUAL
  )[0];

  if (tagdata.tagTypeId === RESEARCH_TAG_TYPE.ARCHIVE ) {
    const imageTypeData = singleData?.value;

    if (!imageTypeData || imageTypeData.length === 0 || imageTypeData[0]?.extension?.toLowerCase() !== 'jpg') {
      return 0;
    }
    
  }
  
  return singleData?.visualTypeId;
};


