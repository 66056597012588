import API_ENDPOINTS from "../../../../../services/api-endpoints";
import API_Project from "../../../../../services/api-project";
import { toast } from "react-toastify";
import Alert from "../../../../../utility/alert";
import API_Tenant from "../../../../../services/api-tenant";
import { projectOnboardingCompletion } from "../../../../../Actions/Project";
import { createUserRole } from "../../../../../Actions/Setting/Permission";
import {
  createDocument,
  generateUserDoc,
} from "../../../../../Actions/Templates";
import { TEMPLATE_TYPES } from "../../../../../constant/System";

import { hideAddToProjectDialog } from "../../Onboarding/AddToProjectDialog/store";

/* {
    Add To Project Body:
    {  
      "projectMemberId": 0,  
      "projectId": 0,  
      "projectRoleId": 0,  
      "memberUserId": "string",  
      "memberName": "string",  
      "userTenantId": 0
      }
} */
export const AddMemberToProject = async ({
  selectedProjectMember,
  role, // user role - eg : Hr, Admin etc..
  isOnboardingByPassResult,
  ndaDefaultDoc,
  _PROJECT_,
  senderNote,
  startDate,
  endDate,
  assignedEpisodes,
  jobTitle,
}) => {
  console.log("selectedProjectMember ", selectedProjectMember);
  try {
    if (jobTitle === 0) {
      toast.warning("Please select Project Role.");
      return;
    }

    if (isOnboardingByPassResult) {
      if (role === 0) {
        toast.warning("Please select User Role.");
        return;
      }
    }

    const confirmation = await Alert.confirm({
      allowOutsideClick: false,
      text: "Do you sure you want to add this member to Project?",
      confirmButtonText: "Yes",
    });

    if (!confirmation.isConfirmed) {
      return;
    }

    if (!isOnboardingByPassResult && !ndaDefaultDoc?.templateId) {
      toast.error("The project template for the NDA was not found");
      return;
    }

    const endpoints = new API_ENDPOINTS();
    const response = API_Project.post(endpoints.PROJECT_ADD_USER_TO_PROJECT, {
      memberUserId: selectedProjectMember.userId,
      memberName:
        selectedProjectMember.givenName + " " + selectedProjectMember.surName,
      projectId: _PROJECT_.projectId,
      projectMemberId: 0,
      ProjectRoleId: jobTitle,
    }).then((response) => {
      console.log("add to project", response);
      if (response.data.isSuccess === true) {
        console.log("AddMemberToProject ", response.data.result);
        if (isOnboardingByPassResult === false) {
          createNDADocument(
            response.data.result.projectMemberId,
            ndaDefaultDoc,
            selectedProjectMember,
            _PROJECT_,
            senderNote,
            startDate,
            endDate,
            assignedEpisodes
          );
        }
        if (isOnboardingByPassResult === true) {
          onboardMemberAndChangeUserRole(response.data.result, role);
        }
      } else {
        console.log(response.data);
        toast.error(response.data.result.message);

        return;
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const createNDADocument = (
  projectMemberId,
  ndaDefaultDoc,
  selectedProjectMember,
  _Project_,
  senderNote,
  startDate,
  endDate,
  assignedEpisodes
) => {
  const userTenantId = selectedProjectMember?.userTenantId;
  const userId = selectedProjectMember?.userId;

  //console.log("selectedProjectMember ", projectMemberId);

  // selectedTemplateId
  generateUserDoc(ndaDefaultDoc.templateId, userTenantId)
    .then((res) => {
      console.log("generateUserDoc: ", res);
      if (!res.isSuccess) {
        toast.error(res?.errorMessages);
        return;
      }

      const docURL = res?.result?.docURL;

      const createDocumentBody = {
        userDocumentId: 0,
        documentTypeId: TEMPLATE_TYPES.NDA,
        documentURL: docURL,
        projectId: _Project_.projectId,
        senderNote: senderNote,
        templateId: 1,
        userId: userId,
        remarks: "",
      };

      createDocument(createDocumentBody)
        .then((res) => {
          console.log("createDocument : ", res);
          if (!res.isSuccess) {
            toast.error(res.displayMessage);
            return;
          }

          const userDocumentId = res?.result?.userDocumentId;

          //console.log(projectMemberId,"<- member id --- doc id => ",userDocumentId);
          console.log("AssignedEpisodes in NDA = ", assignedEpisodes);

          // Send email
          SendInvitaion(
            projectMemberId,
            userDocumentId,
            senderNote,
            startDate,
            endDate,
            assignedEpisodes,
            _Project_
          );

          //getShortList(_Project_.projectId);
          hideAddToProjectDialog();
        })
        .catch(() => {
          toast.error(res.displayMessage);
        });
    })
    .catch((err) => {
      console.error("Error Creating NDA Document", err);
    });
};

/*Send Invitation  Body
  {  
  "projectInvitationId": 0,  // no need to send
  "projectId": 0,  
  "projectMemberId": 0,  
  "invitationUrl": "string",  
  "senderNote": "string",  
  "templateId": 0,  
  "userDocumentId": 0,  
  "startDate": "2025-01-08T15:27:28.743Z",  
  "endDate": "2025-01-08T15:27:28.743Z",  
  "isProjectDocumentRequired": true,  
  "assignedEpisodes": 
    [    {      
            "id": 0,      
            "projectInvitationId": 0,      
            "projectMemberId": 0,      
            "episodeId": 0    
          }
  ]
}
  */
export const SendInvitaion = (
  projectMemberId,
  userDocumentId,
  senderNote,
  startDate,
  endDate,
  assignedEpisodes,
  _PROJECT_
) => {
  try {
    const ConvertedAssignedEpisodes = Array.isArray(assignedEpisodes)
      ? assignedEpisodes.map((ep) => ({
          id: 0,
          projectInvitationId: 0,
          projectMemberId: projectMemberId,
          episodeId: ep.value,
        }))
      : [];

    console.log("base url", window.Location);
    var base_url = window.location.origin;

    const InvitationBody = {
      projectInvitationId: 0,
      projectId: _PROJECT_.projectId,
      projectMemberId: projectMemberId,
      invitationUrl: base_url + "/project/brief/" + _PROJECT_.projectId,
      senderNote: senderNote,
      templateId: 0,
      userDocumentId: userDocumentId,
      startDate: startDate || null,
      endDate: endDate || null,
      isProjectDocumentRequired: true,
      assignedEpisodes: ConvertedAssignedEpisodes || [],
    };

    const endpoints = new API_ENDPOINTS();
    API_Project.post(endpoints.PROJECT_SEND_PROJECT_ADDED_USER_INVITAION, {
      ...InvitationBody,
    }).then((response) => {
      console.log("Send Invitaion: ", response);
      if (response.data.isSuccess === true) {
        toast.success("Added to the Project and Invitation Sent Successfully");
        hideAddToProjectDialog();
      } else {
        console.error(response.data.displayMessage);
        toast.error(response.data.displayMessage + " in Project Invitaion");
        hideAddToProjectDialog();

        return;
      }
    });
  } catch (err) {
    console.log(err);
    hideAddToProjectDialog();
  }
};

export const onboardMemberAndChangeUserRole = async (member, role) => {
  try {
    const payload = {
      bankDetails: "",
      companyEmail: "",
      healthDetails: null,
      password: "",
      projectMemberId: member?.projectMemberId,
      isComplete: true,
    };

    const _payload = {
      ...payload,
      isComplete: true,
    };

    //Onboard User
    const res = await projectOnboardingCompletion(_payload);
    //console.log("1 Onboard User", res);

    if (!res.isSuccess) {
      toast.error(res?.displayMessage);
      return;
    }

    if (res.isSuccess) {
      // toast.success("User Onboarded Successfully");
      //Assign User Role
      //console.log("2 Onboard User", res);
      let userId = member?.memberUserId;
      if (!userId) return;

      createUserRole({ userId, roleId: role })
        .then((roleres) => {
          if (!roleres.isSuccess) {
            //console.log("3 Onboard User roleres fail ", roleres);
            toast.error(res?.displayMessage);
            return;
          }
          if (roleres.isSuccess) {
            //console.log("3 Onboard User roleres ", roleres);
            hideAddToProjectDialog();
            return;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAndBindRoleList = async () => {
  try {
    const endpoints = new API_ENDPOINTS();
    const response = await API_Tenant.get(
      endpoints.PERMISSION_ROLE_GET_ALL + "/0"
    );

    //console.log("Roles:", response);
    if (response.data.isSuccess === true) {
      const RoleList = response.data.result;
      const role_list_dropdown = RoleList?.filter(
        (r) =>
          r.roleId === 3 ||
          r.roleId === 13 ||
          r.roleId === 14 ||
          r.roleId === 21
      ).map((item) => ({
        label: item.roleName,
        value: item.roleId,
      }));

      return role_list_dropdown;
    } else {
      console.error(response.data.displayMessage);
      return [];
    }
  } catch (err) {
    console.error(err);
    return [];
  }
};
