import { useProfileInfoModal } from "../store";

const useProfileStore = () => {
  const steps = useProfileInfoModal((state) => state.steps);

  const currentStep = steps.find((d) => d?.isActive);

  const key = currentStep?.key || null;

  return { key, currentStep };
};

export default useProfileStore;
