import PROJECT_API from "../../../../../services/api-project";
import { PROFILE_INFOR_ENDPOINTS } from "./profileInfo.endpoints";

export const getProjectMemberRateCards = async (
  __projectMemberId,
  __userId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await PROJECT_API.get(
        `${PROFILE_INFOR_ENDPOINTS.GET_PROJECT_MEMEBER_RATE_CARDS}/${__userId}/${__projectMemberId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const assignRateCardToProjectMember = async (__data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await PROJECT_API.post(
        `${PROFILE_INFOR_ENDPOINTS.ASSIGN_RATE_CARDS_TO_PROJECT_MEMEBER}`,
        __data
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }
      console.log("assignRateCardToProjectMember:: ", res);

      const data = res?.data;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const joinProject = async (__data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await PROJECT_API.post(
        `${PROFILE_INFOR_ENDPOINTS.JOIN_PROJECT}`,
        __data
      );

      if (!res?.data?.isSuccess) {
        resolve(res?.data);
        return;
      }
      console.log("joinProject:: ", res);

      const data = res?.data;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
