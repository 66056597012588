import React, { useState } from "react";

import IconCorrect from "../../../../../../common/components/icons/Fill/correct";
import Button from "../../../../../../common/components/Buttons/UIButton";
import ProjectCard from "./ProjectCard";
import MemberCard from "./MemberCard";
import {
  ONBOARDING_STATUS,
  INVITATION_STATUS,
  MONDATORY_DATA_CHECK_TYPE,
} from "../../../../../../constant/Status";
import { useQueryClient } from "react-query";
import { ProjectBriefDataKey } from "../../index";
import {
  acceptOrRejectInvitation,
  checkMandatoryDetailsIsComplted,
  processNDA,
} from "../../../../api";
import useUserData from "../../../../../../hooks/useUserData";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { openProfileUpdateDialog } from "../../../../../../shared/components/Modals/ProfileUpdateDialog/store";

import useDefaultTemplateDoc, {
  TEMPLATE_DOC_TYPES,
} from "../../../../../../hooks/useDefaultTemplateDoc";
import { useNavigate } from "react-router-dom";
import { showProfileInfoModal } from "../../../../../../shared/components/Modals/ProfileInfoModal/store";
import { setProjectMemberStatus } from "../../../../../../Actions/Project";

const PROJECT_DATA_DENIED_VIEW = [
  ONBOARDING_STATUS.ShortListed,
  ONBOARDING_STATUS.ProjectAdded,
  ONBOARDING_STATUS.InvitationRejected,
  ONBOARDING_STATUS.NDARejected,
  ONBOARDING_STATUS.JoinRequestDeclined,
  ONBOARDING_STATUS.JoinDeclinedIsAcknowledged,
];
const MEMBER_DATA_DENIED_VIEW = [
  ONBOARDING_STATUS.ShortListed,
  ONBOARDING_STATUS.ProjectAdded,
  ONBOARDING_STATUS.InvitationReceived,
  ONBOARDING_STATUS.InvitationAccepted,
  ONBOARDING_STATUS.InvitationRejected,
  ONBOARDING_STATUS.NDAGenerated,
  ONBOARDING_STATUS.NDARejected,
  ONBOARDING_STATUS.NDAChangeRequested,
  ONBOARDING_STATUS.JoinRequestDeclined,
  ONBOARDING_STATUS.JoinDeclinedIsAcknowledged,
];
const DECLINE_VIEW = [
  ONBOARDING_STATUS.InvitationReceived,
  // ONBOARDING_STATUS.NDAGenerated,
  ONBOARDING_STATUS.NDAAccepted,
];
const ACCEPT_INVITAION_VIEW = [ONBOARDING_STATUS.InvitationReceived];
const PROCEED_TO_NDA_VIEW = [
  ONBOARDING_STATUS.InvitationAccepted,
  ONBOARDING_STATUS.NDAGenerated,
];
const JOIN_PROJECT_VIEW = [ONBOARDING_STATUS.NDAAccepted];
const REMIND_ME_VIEW = [
  ONBOARDING_STATUS.InvitationReceived,
  ONBOARDING_STATUS.NDAGenerated,
  ONBOARDING_STATUS.NDAAccepted,
];
const ContentCard = ({ data }) => {
  console.log("ContentCard", data);
  const { userId } = useUserData();
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const noteRef = React.useRef();

  const [invitaion, setInvitaion] = useState(
    data?.projectInvitationLog || null
  );
  const ndaDefaultDoc = useDefaultTemplateDoc({
    templateType: TEMPLATE_DOC_TYPES.NDA,
  });

  const handleDecline = (currentStatus) => {
    switch (currentStatus) {
      case ONBOARDING_STATUS.InvitationReceived: {
        if (data?.projectInvitationLog) {
          acceptOrRejectInvitation(
            data?.projectInvitationLog?.mailReference || "",
            INVITATION_STATUS.Reject,
            ""
          ).then((res) => {
            queryClient.refetchQueries([ProjectBriefDataKey]);
          });
        }

        break;
      }
      case ONBOARDING_STATUS.NDAAccepted: {
        const projectMemberStatusBody = {
          projectMemberId: data?.projectMemberDetails?.projectMemberId || 0,
          projectMemberStatusId: ONBOARDING_STATUS.JoinRequestDeclined,
          contractRequestId: 0,
          contractId: 0,
          contractDocUrl: null,
        };
        showLoader();
        setProjectMemberStatus(projectMemberStatusBody).then(() => {
          queryClient.refetchQueries([ProjectBriefDataKey]);
          showProfileInfoModal();
        });

        break;
      }
      default:
        return;
    }
  };
  const handleAccepts = (currentStatus) => {
    console.log("currentStatus", currentStatus);
    console.log("invitaion", data?.projectInvitationLog);

    switch (currentStatus) {
      case ONBOARDING_STATUS.InvitationReceived: {
        if (data?.projectInvitationLog) {
          showLoader();
          acceptOrRejectInvitation(
            data?.projectInvitationLog?.mailReference || "",
            INVITATION_STATUS.Accept,
            ""
          )
            .then((res) => {
              queryClient.refetchQueries([ProjectBriefDataKey]);
              hideLoader();
            })
            .catch((err) => {
              console.log(err);
              hideLoader();
            });
        }

        break;
      }

      case ONBOARDING_STATUS.NDAAccepted: {
        showProfileInfoModal(data);
        break;
      }
      default:
        return;
    }
  };
  const proceedToNDA = () => {
    console.log("proceedToNDA");
    showLoader();
    var base_url = window.location.origin;

    const payload = {
      projectId: data?.projectDetails?.projectId || 0,
      projectMemberId: data?.projectMemberDetails?.projectMemberId || 0,
      ndaUrl:
        base_url +
        "/confirm-project-member/" +
        data?.projectInvitationLog?.mailReference +
        "/" +
        data?.projectNDALog?.ndaReferenceId,
      senderNote: "",
      templateId: ndaDefaultDoc.templateId || 0,
      userDocumentId: invitaion?.userDocumentId || 0,
      fromDate: "2024-12-16T11:37:05.252Z",
      isProjectDocumentRequired: invitaion?.isProjectDocumentRequired,
    };

    checkMandatoryDetailsIsComplted(userId, MONDATORY_DATA_CHECK_TYPE.NDA)
      .then((res) => {
        if (!res) {
          openProfileUpdateDialog();
          hideLoader();
        } else {
          if (
            data?.projectMemberDetails?.userCurrentStatusIdInProject ===
            ONBOARDING_STATUS.InvitationAccepted
          ) {
            processNDA(payload).then((res) => {
              if (res?.isSuccess) {
                //Navigate to NDA
                hideLoader();
                navigate(
                  "/confirm-project-member/" +
                    data?.projectInvitationLog?.mailReference +
                    "/" +
                    data?.projectNDALog?.ndaReferenceId
                );
              }
            });
            hideLoader();
          } else {
            //Navigate to NDA
            hideLoader();
            navigate(
              "/confirm-project-member/" +
                data?.projectInvitationLog?.mailReference +
                "/" +
                data?.projectNDALog?.ndaReferenceId
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };

  return (
    <div className="css-grid css-gap-28">
      <div className="css-grid css-gap-8">
        {!PROJECT_DATA_DENIED_VIEW.includes(
          data?.projectMemberDetails?.userCurrentStatusIdInProject
        ) ? (
          <ProjectCard
            noteRef={noteRef}
            data={data}
            viewType={data?.projectMemberDetails?.userCurrentStatusIdInProject}
            restictions={MEMBER_DATA_DENIED_VIEW}
          />
        ) : null}

        {!MEMBER_DATA_DENIED_VIEW.includes(
          data?.projectMemberDetails?.userCurrentStatusIdInProject
        ) ? (
          <MemberCard
            noteRef={noteRef}
            projectId={data?.projectDetails?.projectId || 0}
            memberData={data?.projectMemberDetails}
          />
        ) : null}
      </div>

      {/* Actions */}
      <div className="css-flex css-justify-end css-items-center css-gap-8">
        {/*Reminder btn*/}
        {REMIND_ME_VIEW.includes(
          data?.projectMemberDetails?.userCurrentStatusIdInProject
        ) ? (
          <Button
            type="button"
            variant={Button.Variant.Neutral}
            size={Button.Sizes.Small}
          >
            Remind me Later
          </Button>
        ) : null}

        {/*Decline btn*/}
        {DECLINE_VIEW.includes(
          data?.projectMemberDetails?.userCurrentStatusIdInProject
        ) ? (
          <Button
            type="button"
            variant={Button.Variant.Neutral}
            size={Button.Sizes.Small}
            onClick={() =>
              handleDecline(
                data?.projectMemberDetails?.userCurrentStatusIdInProject
              )
            }
          >
            Decline
          </Button>
        ) : null}

        {/* <Button
          type="button"
          variant={Button.Variant.Secondary}
          size={Button.Sizes.Small}
          onClick={() => {
            noteRef.current?.onSend();
          }}
        >
          Change request
        </Button> */}

        {/*Proceed to NDA btn*/}
        {PROCEED_TO_NDA_VIEW.includes(
          data?.projectMemberDetails?.userCurrentStatusIdInProject
        ) ? (
          <Button
            type="button"
            variant={Button.Variant.Primary}
            size={Button.Sizes.Small}
            startIcon={<IconCorrect />}
            onClick={() => proceedToNDA()}
          >
            Proceed to NDA
          </Button>
        ) : null}

        {/*Accept Invitaion btn*/}
        {ACCEPT_INVITAION_VIEW.includes(
          data?.projectMemberDetails?.userCurrentStatusIdInProject
        ) ? (
          <Button
            type="button"
            variant={Button.Variant.Primary}
            size={Button.Sizes.Small}
            startIcon={<IconCorrect />}
            onClick={() =>
              handleAccepts(
                data?.projectMemberDetails?.userCurrentStatusIdInProject
              )
            }
          >
            Accept Invitation
          </Button>
        ) : null}

        {/*Join Project btn*/}
        {JOIN_PROJECT_VIEW.includes(
          data?.projectMemberDetails?.userCurrentStatusIdInProject
        ) ? (
          <Button
            type="button"
            variant={Button.Variant.Primary}
            size={Button.Sizes.Small}
            startIcon={<IconCorrect />}
            onClick={() =>
              handleAccepts(
                data?.projectMemberDetails?.userCurrentStatusIdInProject
              )
            }
          >
            Join Project
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default ContentCard;
