import React from "react";
import Avatar from "../../../../../common/components/UI/Avatar";
import { format, formatDistance, formatDistanceToNow } from "date-fns";

const Item = ({
  comment,
  commenterName,
  commentedDate = new Date(),
  profileImage,
}) => {
  return (
    <div className="css-flex css-gap-8 ">
      <div className="css-min-w-28 css-w-28">
        <Avatar size="size-28" src={profileImage} />
        {/* <Avatar size="size-28" src="https://avatar.iran.liara.run/public/68" /> */}
      </div>

      <div className="css-bg-neutral-10 css-rounded-8 css-px-16 css-py-8">
        <div className="css-flex css-gap-4">
          <div className="css-font-x-small-600 css-text-black">
            {commenterName}
          </div>
          <div className="css-font-x-small-400 css-text-tint-4 css-text-black">
            {formatDistanceToNow(new Date(commentedDate), {
              includeSeconds: false,
            })}
          </div>
        </div>

        {/* Comment */}
        <div className="css-flex">
          <div className="css-font-label-400 css-text-black">{comment}</div>
        </div>
      </div>
    </div>
  );
};

export default Item;
