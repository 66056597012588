export const EditorDocumentType = {
  NA: 0,
  ResearchDump: 1,
  BeatSheet: 2,
  ShotList: 3,
  InterviewNotes: 4,
};

export const DragItemTypes = {
  TagCard: "tagCard",
};

export const LOCK_STATUS = {
  Free: 1,
  created: 2,
  Extended: 3,
  Locked: 4,
  Error: 5,
};

export const RESEARCH_FILED_DISPLAY_TYPE = {
  NA: 0,
  TITLE: 1,
  SUBTITLE: 2,
  DISCRIPTION: 3,
  VISUAL: 4,
};

export const RESEARCH_TAG_VISUAL_TYPE = {
  NA: 0,
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  LOCATION: 4,
  SLIDER: 5,
  EMPTY: 100,
};

export const RESEARCH_TAG_WHO = {
  AUTHOR: "Author",
  EDITOR: "Editor",
};

export const RESEARCH_FILED_DISPLAY_NAME = {
  DESIGNATION: "Designation",
  ORGANIZATION: "Organisation",
  ROLE: "Role",
  AUTHORITY: "Authority",
  AUTHORITY_ORGANIZATION: "Authority / Organisation",
  CONTACT_PERSON: "Contact Person (Signatory)",
};
export const RESEARCH_TAG_TYPE = {
  Contributor: 1,
  TALENT: 2,
  ACCESS: 4,
  ARCHIVE: 5,
  COMPLIANCE: 8,
};

