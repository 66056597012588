import * as React from "react";
import { memo } from "react";
const SvgPdf = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Fill/pdf">
      <g id="icon">
        <path
          d="M8.56565 8.40533H8.22982V10.888H8.55753C8.64087 10.8893 8.72083 10.8553 8.77691 10.7946C8.83469 10.7372 8.86685 10.6595 8.86629 10.5786V8.71195C8.86747 8.62967 8.83418 8.55056 8.7742 8.49328C8.71936 8.43778 8.64431 8.40616 8.56565 8.40533Z"
          fill="currentColor"
        />
        <path
          d="M6.63732 9.45606C6.69701 9.39862 6.7303 9.31962 6.7294 9.2374V8.70406C6.73329 8.62129 6.70299 8.54051 6.64544 8.48006C6.58501 8.42156 6.50258 8.39067 6.41794 8.39473H6.08753V9.54673H6.41524C6.49892 9.54856 6.57948 9.51567 6.63732 9.45606Z"
          fill="currentColor"
        />
        <path
          d="M14.7291 6.66666H14.4583V3.45066C14.4569 3.42705 14.4523 3.40377 14.4447 3.38133V3.35733C14.4322 3.32878 14.4149 3.30261 14.3933 3.28L11.1433 0.0799999C11.1203 0.0587221 11.0937 0.0416113 11.0647 0.0293333H11.0403C11.0134 0.0147783 10.984 0.00483271 10.9537 0H2.8125C2.74068 0 2.67178 0.0281116 2.621 0.0781112C2.57022 0.128111 2.54167 0.195945 2.54167 0.266669V6.66666H2.27084C2.19901 6.66666 2.13012 6.69478 2.07933 6.74478C2.02855 6.79478 2 6.86261 2 6.93333V12.2667C2 12.3374 2.02855 12.4052 2.07933 12.4552C2.13011 12.5052 2.19901 12.5333 2.27084 12.5333H2.54167V15.7333C2.54167 15.8041 2.57022 15.8719 2.621 15.9219C2.67178 15.9719 2.74068 16 2.8125 16H14.1875C14.2593 16 14.3282 15.9719 14.379 15.9219C14.4298 15.8719 14.4583 15.8041 14.4583 15.7333V12.5333H14.7292C14.801 12.5333 14.8699 12.5052 14.9207 12.4552C14.9714 12.4052 15 12.3374 15 12.2667V6.93333C15 6.86261 14.9714 6.79478 14.9207 6.74478C14.8699 6.69478 14.8009 6.66666 14.7291 6.66666ZM11.2083 0.909368L13.5347 3.2H11.2083V0.909368ZM13.9166 15.4672H3.08327V12.5339H13.9166V15.4672ZM5.53969 11.4193V7.85934H6.39823C6.63577 7.84895 6.86649 7.93923 7.03198 8.10734C7.18128 8.2729 7.25987 8.48878 7.25137 8.71001V9.22734C7.25515 9.45329 7.16515 9.67095 7.0022 9.83001C6.84556 9.99334 6.62635 10.0843 6.39825 10.0807H6.0868V11.414L5.53969 11.4193ZM7.69017 11.4193V7.85663H8.54601C8.77508 7.85224 8.99536 7.94324 9.15267 8.1073C9.3155 8.26541 9.40551 8.48218 9.40184 8.70731V10.574C9.40522 10.8004 9.31421 11.0182 9.14996 11.1766C8.99264 11.3407 8.77236 11.4317 8.54329 11.4273L7.69017 11.4193ZM11.1649 9.3686V9.90193H10.4283V11.4139H9.88659V7.85663H11.4385V8.38997H10.4283V9.36063L11.1649 9.3686ZM13.9166 6.66723H3.08327V0.533902H10.6666V3.46723C10.6666 3.53796 10.6951 3.60579 10.7459 3.65579C10.7967 3.70579 10.8656 3.7339 10.9374 3.7339H13.9166L13.9166 6.66723Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const Memo = memo(SvgPdf);
export default Memo;
