import styled from "styled-components";
import HeaderSlide from "./DatePickerHeader/HeaderSlide";

export const UIDatePickerWrapper = styled.div`
  position: relative;

  .rdp {
    --rdp-cell-size: 35px;
    --rdp-max-width: calc(var(--rdp-cell-size) * 7);
    /*--rdp-accent-color: #0000ff;
    --rdp-background-color: #e7edff;
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 3px solid var(--rdp-accent-color); */
    margin: 0;

    .rdp-weeknumber,
    .rdp-day {
      border-radius: 4px;
    }

    .rdp-weeknumber,
    .rdp-day {
      font-size: ${({ theme }) => theme.fonts.textXSmall};
      color: ${({ theme }) => theme.colors.primary_light()};
    }

    .rdp-weeknumber {
      /* background-color: ${({ theme }) => theme.colors.color_1(20)}; */
      color: ${({ theme }) => theme.colors.color_1(30)};
    }

    .rdp-head_cell {
      font-size: ${({ theme }) => theme.fonts.textXSmall};
      color: #949aa6; //${({ theme }) => theme.colors.color_1()};
    }

    .rdp-day_selected {
      background-color: ${({ theme }) => theme.colors.primary()};
      color: ${({ theme }) => theme.colors.white()};
    }
  }

  .day--na {
    background-image: url("/assets/images/datepicker/na-date.png");
  }

  .day--pub {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-image: url("/assets/images/datepicker/star.svg");
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .day--con {
    background-color: ${({ theme }) => theme.colors.primary_light()};
    color: ${({ theme }) => theme.colors.white()} !important;
  }

  .day--tent {
    background-color: var(--font-color-shade-info);
    color: var(--font-default-white) !important;
  }
  .day--pref {
    background-color: var(--font-color-shade-info);
    color: var(--font-default-white) !important;
  }
`;

export const UIDatePickerFooter = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  margin-top: ${({ theme }) => theme.spacing(2)};

  .rdp__day {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: ${({ theme }) => theme.fonts.textXSmall};
    color: ${({ theme }) => theme.colors.primary_light()};
    line-height: 1;
  }

  .day__na {
    background-image: url("/assets/images/datepicker/na-date.png");
  }

  .day__pub {
    display: flex;
    align-items: center;
    border: 1px solid #d4d6db;

    i {
      background-image: url("/assets/images/datepicker/star.svg");
      display: inline-flex;
      width: 9px;
      height: 9px;
      margin-right: 2px;
    }
  }
  
  .day__pref {
    display: flex;
    align-items: center;
    background-color: #f1967b;
    color: ${({ theme }) => theme.colors.white()} !important;
  }

  .day__con {
    background-color: ${({ theme }) => theme.colors.primary_light()};
    color: ${({ theme }) => theme.colors.white()} !important;
  }

  .day__tent {
    background-color: var(--font-color-shade-info);
    color: var(--font-default-white) !important;
  }
`;

export const UIDatePickerHeader = styled.div`
  display: block;
`;

export const YearSlider = styled(HeaderSlide)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.color_1(20)};

  .year__item {
    font-size: ${({ theme }) => theme.fonts.textLabel};
    color: ${({ theme }) => theme.textColors.primary(40)};
    line-height: 1;
    height: ${({ theme }) => theme.sizes(5)};

    &.selected {
      font-size: ${({ theme }) => theme.fonts.textH6};
      color: ${({ theme }) => theme.colors.primary()};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }
  }
`;

export const MonthSlider = styled(HeaderSlide)`
  .month-slide-item {
    width: auto;
  }

  .month__item {
    font-size: ${({ theme }) => theme.fonts.textLabel};
    color: ${({ theme }) => theme.textColors.primary(40)};
    line-height: 1;
    height: ${({ theme }) => theme.sizes(6)};
    border-radius: ${({ theme }) => theme.rounded("sm")};
    padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
    display: flex;
    align-items: center;
    justify-content: center;

    &.selected {
      background-color: ${({ theme }) => theme.colors.primary_light()};
      color: ${({ theme }) => theme.colors.white()};
    }
  }
`;
