import * as React from "react";
import { memo } from "react";
const Svg04 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.2041 12.0382H2.61228C2.37278 12.0382 2.17687 12.2341 2.17687 12.4735C2.17687 12.7347 2.37278 12.9306 2.61228 12.9306H6.2041C6.44359 12.9306 6.66126 12.7347 6.66126 12.4735C6.66126 12.2341 6.44359 12.0382 6.2041 12.0382Z"
      fill="currentColor"
    />
    <path
      d="M6.2041 9.33887H2.61228C2.37278 9.33887 2.17687 9.53459 2.17687 9.79599C2.17687 10.0354 2.37278 10.2313 2.61228 10.2313H6.2041C6.44359 10.2313 6.66126 10.0354 6.66126 9.79599C6.66126 9.53459 6.44359 9.33887 6.2041 9.33887Z"
      fill="currentColor"
    />
    <path
      d="M12.4736 3.96182H2.61228C2.37278 3.96182 2.17687 4.15778 2.17687 4.41894C2.17687 4.65834 2.37278 4.8543 2.61228 4.8543H12.4736C12.7348 4.8543 12.9307 4.65834 12.9307 4.41894C12.9307 4.15778 12.7348 3.96182 12.4736 3.96182Z"
      fill="currentColor"
    />
    <path
      d="M12.4736 6.66114H2.61228C2.37278 6.66114 2.17687 6.85705 2.17687 7.09649C2.17687 7.35783 2.37278 7.55379 2.61228 7.55379H12.4736C12.7348 7.55379 12.9307 7.35783 12.9307 7.09649C12.9307 6.85705 12.7348 6.66114 12.4736 6.66114Z"
      fill="currentColor"
    />
    <path
      d="M14.977 1.00117C14.3675 0.391862 13.5185 0 12.5607 0H3.43949C2.48163 0 1.63265 0.391862 1.0014 1.00117C0.391862 1.63265 0 2.48159 0 3.43932V12.5605C0 13.4966 0.391862 14.3673 1.0014 14.9769C1.63265 15.6081 2.48163 16 3.43949 16H12.5607C13.5185 16 14.3675 15.6081 14.977 14.9769C15.6083 14.3673 16.0001 13.4966 16.0001 12.5605V3.43932C16.0001 2.48159 15.6083 1.63265 14.977 1.00117ZM15.1076 12.5605C15.1076 13.2572 14.8029 13.8885 14.3457 14.3456C13.8886 14.8027 13.2572 15.0858 12.5607 15.0858H3.43949C2.74289 15.0858 2.11159 14.8027 1.63265 14.3456C1.17553 13.8885 0.892503 13.2572 0.892503 12.5605V3.43932C0.892503 2.7428 1.17553 2.08973 1.63265 1.63265C2.11159 1.17536 2.74289 0.892411 3.43949 0.892411H12.5607C13.2572 0.892411 13.8886 1.17536 14.3457 1.63265C14.8029 2.08973 15.1076 2.7428 15.1076 3.43932V12.5605Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg04);
export default Memo;
