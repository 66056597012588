import React from "react";
import DocumentViewer from "@txtextcontrol/tx-react-ds-document-viewer";
import { useQuery } from "react-query";

import { getDocumentBase64ByDocId } from "../../../Actions/Document";
import useUserData from "../../../hooks/useUserData";
import { DocWrapper } from "./styled";

const ViewDocument = ({
  onDocumentLoad,
  documentId,
  signatureSettings,
  onDocumentLoaded,
}) => {
  const containerRef = React.useRef();
  const containerRectRef = React.useRef();
  const [isDocumentViewerLoaded, setDocumentViewerLoaded] =
    React.useState(false);

  const { userInfo } = useUserData();

  const onDocumentViewLoad = () => {
    window.addEventListener("documentViewerLoaded", () => {
      setDocumentViewerLoaded(true);
      onDocumentLoad && onDocumentLoad(window.TXDocumentViewer);

      window.TXDocumentViewer.toggleToolbar();
      window.TXDocumentViewer.toggleSidebar();

      console.log("TXDocumentViewer: ", window.TXDocumentViewer);
    });
  };

  React.useEffect(() => {
    if (containerRef?.current) {
      const react = containerRef?.current?.getBoundingClientRect();
      containerRectRef.current = {
        height: `${Math.floor(react.height) - 1}px`,
      };
    }
  }, []);

  useQuery({
    queryKey: ["VIEW_DOCUMENT", documentId],
    queryFn: () => getDocumentBase64ByDocId(documentId),
    enabled: !!documentId && isDocumentViewerLoaded,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    onSuccess: (data) => {
      if (!data?.isSuccess) {
        return;
      }

      window.TXDocumentViewer.loadDocument(
        data?.result,
        "View Document",
        signatureSettings
      ).then((isDone) => {
        onDocumentLoaded && onDocumentLoaded(window.TXDocumentViewer, isDone);
        // ;
      });
    },
  });

  return (
    <DocWrapper className="doc_wrapper" ref={containerRef}>
      <DocumentViewer
        width="100%"
        height={containerRectRef.current?.height}
        serviceURL={process.env.REACT_APP_TXTEXTCONTROLLER_SERVICE_URL}
        clientId={process.env.REACT_APP_TXTEXTCONTROLLER_DOCVIEWER_CLIENT_ID}
        clientSecret={
          process.env.REACT_APP_TXTEXTCONTROLLER_DOCVIEWER_CLIENT_SECRET
        }
        onLoad={onDocumentViewLoad}
        toolbarDocked={false}
        userNames={[userInfo.email]}
      />
    </DocWrapper>
  );
};

export default ViewDocument;
