import React from "react";
import { BaseNotesViewDialog } from "../dialog.styled";
import NoteBoard from "../../../../../shared/components/NoteBoard";
import Icon from "../../../../../common/components/UI/Icon";
import IconNote from "../../../../../common/components/icons/Line/Notes/04";
import { useNotesViewDialog, hideNotesDialog } from "./store";
import { useGlobalStore } from "../../../../../store/GlobalStore";

const NotesViewDialog = () => {
  const visibility = useNotesViewDialog((state) => state.visibility);
  const project = useGlobalStore((state) => state.selectedProject);
  console.log("project Note", project);
  //const meta = useNotesViewDialog((state) => state.meta);

  return (
    <BaseNotesViewDialog
      visibility={visibility}
      title={"Notes - " + project?.projectDetails?.projectName || ""}
      subTitle="View your note details here"
      bodyComponent={({ props }) => <BodyComponent {...props} />}
      //   onRootClose={}
      onClose={hideNotesDialog}
    />
  );
};

function BodyComponent(props) {
  const meta = useNotesViewDialog((state) => state.meta);

  return (
    <div className="css-border css-border-solid css-border-primary-75 css-rounded-b-8 css-px-20 css-py-16">
      <div className="css-flex css-gap-16">
        <div className="css-w-28 css-h-28 css-text-secondary-300">
          <Icon
            icon={<IconNote />}
            iconSize={28}
            size={28}
            inheritParentColor
          />
        </div>
        <div className="css-flex-1">
          <NoteBoard
            noteMarkAsReadOnMounted
            projectId={meta?.projectId || 0}
            projectMemberId={meta?.memberId || 0}
            // hideTextArea
          />
        </div>
      </div>
    </div>
  );
}

export default NotesViewDialog;
