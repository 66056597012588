import DocumentEditor from "@txtextcontrol/tx-react-ds-document-editor";
import { TextControllerConfigs } from "../../constant";
import React from "react";
import {
  addUniqueItem,
  getContextMenuItems,
  readDocumentFromLocal,
} from "../../services/TXTextController";
import {
  getAllTemplates,
  getFormFields,
} from "../../../../../../Actions/Templates";
import API_ENDPOINTS from "../../../../../../services/api-endpoints";
import API_Template from "../../../../../../services/api-template";
import useUserData from "../../../../../../hooks/useUserData";
import { DocumentEditorContainer } from "./styled";
import Alert from "../../../../../../utility/alert";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";

let TEMPLATES = [];
let TEMPLATE_TYPES = [];

const TextControllerEditor = ({
  templateStateRef,
  editorStateRef,
  setEditorState,
  mergeData,
  handleNewDoc,
  handleRead,
  handleSave,
  handleUpdate,
}) => {
  const USER = useUserData();
  const navigate = useNavigate();
  const [formFields, setFormFields] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const JOB_DESCRIPTION = 10;

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const userjobTitles = useAppStore((state) => state.jobTitles);

  const getTemplateTypeList = React.useCallback(async () => {
    try {
      const endpoints = new API_ENDPOINTS();
      const response = await API_Template.get(
        endpoints.TEMPLATE_GET_TEMPLATE_TYPE
      );

      if (response.data.isSuccess === true) {
        console.log("Template type response: ", response);
        TEMPLATE_TYPES = response.data.result.map((item) => ({
          templateTypeId: item.tenantTemplateTypeId,
          templateTypeName: item.templateTypeName,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const switchTabs = (tabObject) => {
    // Hide all tab contents
    var tabContents = document.querySelectorAll(
      "#txRibbonTabContentContainer div.tab-content"
    );
    tabContents.forEach((tabContent) => {
      tabContent.style.display = "none";
    });

    // Remove (selected) class from all tab links
    var ulTabs = document.querySelector("ul.tabs");
    var aElements = ulTabs.querySelectorAll("li a");
    aElements.forEach((aElement) => {
      aElement.classList.remove("selected");
    });

    var tabLinks = ulTabs.querySelectorAll("li a div");
    tabLinks.forEach((tabLink) => {
      tabLink.classList.remove("selected");
    });

    // Show the selected tab content and mark the selected tab link
    var selectedAnchor = tabObject.querySelector("a");
    selectedAnchor.classList.add("selected");
    var divElement = selectedAnchor.querySelector("div");
    var id = selectedAnchor.getAttribute("rel");
    var relElement = document.getElementById(id);
    relElement.style.display = "inline-block";
    divElement.classList.add("selected");
  };

  const closeSaveDocumentDialog = () => {
    var modalContainer = document.querySelector(
      ".saveDocumentDialogModalContainer"
    );
    if (modalContainer) {
      modalContainer.remove();
    }
  };

  const fetchTemplates = async () => {
    try {
      await getAllTemplates().then((docs) => {
        const templates = docs.map((doc) => doc);
        TEMPLATES.push(...templates);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTextControlLoaded = () => {
    hideLoader();
    window.TXTextControl.setStatusBarColor("#245A6C");
    window.TXTextControl.showStatusBar(false);
    window.TXTextControl.showVerticalRuler(false);
    window.TXTextControl.showHorizontalRuler(false);

    var selection = window.TXTextControl.selection;
    selection.setCulture(TextControllerConfigs.culture);

    window.TXTextControl.addEventListener(
      "ribbonTabLoaded",
      handleRibbonTabLoaded
    );
  };

  const handleContextMenuOpening = (e) => {
    const contextMenuItems = getContextMenuItems(formFields);
    contextMenuItems.forEach((item) => addUniqueItem(e.items, item));
  };

  const handleRibbonTabsLoaded = () => {
    setEditorState((prevState) => ({
      ...prevState,
      isEditorLoaded: true,
    }));
    editorStateRef.current = {
      ...editorStateRef.current,
      isEditorLoaded: true,
    };
    window.TXTextControl.sideBarToggleButton.setAllowedSideBars(
      window.TXTextControl.SideBarType.FieldNavigator |
        window.TXTextControl.SideBarType.Comments
    );
    window.TXTextControl.clipboardMode =
      window.TXTextControl.ClipboardMode.Client;
    window.TXTextControl.setIsSpellCheckingEnabled(true);

    // Fetch and add the new tab container after the existing last tab
    fetch("/TXTextControl/fileRibbonTabContainer.html")
      .then((response) => response.text())
      .then((data) => {
        var ulTabs = document.querySelector("ul.tabs");
        var firstLi = ulTabs.firstElementChild;
        var newLi = document.createElement("li");
        newLi.id = "li_tabFile";
        newLi.style.opacity = "1";
        newLi.style.pointerEvents = "initial";
        newLi.innerHTML = data;
        ulTabs.insertBefore(newLi, firstLi);

        // Attach the click event to switch tabs on a click
        newLi.addEventListener("click", function () {
          switchTabs(this);
        });

        ulTabs.addEventListener("click", (e) => {
          window.TXTextControl.ribbon.expand();
        });

        ulTabs.addEventListener("dblclick", (e) => {
          window.TXTextControl.ribbon.minimize();
        });
      });

    // Fetch and add the ribbon File tab content to the content container
    fetch("/TXTextControl/fileRibbonTabContent.html")
      .then((response) => response.text())
      .then((data) => {
        var txRibbonTabContentContainer = document.getElementById(
          "txRibbonTabContentContainer"
        );
        var lastDiv = txRibbonTabContentContainer.lastElementChild;
        var newDiv = document.createElement("div");
        newDiv.innerHTML = data;
        txRibbonTabContentContainer.insertBefore(newDiv, lastDiv.nextSibling);

        var btnSaveButton = newDiv.querySelector("#btnSaveButton");
        var btnImportButton = newDiv.querySelector("#btnImportButton");
        var btnSaveAndCloseButton = newDiv.querySelector(
          "#btnSaveAndCloseButton"
        );

        btnSaveAndCloseButton.classList.add("txui-state-disabled");
        if (templateStateRef.current.isEditMode) {
          btnSaveButton.classList.add("txui-state-disabled");
          btnImportButton.classList.add("txui-state-disabled");
        } else {
          btnSaveButton.classList.remove("txui-state-disabled");
          btnImportButton.classList.remove("txui-state-disabled");
        }

        btnImportButton.addEventListener("click", (e) => {
          console.log("Import Document...!");
          var input = document.createElement("input");
          input.type = "file";
          input.click();
          input.addEventListener("change", (event) => {
            readDocumentFromLocal(event);
          });
        });

        btnSaveAndCloseButton.addEventListener("click", (e) => {
          console.log("Save and Close Document...!");
          handleUpdate({ close: true });
        });
        return fetch(
          "/TXTextControl/SaveDocumentDialog/saveDocumentDialog.html"
        );
      })
      .then((response) => response.text())
      .then((modalDialogContent) => {
        var saveButton = document.querySelector("#btnSaveButton");
        saveButton.addEventListener("click", () => {
          if (templateStateRef.current.isSaved) {
            handleUpdate({});
          } else {
            console.log("Save Document button clicked!");

            // Create a modal container element
            var modalContainer = document.createElement("div");
            modalContainer.className = "saveDocumentDialogModalContainer";
            modalContainer.innerHTML = modalDialogContent;
            document.body.appendChild(modalContainer);

            var selectElement = document.getElementById(
              "saveDocumentDialog_selTemplateType"
            );
            var jobTitleSelectElement = document.getElementById(
              "saveDocumentDialog_selJobTitle"
            );
            TEMPLATE_TYPES.forEach((templateType) => {
              var option = document.createElement("option");
              option.value = templateType.templateTypeId;
              option.textContent = templateType.templateTypeName;
              selectElement.appendChild(option);
            });

            //Add event listner and function to Template Type dropdown
            selectElement.addEventListener("change", () => {
              var templateType = document.getElementById(
                "saveDocumentDialog_selTemplateType"
              ).value;
              if (Number(templateType) === JOB_DESCRIPTION) {
                jobTitleSelectElement.value = "0";
                document
                  .getElementById("saveDocumentDialog_selJobTitle_tr")
                  .removeAttribute("hidden");
              } else {
                jobTitleSelectElement.value = "0";
                document
                  .getElementById("saveDocumentDialog_selJobTitle_tr")
                  .setAttribute("hidden", "");
              }
            });
            //Populate Job titles
            userjobTitles.forEach((jobTitle) => {
              var option = document.createElement("option");
              option.value = jobTitle.value;
              option.textContent = jobTitle.label;
              jobTitleSelectElement.appendChild(option);
            });

            document
              .getElementById("btnClose_dialogContainer_close")
              .addEventListener("click", closeSaveDocumentDialog);

            document
              .getElementById("btnSave_dialogContainer_document_save")
              .addEventListener("click", () => {
                var templateName = document.getElementById(
                  "saveDocumentDialog_txtFieldTemplateName"
                ).value;
                var templateTypeId = document.getElementById(
                  "saveDocumentDialog_selTemplateType"
                ).value;
                var jobTitleId = document.getElementById(
                  "saveDocumentDialog_selJobTitle"
                ).value;
                var nameRegex =
                  /^(?![_\s-])[a-zA-Z][a-zA-Z0-9_\/()[\]{}#@!?\$]*(?:[a-zA-Z0-9]+(?:[_\s-][a-zA-Z0-9]+)*)?$/;
                if (!templateName) {
                  Alert.confirm({
                    allowOutsideClick: false,
                    title: "Required!",
                    text: "Template Name is required.",
                    confirmButtonText: "OK",
                    showCancelButton: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      console.log("yes");
                    }
                  });
                } else if (!nameRegex.test(templateName)) {
                  let errorMessage = "Template Name is invalid.";

                  if (templateName.trim() === "") {
                    errorMessage = "Name cannot be empty.";
                  } else if (
                    templateName.startsWith(" ") ||
                    templateName.endsWith(" ")
                  ) {
                    errorMessage = "Name cannot start or end with a space.";
                  } else if (templateName.match(/^[^a-zA-Z]/)) {
                    errorMessage = "Name must start with a letter.";
                  } else if (!templateName.match(/[a-zA-Z]/)) {
                    errorMessage = "Name must contain at least one letter.";
                  } else if (templateName.match(/\s{2,}/)) {
                    errorMessage =
                      "Name cannot have more than one consecutive space.";
                  }

                  Alert.confirm({
                    allowOutsideClick: false,
                    title: "Invalid!",
                    text: errorMessage,
                    confirmButtonText: "OK",
                    showCancelButton: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      console.log("yes");
                    }
                  });
                } else if (!templateTypeId) {
                  Alert.confirm({
                    allowOutsideClick: false,
                    title: "Required!",
                    text: "Template Type is required",
                    confirmButtonText: "OK",
                    showCancelButton: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      console.log("yes");
                    }
                  });
                } else if (
                  Number(templateTypeId) === JOB_DESCRIPTION &&
                  !jobTitleId
                ) {
                  Alert.confirm({
                    allowOutsideClick: false,
                    title: "Required!",
                    text: "Job Title is required",
                    confirmButtonText: "OK",
                    showCancelButton: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      console.log("yes");
                    }
                  });
                } else {
                  handleSave({ templateName, templateTypeId, jobTitleId });
                  closeSaveDocumentDialog();
                }
              });

            document
              .getElementById("btnCancel_dialogContainer_document_save")
              .addEventListener("click", closeSaveDocumentDialog);
          }
        });
      });

    // Fetch and add the ribbon Home tab new home content to the content container
    fetch("/TXTextControl/HomeTab/updateButtonContent.html")
      .then((response) => response.text())
      .then((content) => {
        var updateButton = document.createElement("div");
        updateButton.id = "updateButtonId";
        updateButton.className = "ribbon-button ribbon-button-big";
        updateButton.innerHTML = content;

        updateButton.addEventListener("click", function () {
          if (templateStateRef.current.isEditMode) {
            handleUpdate({});
          }
        });

        if (templateStateRef.current.isEditMode) {
          updateButton.classList.remove("txui-state-disabled");
        } else {
          updateButton.classList.add("txui-state-disabled");
        }

        var clientPasteButton = document.getElementById(
          "ribbonTabHome_tglBtnClientPaste"
        );
        var container = clientPasteButton.parentNode;
        container.insertBefore(updateButton, clientPasteButton);
      });
  };

  const handleRibbonTabLoaded = (e) => {
    const ribbonbar = document.getElementById("ribbonbar");
    if (ribbonbar) {
      // Select the tabs you want to remove
      const tabsToRemove = [
        "li_tabReports",
        "li_tabReferences",
        "li_tabPermissions",
        "li_tabFormFields",
      ];
      tabsToRemove.forEach((tabId) => {
        const tab = document.getElementById(tabId);
        if (tab) {
          tab.remove();
        }
      });
    }
  };

  const handleWebSocketClosed = (e) => {
    window.TXTextControl.removeFromDom();
    window.location.reload(true);
  };

  const EVENT_LISTENERS = [
    { event: "textControlLoaded", handler: handleTextControlLoaded },
    { event: "contextMenuOpening", handler: handleContextMenuOpening },
    { event: "ribbonTabsLoaded", handler: handleRibbonTabsLoaded },
    { event: "webSocketClosed", handler: handleWebSocketClosed },
  ];

  const handleDocumentEditorLoad = () => {
    EVENT_LISTENERS.forEach(({ event, handler }) => {
      window.TXTextControl.addEventListener(event, handler);
    });
  };

  const handleCleanUpDOM = () => {
    EVENT_LISTENERS.forEach(({ event, handler }) => {
      window.TXTextControl.removeEventListener(event, handler);
    });
    window.TXTextControl.removeFromDom();
  };

  React.useEffect(() => {
    getTemplateTypeList();
    getFormFields().then((result) => {
      setFormFields(result);
      setIsLoading(false);
    });
  }, [getTemplateTypeList]);

  React.useEffect(() => {
    showLoader();
    const isRefreshed = localStorage.getItem("isRefreshed");
    if (!isRefreshed) {
      localStorage.setItem("isRefreshed", "true");
      navigate(0);
    } else {
      localStorage.removeItem("isRefreshed");
      fetchTemplates();
    }
    return () => {
      handleCleanUpDOM();
    };
  }, []);

  return (
    <DocumentEditorContainer>
      {!isLoading && (
        <DocumentEditor
          style={TextControllerConfigs.style}
          serviceURL={process.env.REACT_APP_TXTEXTCONTROLLER_SERVICE_URL}
          onLoad={handleDocumentEditorLoad}
          authSettings={{
            clientId: process.env.REACT_APP_TXTEXTCONTROLLER_CLIENT_ID,
            clientSecret: process.env.REACT_APP_TXTEXTCONTROLLER_CLIENT_SECRET,
          }}
          reconnectTimeout={20}
          userNames={[USER.userInfo.email]}
          uiCulture={TextControllerConfigs.uiCulture}
        ></DocumentEditor>
      )}
    </DocumentEditorContainer>
  );
};

export default TextControllerEditor;
