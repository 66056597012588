export const SETTINGS_ENDPOINTS = {
  SETUP_TENANT_CLIENT: "/Tenant/Client_Create",
  GET_ALL_TENANT_CLIENTS: "/Tenant/Client_GetAll",
  GET_TENANT_CLIENT_BY_ID: "/Tenant/Client_GetById",
  SETUP_TENANT_FULL_CLIENT_PROFILE:
    "/Tenant/Client_Create_Update_Region_Country_Update",
  GET_ALL_REGIONS: "/Global/Region_GetAll",
  GET_REGION_CONTRIES_BY_REGION_ID: "/Global/RegionCountry_GetById",
  //Company
  GET_COMPANY_BY_ID: "/Tenant/Company_GetById",
  UPDATE_GLOBLE_COMPANY: "/Global/Company_Update",
  UPDATE_TANENT_COMPANY: "/Tenant/Company_Update",
  GET_ALL_DEPARTMENTs: "/Tenant/Department_GetAll",
  SETUP_DEPARTMENT: "/Tenant/Department_Create",
  UPDATE_DEPARTMENT: "/Tenant/Department_Update",
  //Project
  PROJECT_UPDATE: "/Project/Project_Update",
  PROJECT_GET_BY_ID: "/Project/ProjectGetById",
  GET_ALL_JOB_TITLES: "/Global/JobTitle_GetAll",
  //Rate Card
  SAVE_RATE_CARD: "/Global/Rate_Card",
  GET_RATE_CARD: "/Global/Rate_Card",
  GET_RATE_CARD_UNITS: "/Global/RateCardUnit_GetAll",
  GET_ALL_CURRENCIES: "/Global/Currency_GetAll",
  //Personal Data
  GET_PERSONAL_DATA: "/Global/Profile_GetByUserId",
  UPDATE_PERSONAL_DATA: "/Global/Profile_Update",
  //My Profile
  GET_MY_PROFILE: "/Global/UserProfile",
  SAVE_MY_PROFILE: "/Global/UserProfile",
  //Identification
  GET_IDENTIFICATIONS_TYPES: "/Global/IdentificationType/all",
  GET_IDENTIFICATIONS_BY_USERID: "/Global/Identification",
  SAVE_IDENTIFICATION: "/Global/Identification",
  DELETE_IDENTIFICATION: "/Global/Identification",
  //Business Registration
  GET_BUSINESS_REGISTRATION_BY_USER: "/Global/BusinessRegistration",
  SAVE_BUSINESS_REGISTRATION_BY_USER: "/Global/BusinessRegistration",
  DELETE_BUSINESS_REGISTRATION: "/Global/BusinessRegistration",
  //Visa Information
  GET_VISA_INFORMATION_BY_USER: "/Global/UserVisaInfo",
  SAVE_VISA_INFORMATION: "/Global/UserVisaInfo",
  DELETE_VISA_INFORMATION: "/Global/UserVisaInfo",
  GET_VISA_TYPES: "/Global/VisaType/all",
  GET_IDENTIFICATIONS_BY_USER_AND_TYPE: "/Global/Identification",
  //Subscriptions
  GET_USER_SUBSCRIPTION_BY_USER: "/Global/Subscription",
  SAVE_USER_SUBSCRIPTION: "/Global/Subscription",
  //Invoicing
  GET_INVOICING_DATA_BY_USER: "/Global/Invoicing",
  SAVE_USER_INVOICING: "/Global/Invoicing",
  //FreqFlyer
  GET_FREQ_FLYER_DATA_BY_USER: "/Global/FreqFlyer",
  SAVE_FREQ_FLYER_DATA: "/Global/FreqFlyer",
  GET_FREQ_FLYER_MEMBERSHIPS: "/Global/FreqFlyerMembership/all",
  DELETE_FREQ_FLYER: "/Global/FreqFlyer",
  //System Preferences
  GET_USER_SYSTEM_PREF_BY_USER: "/Global/SystemPreference",
  SAVE_USER_SYSTEM_PREF: "/Global/SystemPreference",
  GET_ALL_DATE_FORMATES: "/Global/DateFormat/all",
  GET_ALL_TIME_FORMATES: "/Global/TimeFormat/all",
  GET_ALL_LANGUAGES: "/Global/Language_GetAll",
  //Health Declarations
  GET_USER_HEALTH_DECLARATION_BY_USER: "/Global/HealthDeclaration",
  SAVE_USER_HEALTH_DECLARATION: "/Global/HealthDeclaration",
  //Health Declarations - Emergency Conatct Person
  GET_USER_EMERGENCY_CONATCT_PERSON_BY_USER: "/Global/EmergencyContact",
  SAVE_USER_EMERGENCY_CONATCT: "/Global/EmergencyContact",
  GET_ALL_RELATIONSHIPS: "/Global/Relationship/all",
  DELETE_USER_EMERGENCY_CONATCT: "/Global/EmergencyContact",
};
