import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getNDADetails2,
  submitInvitation,
  submitNDA,
} from "../../../Actions/NDA";
import { initialState, ndaReducer, ActionTypes } from "../../../reducers/nad";
import { useProfileMissedFields } from "../../../hooks/useProfileMissedFields";
import { PublicRoutePath } from "../../../routes/paths/public-path";
import { getDocumentSFDTByDocId } from "../../../Actions/Document";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { useAppStore } from "../../../store/AppStore/AppStore";

import Container from "../../ui-components/Container";
import DocumentViewNDA from "../../ui-components/DocumentViewNDA";
import FloatActionBar from "../../ui-components/Container/FloatActionBar";
import withProfileUpdateAlert from "../../../hoc/withProfileUpdateAlert";
import useGeneratePath from "../../../hooks/useGeneratePath";
import AlertDialog from "../../ui-components/Dialog/AlertDialog";
import Button, { BUTTON_ICONS } from "../../ui-components/Buttons/Button";
import Alert from "../../../utility/alert";
import useUserData from "../../../hooks/useUserData";

import "../../assets/css/onboarding_nda.e0590c766de10bda39a989cf382a3cae.css";
import TextControllerViewer from "../../../pages/Template/components/TXTextController/components/TextControllerViewer";
import { getProjectMembersListByProjectId } from "../../../Actions/Project";
import { ONBOARDING_STATUS } from "../../../constant/Status";
import NoteBoard from "../../../shared/components/NoteBoard";
import UIButton from "../../../common/components/Buttons/UIButton";
import ViewDocument from "../../../common/components/EditorDocuments/ViewDocument";

const useMemberData = ({ userId, projectId }) => {
  const [memberData, setMember] = React.useState(null);

  React.useEffect(() => {
    if (!userId || !projectId) {
      return;
    }

    getProjectMembersListByProjectId(projectId)
      .then((res) => {
        const member = res.find((member) => member.userId === userId) || null;
        setMember(member);
      })
      .catch((e) => {
        setMember(null);
      });
  }, [projectId, userId]);

  return memberData;
};
const ProjectMemberConfirmation = () => {
  // Refs
  const textareaRef = React.useRef(null);

  //Hooks
  const params = useParams();
  const navigate = useNavigate();
  const { genBriefPath } = useGeneratePath();

  const { fields } = useProfileMissedFields();
  const { userId } = useUserData();

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  // States
  const [state, dispatch] = React.useReducer(ndaReducer, initialState);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [remarkVal, setRemarkVal] = React.useState("");
  const [isInitaionSubmited, setIsInitaionSubmited] = React.useState(false);
  const [isHideAction, setHideAction] = React.useState(false);
  const [templateData, setTemplateData] = React.useState(null);
  const [actionStatus, setActionStatus] = React.useState(-1);
  const [isChangeRequest, setChangeRequest] = React.useState(false);

  const documentId = state?.document?.documentId || null;
  const member = useMemberData({
    userId,
    projectId: state?.projectDetails?.projectId,
  });

  const { data, isLoading, isFetched } = useQuery(
    ["nda", params.id],
    async () => getNDADetails2(params.id),
    {
      enabled: !!params.id,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: false,
    }
  );

  // Confirmation modal show handler
  const onShowConfirmationHandler = (status) => {
    setShowConfirmation(true);
    setActionStatus(status);
  };

  // Modal submit handler
  const onSubmitHandler = (status) => {
    if (isInitaionSubmited) {
      return;
    }

    setIsInitaionSubmited(true);

    // Check if the status is 1, then check if the user profile is updated or not.
    if (status === 1 && fields.length > 0) {
      setShowConfirmation(false);
      toast.error("For the NDA your basic profile should up to date.", {
        // autoClose: 7000,
        onClose: () => {
          setIsInitaionSubmited(false);
        },
      });

      return;
    }

    // API Call for submit.
    submitNDA({
      id: params.ndaRef,
      status: Number.parseInt(status),
      remark: remarkVal,
    })
      .then((data) => {
        setIsInitaionSubmited(false);
        setShowConfirmation(false);

        if (!data.isSuccess) {
          toast.error(data?.result?.message);
          setHideAction(true);
          return;
        }

        setHideAction(true);

        toast.success(data?.displayMessage, {
          onClose: () => {
            if (status === 1 && state?.projectDetails) {
              const pid = state?.projectDetails?.projectId || 0;
              // Generate Path
              let briefPath = genBriefPath(pid);

              // set project details to section
              localStorage.setItem(
                "selected_project",
                JSON.stringify(state.projectDetails)
              );

              navigate("/project/brief/" + pid);
            }
          },
        });
      })
      .catch((err) => {
        let msg;

        if (err.systemeError) {
          msg = err.systemeError.errors.projectInvitationAcceptOrRejectDto[0];
        } else {
          msg = err;
        }

        toast.error(msg);
        setIsInitaionSubmited(false);
        setShowConfirmation(false);
      });
  };

  const onCancelHandler = () => {
    // navigate(`/${ROUTE_OPTIONS.dashboard.path}`);'
    navigate("/project/brief/" + state.projectDetails.projectId);
  };

  const handleAccessibilityForFloatingButtons = async () => {
    return new Promise((resolve) => {
      if (
        member?.userCurrentStatusIdInProject === ONBOARDING_STATUS.NDAGenerated
      ) {
        setHideAction(true);
        resolve();
        return;
      }

      setHideAction(false);
      resolve();
    });
  };

  React.useEffect(() => {
    if (documentId) {
      getDocumentSFDTByDocId(documentId)
        .then((res) => {
          if (!res?.isSuccess) {
            console.log(
              "getDocumentSFDTByDocId: Document Not Retriving base on documentId",
              res
            );
            return;
          }

          setTemplateData(res?.result || null);
        })
        .catch((err) => {});
    }
  }, [documentId]);

  React.useEffect(() => {
    if (showConfirmation) {
      textareaRef?.current.focus();
      setRemarkVal("");
    }
  }, [showConfirmation]);

  React.useEffect(() => {
    showLoader();

    if (!isLoading) {
      hideLoader();
    }

    if (!isLoading && isFetched && data) {
      console.log("NDA data --> ", data);

      // Check if the data is successfully
      if (!data?.isSuccess) {
        Alert.fire({
          allowOutsideClick: false,
          icon: "error",
          title: "Error",
          text: data?.result?.message,
          confirmButtonText: "Go Back",
        }).then(() => {
          navigate(`/${ROUTE_OPTIONS.dashboard.path}`);
        });
        return;
      }

      // Check match userId with current log user
      if (data?.result?.projectInviteeDetails?.userId !== userId) {
        localStorage.setItem("user-status", -1);
        navigate(PublicRoutePath.Login.path);
        return;
      }

      handleAccessibilityForFloatingButtons(
        data?.result?.projectDetails?.projectId
      ).then((res) => {
        // Dispatch data
        dispatch({
          type: ActionTypes.NDA_FETCH_DETAILS,
          payload: data?.result,
        });
      });
    }
  }, [isLoading, showLoader, hideLoader, data, isFetched, navigate, userId]);

  if (isLoading || !data.isSuccess || !documentId) {
    return <></>;
  }

  console.log("state.joinAs ", state.joinAs);

  return (
    <>
      {/* <Container withGutterY={false} pageContentClassName="full--page"> */}

      <div className="css-bg-neutral-10 css-max-h-[calc(100vh-var(--nav-height))] css-h-[calc(100vh-var(--nav-height))] css-overflow-hidden">
        <div className="css-flex css-flex-col css-h-[inherit] css-overflow-hidden">
          <div className="css-h-full css-overflow-x-hidden css-pt-56">
            <div className="css-grid css-gap-8 css-w-[720px] css-mx-auto">
              <div className="css-overflow-hidden">
                <div className="css-bg-accent-200 css-p-16 css-px-24 css-rounded-tr-8 css-rounded-tl-8">
                  <div className="css-grid css-gap-8">
                    {/* Company info */}
                    <div className="css-grid">
                      <div className="css-font-small-600 css-text-primary-300">
                        Company Name
                      </div>
                      <div className="css-font-large-600 css-text-neutral-10">
                        Peddling Pictures
                      </div>
                    </div>

                    {/* project info */}
                    <div className="css-grid">
                      <div className="css-font-small-600 css-text-primary-300">
                        Project
                      </div>
                      <div className="css-font-large-600 css-text-neutral-10">
                        {state?.projectName || "-"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="css-rounded-8 css-border css-rounded-tr-none css-rounded-tl-none css-border-solid css-border-neutral-80 css-overflow-hidden ">
                  <ViewDocument
                    documentId={data?.result?.ndaInfo?.documentId || null}
                    onDocumentLoad={(TXDocumentViewer) => {
                      // console.log("TXDocumentViewer---", TXDocumentViewer);
                    }}
                    onDocumentLoaded={(TXDocumentViewer, isDone) => {
                      TXDocumentViewer.setZoomFactor(0.8);
                    }}
                  />

                  {/* <DocumentViewNDA
                    title={state.document.title || null}
                    url={state.document.url || null}
                    templateData={templateData}
                  /> */}
                </div>
              </div>

              <div className="css-w-full">
                <NoteBoard
                  projectId={state?.projectDetails?.projectId || 0}
                  projectMemberId={member?.projectMemberId || 0}
                  isNoteMutate={isChangeRequest}
                  onNoteMutated={() => {
                    setChangeRequest(false);
                  }}
                  onNoteMutateSuccess={(data) => {
                    // console.log("onNoteMutateSuccess", data);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="css-flex css-justify-center css-pb-56 css-pt-24">
            <div className="css-w-[720px] ">
              {!isHideAction && templateData ? (
                <div className="css-flex css-justify-between">
                  <div className="css-flex css-gap-8 css-items-center ">
                    <UIButton
                      variant={UIButton.Variant.Neutral}
                      size={UIButton.Sizes.Small}
                      onClick={() => {
                        window.TXDocumentViewer.setZoomFactor(0.2);
                      }}
                    >
                      Remind Later
                    </UIButton>

                    <UIButton
                      variant={UIButton.Variant.Neutral}
                      size={UIButton.Sizes.Small}
                      onClick={onCancelHandler}
                    >
                      Cancel
                    </UIButton>

                    <UIButton
                      variant={UIButton.Variant.Neutral}
                      size={UIButton.Sizes.Small}
                      onClick={() => onShowConfirmationHandler(0)}
                    >
                      Decline
                    </UIButton>
                  </div>

                  <div className="css-flex css-gap-8 css-items-center">
                    <UIButton
                      variant={UIButton.Variant.Secondary}
                      size={UIButton.Sizes.Small}
                      onClick={() => {
                        setChangeRequest(true);
                      }}
                    >
                      Change request
                    </UIButton>

                    <UIButton
                      variant={UIButton.Variant.Primary}
                      size={UIButton.Sizes.Small}
                      onClick={() => onShowConfirmationHandler(1)}
                    >
                      Accept NDA
                    </UIButton>
                  </div>
                </div>
              ) : (
                <div className="css-flex css-justify-end">
                  <UIButton
                    variant={UIButton.Variant.Neutral}
                    size={UIButton.Sizes.Small}
                    onClick={onCancelHandler}
                  >
                    Cancel
                  </UIButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </Container> */}

      {/* Confirmation dialog */}
      <AlertDialog
        alertIcon={
          <>
            <i className="icon icon-35 icon-lp-add-user-1 text-orange"></i>
          </>
        }
        visibility={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        contentTextAlign="left"
        title="Confirmation"
        contents={
          <>
            <div className="col-12">
              <label className="form-label">Remark</label>
              <textarea
                className="form-control textarea-control"
                ref={textareaRef}
                value={remarkVal}
                onChange={(e) => setRemarkVal(e.target.value)}
              ></textarea>
            </div>
          </>
        }
        actions={
          <>
            <button
              className="btn icon icon-lp-close icon--v-middle box-btn box-btn-gray-200"
              type="button"
              onClick={() => setShowConfirmation(false)}
            ></button>

            {actionStatus === 0 ? (
              <Button
                leftIcon={BUTTON_ICONS.cancel}
                variant="secondary"
                onClick={() => onSubmitHandler(8)}
              >
                Decline
              </Button>
            ) : null}

            {actionStatus === 1 ? (
              <Button
                leftIcon={BUTTON_ICONS.done}
                onClick={() => onSubmitHandler(7)}
              >
                Accept NDA
              </Button>
            ) : null}
          </>
        }
      />
    </>
  );
};

export default ProjectMemberConfirmation;
// export default withProfileUpdateAlert(ProjectMemberConfirmation, {
//   page: "NDA",
// });
