import React from "react";
import BaseCard from "./BaseCard";
import CardItem from "./CardItem";
import Trademark from "../../../../../../common/components/UI/Trademark";

import { AttachmentItem1 } from "../../../../../../common/components/UI/AttachmentItem";
import IconInvitationSend from "../../../../../../common/components/icons/Line/InvitationSend/01";
import IconClapper from "../../../../../../common/components/icons/Line/clapper";
import IconDoc from "../../../../../../common/components/icons/Line/doc01";
import IconCalendar from "../../../../../../common/components/icons/Fill/Calendar/01";
import IconAttachment from "../../../../../../common/components/icons/Fill/attachment";
import IconContactPerson from "../../../../../../common/components/icons/Line/Contactperson/01";
import IconNote from "../../../../../../common/components/icons/Line/Notes/04";

import Icon from "../../../../../../common/components/UI/Icon";
import NoteBoard from "../../../../../../shared/components/NoteBoard";
import { ONBOARDING_STATUS } from "../../../../../../constant/Status";
import { IconMessage } from "../../../../../../common/components/icons";
const DATA_HIDE_STATUS_IF_INVITATION_NOT_ACCEPTED = [
  ONBOARDING_STATUS.ShortListed,
  ONBOARDING_STATUS.ProjectAdded,
  ONBOARDING_STATUS.InvitationReceived,
  ONBOARDING_STATUS.InvitationRejected,
];
const ProjectCard = ({
  headerWrapperClass,
  data,
  viewType,
  restictions = [],
  noteRef,
}) => {
  return (
    <BaseCard
      headerWrapperClass={headerWrapperClass}
      headerStart={
        <HeaderStart
          projectName={data?.projectDetails?.projectName}
          logo={data?.projectDetails?.projectLogo}
        />
      }
      headerEnd={
        <HeaderEnd
          hideArea={
            DATA_HIDE_STATUS_IF_INVITATION_NOT_ACCEPTED.includes(
              data?.projectMemberDetails?.userCurrentStatusIdInProject
            )
              ? true
              : false
          }
          episodes={data?.projectDetails?.projectNoOfEpisodes}
          duration={data?.projectDetails?.duration || "-"}
        />
      }
      content={
        <Content
          noteRef={noteRef}
          data={data}
          status={viewType}
          restictions={restictions}
        />
      }
    />
  );
};

function HeaderStart({ projectName, logo }) {
  return (
    <div className="css-flex css-gap-16">
      <Trademark src={logo} />
      <div className="css-grid">
        <div className="css-font-base-400">Project</div>
        <div className="css-font-h6-600">{projectName}</div>
      </div>
    </div>
  );
}

function HeaderEnd({ episodes, duration, hideArea = false }) {
  if (hideArea) return null;
  return (
    <div className="css-flex css-gap-24">
      <HeaderEndItem
        icon={<IconClapper />}
        label="Episodes"
        content={episodes}
      />
      <HeaderEndItem
        icon={<IconClapper />}
        label="Duration"
        content={duration + " min"}
      />
    </div>
  );
}

function HeaderEndItem({ label, content, icon }) {
  return (
    <div className="css-flex css-gap-16 css-items-center">
      <div className="css-text-secondary-300">
        <Icon icon={icon} size={28} iconSize={28} inheritParentColor />
      </div>
      <div className="css-font-base-600">
        <div className="css-text-accent-200">{label}</div>
        <div className="css-text-primary-300">{content}</div>
      </div>
    </div>
  );
}

function Content({ data, status, restictions, noteRef }) {
  if (status !== ONBOARDING_STATUS.InvitationReceived) {
    // Invitation Completed
    return (
      <div className="css-grid css-gap-16">
        {/* Project Description */}
        <CardItem
          icon={<IconDoc />}
          caption="Project Description"
          content={data?.projectDetails?.projectDescription}
        />

        {/* Project Start & End Dates */}
        <CardItem
          icon={<IconCalendar />}
          caption="Start & End Dates"
          content="August 17 - November 26"
        />

        {/* Contact Person */}
        <CardItem
          icon={<IconContactPerson />}
          caption="Contact Person"
          content={data?.projectDetails?.projectContactPersonName}
        />

        {/* Invitation sent by */}
        <CardItem
          icon={<IconInvitationSend />}
          caption="Invitated by"
          content={
            data?.projectInvitationLog?.invitedByUser?.memberName +
            " (" +
            data?.projectInvitationLog?.invitedByUser?.roleInProject +
            ")"
          }
        />

        {/* Attachments */}
        <CardItem
          icon={<IconAttachment />}
          caption="Attachments"
          content={
            <Attachments
              deck={data?.projectDetails?.projectDeckUrl}
              trailer={data?.projectDetails?.projectTrailerUrl}
            />
          }
        />

        {/* Note */}
        {restictions.includes(
          data?.projectMemberDetails?.userCurrentStatusIdInProject
        ) ? (
          <CardItem
            icon={<IconNote />}
            content={
              <NoteBoard
                noteRef={noteRef}
                projectId={data?.projectDetails?.projectId || 0}
                projectMemberId={
                  data?.projectMemberDetails?.projectMemberId || 0
                }
              />
            }
          />
        ) : null}
      </div>
    );
  } else {
    //Invitation Received
    return (
      <div className="css-grid css-gap-16">
        {/* Invitation sent by */}
        <CardItem
          icon={<IconInvitationSend />}
          caption="Invitated by"
          content={
            data?.projectInvitationLog?.invitedByUser?.memberName +
            " (" +
            data?.projectInvitationLog?.invitedByUser?.roleInProject +
            ")"
          }
        />
        {/* Join As */}
        <CardItem
          icon={<IconDoc />}
          caption="Join As"
          content={data?.projectMemberDetails?.roleInProject}
        />

        {/* Message */}
        <CardItem
          icon={<IconMessage />}
          caption="Message"
          content={data?.projectInvitationLog?.invitationRemarks}
        />

        {/* Note */}
        <CardItem
          icon={<IconNote />}
          content={
            <NoteBoard
              noteRef={noteRef}
              projectId={data?.projectDetails?.projectId || 0}
              projectMemberId={data?.projectMemberDetails?.projectMemberId || 0}
            />
          }
        />
      </div>
    );
  }
}

function Attachments({ deck, trailer }) {
  return (
    <div className="css-flex css-gap-16 css-flex-wrap css-mt-4">
      <AttachmentItem1 label={deck} />
      <AttachmentItem1 label={trailer} />
    </div>
  );
}

export default ProjectCard;
