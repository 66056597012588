import API_ENDPOINTS from "../../services/api-endpoints";
import API_STORAGE from "../../services/api-fileupload";
import API_GLOBAL from "../../services/api";
import API_COMMON from "../../services/api-common";

export const getAllJobTitles = async () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_GLOBAL.get(endpoints.GETALLJOBTITLES)
      .then((res) => {
        if (!res.data.isSuccess) {
          return [];
        }

        const data = res?.data?.result;

        const dataList = data.map((item) => {
          return {
            ...item,
            label: item.jobTitleDescription,
            value: item.jobTitleId,
          };
        });

        resolve(dataList);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Get Country List
export const getCountryList = async () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_GLOBAL.get(endpoints.GETALLCOUNTRIES)
      .then((response) => {
        if (!response.data.isSuccess) {
          resolve([]);
        }

        const data = response.data.result;
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Upload Image
export const uploadImage = async (data, config = null) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_STORAGE.post(endpoints.FILE_STREAM_UPLOAD_ENDPOINT, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Get User Titles
export const getUserTitles = async () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_GLOBAL.get(endpoints.GET_USER_TITLES)
      .then((response) => {
        if (!response.data.isSuccess) {
          resolve([]);
        }

        const data = response.data.result.result || [];

        const _data = data.map((val) => ({
          ...val,
          id: val.titleId,
          label: val.titleName,
        }));

        resolve(_data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateProjectCheckedIn = (data) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_GLOBAL.post(endpoints.PROJECT_UPDATE_CHECKED_IN, data)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllCurrencies = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API_GLOBAL.get(`/Global/Currency_GetAll`);

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const currency = response?.data?.result;

      const data = currency.map((item) => {
        return {
          value: item?.currencyId || 0,
          label: item?.currencyCode,
        };
      });

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllUnits = async () => {
  try {
    const response = await API_GLOBAL.get(`/Global/RateCardUnit_GetAll`);

    if (!response?.data?.isSuccess) {
      return [];
    }

    const units = response?.data?.result;

    const data = units
      .filter((item) => item?.rateCardUnitId !== 1)
      .map((item) => ({
        value: item?.rateCardUnitId || 0,
        label: item?.rateCardUnitName,
      }));

    return data;
  } catch (error) {
    console.error("Error fetching rate card units:", error);
    throw error;
  }
};
export const getAllDeliverables = async () => {
  try {
    const response = await API_COMMON.get(`/common/deliverables`);

    if (!response?.data?.isSuccess) {
      return [];
    }

    const deliverables = response?.data?.result;

    const data = deliverables
      .filter((item) => item?.rateCardUnitId !== 1)
      .map((item) => ({
        value: item?.deliverableId || 0,
        label: item?.deliverableName,
      }));

    return data;
  } catch (error) {
    console.error("Error fetching rate card delivarables:", error);
    throw error;
  }
};
