import { format } from "date-fns";
import RESEARCH_API from "../../../../services/api-research";
import { CalendarType } from "../constant";
import { CALENDAR_ENDPOINTS } from "./calender.endpoints";

/**
 *
 * @param {*} calendarType
 * @param {*} date
 * @param {*} meta
 * @returns
 */
export const getCalendar = async (calendarType, date, meta) => {
  /**
  Base url
   uri = res/calendar?calendarType=1&date=2024-12-01

  User availability url
   {uri}&userId=89239bvg834923vvl

  Tag availability Url
   {uri}&tagId=1&subTagTypeId=34
  */

  let url = `${CALENDAR_ENDPOINTS.GET_EVENT_CALENDER}?calendarType=${calendarType}&date=${date}`;

  if (calendarType === CalendarType.Tag) {
    url = `${url}&tagId=${meta?.tagId}&subTagTypeId=${meta?.subTagTypeId}`;
  }

  if (calendarType === CalendarType.User) {
    url = `${url}&userId=${meta?.userId}`;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await RESEARCH_API.get(url);

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response.data?.result;

      data.calendarDates.forEach((calendarDate) => {
        calendarDate.timeSlots.forEach((timeSlot) => {
          timeSlot.calendarId = data?.calendarId || 0;
          timeSlot.startDate = calendarDate.startDate?.split("T")[0];
          timeSlot.endDate = calendarDate.endDate?.split("T")[0];
        });
      });

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};

/**
 *
 * @param {*} __projectId
 * @param {*} __calendarType
 * @param {*} ____calendarId
 * @param {*} __groupId
 * @param {*} meta
 * @param {*} values
 * @returns
 */
export const saveCalendarEvent = (
  __projectId,
  __calendarType,
  ____calendarId,
  __groupId,
  meta,
  values
) => {
  let params = {
    calendarId: ____calendarId || 0,
    calendarType: __calendarType,
    tagId: meta?.tagId || 0,
    subTagTypeId: meta?.subTagTypeId || 0,
    userId: meta?.userId,
    projectId: __projectId,
    title: values?.title || "",
    startDate: format(new Date(values?.startDate), "yyyy-MM-dd") || "",
    endDate: format(new Date(values?.endDate), "yyyy-MM-dd") || "",
    startTime: values?.startTime || "",
    endTime: values?.endTime || "",
    isFullDay: values?.isFullDay || false,
    availability: values?.availability || 0,
    comment: values?.note || "",
    groupId: __groupId,
  };

  return new Promise((resolve, reject) => {
    RESEARCH_API.post(`${CALENDAR_ENDPOINTS.SAVE_EVENT_CALENDER}`, params)
      .then((res) => {
        const data = res?.data?.result || null;

        data.calendarDates.forEach((calendarDate) => {
          calendarDate.timeSlots.forEach((timeSlot) => {
            timeSlot.calendarId = data?.calendarId || 0;
            timeSlot.startDate = calendarDate.startDate?.split("T")[0];
            timeSlot.endDate = calendarDate.endDate?.split("T")[0];
          });
        });

        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};

/**
 *
 * @param {*} __calendarId
 * @param {*} __groupId
 * @returns
 */
export const getEventFormData = async (__calendarId, __groupId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await RESEARCH_API.get(
        `${CALENDAR_ENDPOINTS.GET_EVENT__FORM_DATA}/?calendarId=${__calendarId}&groupId=${__groupId}`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
