import React from "react";
import Breadcrumb from "../../../../ui-components/Breadcrumb";
import Logo from "./Logo";
import "./styles.scss";

import LogoImage from "../../../../../assets/logo.svg";
import ProjectSelector from "./ProjectSelector";
import PageSelector from "./PageSelector";
import LocationList from "./LocationList";
import MemberList from "./MemberList";
import { useNotification } from "../../../../../context/NotificationContext";
//import Notification from "../../../../ui-components/Notification";
// import CreateNewProject from "../../../project/create-new-project";
import {
  setGlobalProject,
  useGlobalStore,
} from "../../../../../store/GlobalStore";
import { useAppStore } from "../../../../../store/AppStore/AppStore";
import { IconBlock } from "../../../../../styled/Common/Icons";
import * as LineIcon from "../../../../../common/components/icons/Line";
import OptionButton from "../../../../../common/components/Buttons/OptionButton";
import { useTempUserStore } from "../../../../../store/TempUserStore";
import IconButton from "../../../../../common/components/Buttons/IconButton";
import IconChat from "../../../../../common/components/icons/Line/chat";
import IconFAQ from "../../../../../common/components/icons/Line/FAQ/01";

import useIsTenantPathMatch from "../../mainLayout/hooks/useIsTenantPathMatch";

import useUserData from "../../../../../hooks/useUserData";
import { SYSTEM_ROLE_TYPE } from "../../../../../constant/System";
//import Alert from "../../../../../utility/alert";
import useIsDashboardPathMatch from "../../mainLayout/hooks/useIsDashboardPathMatch";

const USER_CREATION_PERMISSIONS = [
  SYSTEM_ROLE_TYPE.PO,
  SYSTEM_ROLE_TYPE.ADMIN,
  SYSTEM_ROLE_TYPE.RM,
];

const Toolbar = () => {
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const toggleSideMenu = useAppStore((state) => state.toggleSideMenu);
  const createNewUserModal = useTempUserStore((state) => state.onToggleModal);
  const globalSelectedProjectId = useGlobalStore(
    (state) => state.selectedProjectId
  );
  const isTenantPath = useIsTenantPathMatch();
  const isDashBoardPath = useIsDashboardPathMatch();

  const { userRoles } = useUserData();

  const headerRef = React.useRef();

  const { onToggleNotification, count } = useNotification();

  // const [showCreateProject, setShowCreateProject] = React.useState(false);
  React.useEffect(() => {
    const h = headerRef.current.clientHeight;
    document.documentElement.style.setProperty("--nav-height", `${h}px`);
  }, []);

  const onProjectSelectHandler = (id) => {
    showLoader();
    setGlobalProject(id)
      .then((status) => {
        hideLoader();
        console.log("global selected project id => ", id);
      })
      .catch((e) => {
        hideLoader();
      });
  };

  return (
    <header className="sys__toolbar" ref={headerRef}>
      <div className="sys__toolbar__container">
        {/* Start */}
        <div className="sys__toolbar__start">
          {/* Logo */}
          <Logo url={LogoImage} />
          <div className="action">
            {/* Page Selector */}
            <PageSelector />

            {/* Project selector */}
            {!isTenantPath && !isDashBoardPath ? (
              <>
                <ProjectSelector onSelect={onProjectSelectHandler} />
              </>
            ) : null}
          </div>
        </div>

        {/* Center */}
        <div className="sys__toolbar__center">
          <LocationList />
        </div>

        {/* End */}
        <div className="sys__toolbar__end">
          {/* Members List */}
          <MemberList />

          {/* Right actions */}
          <div className="actions">
            <div className="divider"></div>

            <div className="inner">
              <button
                className="nav__button nav__button--size-36 faq__btn"
                data-tooltip-text="FAQ"
                data-tooltip-placement="bottom"
              >
                <IconButton as="div" icon={<IconFAQ />} />
              </button>

              <button
                className="nav__button nav__button--size-36 chat__btn"
                data-tooltip-text="Chat"
                data-tooltip-placement="bottom"
              >
                <IconButton as="div" icon={<IconChat />} />
              </button>

              <button
                className="nav__button nav__button--size-36  btn__notify"
                data-tooltip-text="Notifications"
                data-tooltip-placement="auto"
                type="button"
                onClick={onToggleNotification}
              >
                <span className="icon icon-lp-bell">
                  {count > 0 ? <span className="dot">{count}</span> : null}
                </span>
              </button>
            </div>

            {globalSelectedProjectId ? (
              <>
                <div className="divider"></div>

                <div
                  className="inner"
                  data-tooltip-text="Create New"
                  data-tooltip-placement="bottom"
                >
                  <OptionButton
                    options={[
                      // {
                      //   label: "Create New Project",
                      //   onClick: () => setShowCreateProject(true),
                      // },
                      userRoles.some((role) =>
                        USER_CREATION_PERMISSIONS.includes(role)
                      )
                        ? {
                            label: "Invite New User",
                            onClick: createNewUserModal,
                          }
                        : null,
                    ]}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {/* Breadcrumb */}
      <div className="sys__toolbar__breadcrumb">
        <span
          role="button"
          className="toggle__menu"
          data-tooltip-text="Show/Hide Menu"
          data-tooltip-placement="showHide-menu"
          onClick={toggleSideMenu}
        >
          <IconBlock className="icon">
            <LineIcon.Menu />
          </IconBlock>
        </span>

        <Breadcrumb />
      </div>

      {/* <CreateNewProject
        show={showCreateProject}
        close={() => setShowCreateProject(false)}
      /> */}

      {/* <Notification /> */}
    </header>
  );
};

export default Toolbar;
