import * as React from "react";
import { memo } from "react";
const SvgDelete = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Fill/Bin/01">
      <g id="icon">
        <path
          d="M1.32495 3.48464C1.12632 3.48464 0.932744 3.45641 0.752246 3.40701L1.84419 13.8988C1.97023 15.0966 2.97243 16 4.17731 16H11.7271C12.93 16 13.9312 15.0966 14.0582 13.8988L15.1522 3.40701C14.9717 3.45843 14.7781 3.48464 14.5795 3.48464H1.32495ZM4.4667 9.14301C4.71978 7.45518 6.1757 6.16154 7.93317 6.16154C8.19933 6.16154 8.45745 6.19279 8.70449 6.24926L8.9011 5.97602C9.03016 5.79554 9.30641 5.82075 9.39717 6.0224L10.1917 7.77473C10.2824 7.97638 10.1221 8.2002 9.90031 8.18005L7.98461 7.98848C7.7628 7.96529 7.64886 7.71524 7.77792 7.53174L7.95336 7.28674C6.76867 7.27666 5.78562 8.12863 5.58597 9.25385C5.55976 9.40912 5.42061 9.51699 5.26333 9.51699H4.7854C4.58678 9.51699 4.43151 9.33853 4.46276 9.13992L4.4667 9.14301ZM11.4438 9.64914C11.4538 11.5356 9.94147 13.1135 8.055 13.1781C7.51255 13.1932 6.99632 13.0904 6.53455 12.8887L6.3077 13.2265C6.18368 13.412 5.90741 13.3918 5.80961 13.1932L4.95864 11.4691C4.86084 11.2674 5.0151 11.0375 5.2349 11.0506L6.19477 11.1152L7.15464 11.1767C7.37646 11.1948 7.49744 11.4428 7.37646 11.6284L7.17481 11.9248C7.54182 12.0508 7.94915 12.0871 8.37261 12.0125C9.33752 11.8371 10.1068 11.0597 10.2772 10.0918C10.3034 9.9476 10.3135 9.8004 10.3135 9.66024C10.3135 9.47472 10.4577 9.32751 10.6412 9.32751H11.1161C11.2915 9.32751 11.4417 9.46665 11.4417 9.64512L11.4438 9.64914Z"
          fill="currentColor"
        />
        <path
          d="M14.887 0.0383142C14.7892 0.0121002 14.6854 0 14.5795 0H1.32484C1.21898 0 1.11614 0.0131071 1.01733 0.0383142C0.433553 0.175437 0 0.698723 0 1.32385C0 1.56381 0.0645284 1.79067 0.178462 1.98426C0.289371 2.18087 0.454721 2.3452 0.650319 2.45913C0.84693 2.57811 1.07884 2.64465 1.32183 2.64465H14.5765C14.8215 2.64465 15.0513 2.5771 15.248 2.45913C15.4446 2.3452 15.6069 2.18086 15.7198 1.98121C15.8307 1.7846 15.8953 1.56077 15.8953 1.32282C15.8953 0.697691 15.4617 0.174417 14.883 0.0372818L14.887 0.0383142Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const Memo = memo(SvgDelete);
export default Memo;
