import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useLocation,
  generatePath,
  useSearchParams,
  useRouteLoaderData,
} from "react-router-dom";

import API from "../../../services/api";
import API_Tenant from "../../../services/api-tenant";
import API_Project from "../../../services/api-project";
import API_ENDPOINTS from "../../../services/api-endpoints";
import { toast } from "react-toastify";
import "../../sass/project/project.sass";
import "../../assets/css/project.wizard.css";
import "./styles/project.scss";

import Modal from "../../controls/modals/modal";

import "react-toastify/dist/ReactToastify.css";
import * as Loader from "react-loader-spinner";
import DataTable from "react-data-table-component";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "../../../components/controls/select-search/styles.css";
import { MultiSelect } from "react-multi-select-component";
import { confirm } from "react-confirm-box";
import { ROUTES_PATH } from "../../../routes/paths";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { PROJECT_PERMISSION_MODULES } from "../../../constant/project_permission_module";
import { Permission } from "../../../helpers/permission";
import UIButton from "./../../../common/components/Buttons/UIButton/index";
import IconAdd from "./../../../common/components/icons/Fill/add";
import {
  ModalVisibility,
  setActiveProjectId,
  setCurrentStep,
  updateActionsState,
  updateStepData,
} from "../../../shared/components/Modals/ProjectWizardModal/store";
import { useProjectStore } from "../../../store/ProjectStore";
import { useAppStore } from "../../../store/AppStore/AppStore";
import { getGenresByProjectId } from "../../../shared/components/Modals/API/Project";
import { setGlobalProject } from "../../../store/GlobalStore";
import ProjectListView from "./Components/ProjectListView";
import { ProjectContainer } from "./styled";
import useUserData from "../../../hooks/useUserData";
import { SYSTEM_ROLE_TYPE } from "../../../constant/System";
import { PageContainer } from "../../../styled/Common/Container";
import { getProjectByID } from "../../../Actions/Project";

const NewProject = (props) => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.projects.children["new-project"].id
  );
  const { userRoles, tenantId, userId } = useUserData();

  const relatedProjects = useProjectStore((state) => state.projectList);
  // const relatedProjects = useProjectStore((state) =>
  //   userRoles?.find((u) => u === SYSTEM_ROLE_TYPE.ADMIN)
  //     ? state.tenantProjects
  //     : state.userTenantProjects
  // );

  const moduleType = PROJECT_PERMISSION_MODULES.NewProjectActions;

  const permissions = new Permission(permission);

  const deleteAction = permissions.findByName(moduleType.Delete.key);
  const featuresAction = permissions.findByName(moduleType.Features.key);
  const editAction = permissions.findByName(moduleType.Edit.key);
  const onboardingAction = permissions.findByName(moduleType.Onboarding.key);
  const projectBriefAction = permissions.findByName(
    moduleType.ProjectBrief.key
  );
  const newProjectCreateAction = permissions.findByName(
    moduleType.ProjectCreateNew.key
  );

  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const [showCreateProjectPrompt, setShowCreateProjectPrompt] = useState(false);
  const [showProjectFeaturePrompt, setShowProjectFeaturePrompt] =
    useState(false);
  const [show, setShow] = useState(false); //Loading Indicator
  const [projectId, setProjectId] = useState(0);
  const [projectName, setProjectName] = useState("");
  const [projectPrivacyId, setProjectPrivacyId] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [clientId, setClientId] = useState(0);
  const [projectTypeId, setProjectTypeId] = useState(0);
  const [duration, setDuration] = useState("");
  const [genres, setGenres] = useState([]);

  let ProjectList = [];
  const [allProjects, setAllProjects] = useState([]);

  //const allTenantProjects = useProjectStore((state) => state.projects);
  const getProjectsByTenant = useProjectStore(
    (state) => state.getProjectsByTenant
  );

  const getProjectsByTenantId = useProjectStore(
    (state) => state.getProjectsByTenantId
  );

  const getProjectsByTenantAndUserId = useProjectStore(
    (state) => state.getProjectsByTenantAndUserId
  );

  //console.log("relatedProjects:: ", relatedProjects);
  let ProjectFeatureList = [];
  const [allProjectFeatures, setAllProjectFeatures] = useState([]);
  const [selectedProjectFeatures, setAllSelectedProjectFeatures] = useState([]);

  let ClientList = [];
  const [allClients, setAllClients] = useState([]);
  let ProjectPrivacyList = [];
  const [allProjectPrivacies, setAllProjectPrivacies] = useState([]);
  let ProjectTypeList = [];
  const [allProjectTypes, setAllProjectTypes] = useState([]);
  let GenresList = [];
  const [allGenres, setAllGenres] = useState([]);

  let USER = JSON.parse(localStorage.getItem("user"));
  const [projectSaveBtn, setProjectSaveBtn] = useState("Create");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const [selectedGenres, setSelectedGenres] = useState([]);

  const columns = [
    {
      name: "",
      selector: (row) => row.projectName,
      sortable: true,
      style: {
        background: "#FAFDFF",
        color: "#6c757d",
      },
    },
    {
      name: "",
      selector: (row) => row.startDate,
      sortable: true,
      style: {
        background: "#FAFDFF",
        color: "#6c757d",
      },
    },
    {
      cell: (row) => (
        <button
          disabled={!editAction.permission.canView}
          onClick={
            editAction.permission.canView ? () => editProject(row) : null
          }
          id={row.ID}
          className="btn btn-primary"
        >
          Edit{" "}
        </button>
      ),
      ignoreRowClick: false,
      allowOverflow: true,
      button: true,
      style: {
        background: "#FAFDFF",
        color: "#6c757d",
      },
    },
    {
      cell: (row) => (
        <button
          disabled={!featuresAction.permission.canView}
          onClick={
            featuresAction.permission.canView
              ? () => editProjectFeature(row)
              : null
          }
          id={row.ID}
          className="btn btn-primary"
        >
          Features{" "}
        </button>
      ),
      ignoreRowClick: false,
      allowOverflow: true,
      button: true,
      style: {
        background: "#FAFDFF",
        color: "#6c757d",
      },
    },
    {
      cell: (row) => (
        <button
          disabled={!deleteAction.permission.canView}
          onClick={
            deleteAction.permission.canView ? () => deleteProject(row) : null
          }
          id={row.ID}
          className="btn btn-primary"
        >
          Delete{" "}
        </button>
      ),
      ignoreRowClick: false,
      allowOverflow: true,
      button: true,
      style: {
        background: "#FAFDFF",
        color: "#6c757d",
      },
    },
    {
      cell: (row) => (
        <button
          disabled={!onboardingAction.permission.canView}
          onClick={
            onboardingAction.permission.canView ? () => Onboarding(row) : null
          }
          id={row.ID}
          className="btn btn-primary"
        >
          Onboarding{" "}
        </button>
      ),
      ignoreRowClick: false,
      allowOverflow: true,
      button: true,
      style: {
        background: "#FAFDFF",
        color: "#6c757d",
      },
    },
    {
      cell: (row) => (
        <button
          disabled={!projectBriefAction.permission.canView}
          onClick={
            projectBriefAction.permission.canView
              ? () => projectBrief(row)
              : null
          }
          id={row.ID}
          className="btn btn-primary"
        >
          Project Brief{" "}
        </button>
      ),
      ignoreRowClick: false,
      allowOverflow: true,
      button: true,
      style: {
        background: "#FAFDFF",
        color: "#6c757d",
      },
    },
  ];

  const project_data = [];

  const updateProjectList = React.useCallback(() => {
    if (userRoles?.find((u) => u === SYSTEM_ROLE_TYPE.ADMIN)) {
      getProjectsByTenant();
      console.log("getProjectAPI call 1 : ", getProjectsByTenant());
      getProjectsByTenantId(tenantId);
      console.log("getProjectAPI call 2 : ", getProjectsByTenantId(tenantId));
      return;
    }
    getProjectsByTenantAndUserId(tenantId, userId);
  }, [
    getProjectsByTenant,
    getProjectsByTenantAndUserId,
    getProjectsByTenantId,
    tenantId,
    userId,
    userRoles,
  ]);

  useEffect(() => {
    // updateProjectList();
    getAndBindProjectList();
    getAndBindGenresList();
    getAndBindClientList();
    getAndBindProjectPrivacyList();
    getAndBindProjectTypeList();
    getAndBindProjectFeatures();
    // if (props.showWizard) {
    //   setShowCreateProjectPrompt(true);
    //   //setShowProjectFeaturePrompt(true);
    // }
  }, []);

  React.useEffect(() => {
    if (searchParams.has("showWizard")) {
      setProjectId(0);
      setProjectName("");
      setProjectPrivacyId(0);
      setProjectTypeId(0);
      setStartDate("");
      setEndDate("");
      setDuration("");
      setClientId(0);
      setGenres([]);
      setAllSelectedProjectFeatures([]);

      setShowCreateProjectPrompt(true);
    }
  }, [searchParams]);

  const updateSearchParams = () => {
    searchParams.delete("showWizard");
    setSearchParams(searchParams);
  };

  const setClient = async (id) => {
    //e.preventDefault();

    try {
      setClientId(id);
    } catch (err) {
      console.log(err);
    }
  };
  const closeNewProjectPropt = async () => {
    //e.preventDefault();

    try {
      setShowCreateProjectPrompt(false);
      setProjectSaveBtn("Create");
    } catch (err) {
      toast.error(err);
      console.log(err);
    }
  };

  //*****/ PROJECT ******//
  const getAndBindProjectList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GETALL + "/" + tenantId
      ).then((response) => {
        console.log("Projects:", response);
        if (response.data.isSuccess === true) {
          ProjectList = response.data.result;

          ProjectList.forEach((project) => {
            project_data.push(project);
          });

          const project_list = ProjectList.map((item) => {
            return <div></div>;
          });
          console.log("Projects datatable:", project_data);
          setAllProjects(project_data);
          //setGenresUIViewOptions();
          //   setHideGenresDataInputDiv(false);
          //setHideGenresViewtDiv(false);
          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const saveProject = async (e) => {
    let isEditMode = false;
    if (projectName.length === 0) {
      toast.error("Please enter a Project Name");
      return;
    }
    if (projectPrivacyId === 0) {
      toast.error("Please select a Project Privacy");
      return;
    }
    if (startDate.length === 0) {
      toast.error("Please enter a Start Date");
      return;
    }
    if (endDate.length === 0) {
      toast.error("Please enter a End Date");
      return;
    }
    var sdate = Date.parse(startDate);
    var edate = Date.parse(endDate);

    if (edate < sdate) {
      toast.error("End Date cannot be Earlier than Start date");
      return;
    }

    if (clientId === 0) {
      toast.error("Please select a Client");
      return;
    }
    if (projectTypeId === 0) {
      toast.error("Please select a Project Type");
      return;
    }
    if (duration.length === 0) {
      toast.error("Please enter a Duration");
      return;
    }
    if (genres.length === 0) {
      toast.error("Please select a Genres");
      return;
    }

    if (projectId == 0) {
      isEditMode = false;
    } else {
      isEditMode = true;
    }

    setShow(true); //Loading Indicator on

    let selectedGenresIds = [];
    genres.map((item) => {
      selectedGenresIds.push(item.value);
    });

    try {
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      //Create
      if (projectId === 0) {
        End_point = endpoints.PROJECT_CREATE;
        isEditMode = false;
        const response = API_Project.post(End_point, {
          projectId: projectId,
          tenantId: tenantId,
          projectName: projectName,
          projectPrivacyId: projectPrivacyId,
          projectTypeId: projectTypeId,
          startDate: startDate,
          endDate: endDate,
          duration: duration,
          clientId: clientId,
          //genereId: selectedGenresIds,
        }).then((response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Project created successfully");
            } else {
              // toast.success("Project updated successfully");
            }
            setProjectId(response.data.result.projectId);

            updateGenres(response.data.result.projectId, false);

            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.result.message);
            toast.error(response.data.result.message);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      } else {
        // Update
        End_point = endpoints.PROJECT_UPDATE;
        isEditMode = true;
        const response = API_Project.put(End_point + "/" + projectId, {
          projectId: projectId,
          tenantId: tenantId,
          projectName: projectName,
          projectPrivacyId: projectPrivacyId,
          projectTypeId: projectTypeId,
          startDate: startDate,
          endDate: endDate,
          duration: duration,
          clientId: clientId,
          //genereId: selectedGenresIds,
        }).then((response) => {
          console.log("Saved project: ", response);
          if (response.data.isSuccess === true) {
            if (!isEditMode) {
              // toast.success("Project created successfully");
            } else {
              // toast.success("Project updated successfully");
            }
            setProjectId(response.data.result.projectId);
            updateGenres(response.data.result.projectId, true);
            setShow(false); //Loading Indicator off
          } else {
            console.log(response.data.result.message);
            toast.error(response.data.result.message);
            setShow(false); //Loading Indicator off
            return;
          }
        });
      }

      updateSearchParams();
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const updateGenres = async (_projectId, isEditMode) => {
    try {
      let selectedGenresIds = [];
      genres.map((item) => {
        selectedGenresIds.push(item.value);
      });
      setShow(false); //Loading Indicator off
      // ** Genres Update **///////////////////
      if (_projectId !== 0) {
        console.log("project Id:", _projectId);
        setShow(true); //Loading Indicator on
        const endpoints = new API_ENDPOINTS();
        const response = API_Project.post(endpoints.PROJECT_GENRES_ADD, {
          projectId: _projectId,
          projectGenresId: selectedGenresIds,
        }).then((response) => {
          console.log("addes genres:", response);
          if (response.data.isSuccess === true) {
            //toast.success("Project Genres added successfully");
            getAndBindProjectList();
            console.log("isEditMode", isEditMode);
            if (isEditMode === false) {
              setShowCreateProjectPrompt(false);
              setShowProjectFeaturePrompt(true);
            }
            // setShowCreateProjectPrompt(false);
            // setShowProjectFeaturePrompt(true);
            setShow(false); //Loading Indicator off
          } else {
            console.log("fail response", response);
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator off

            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteProject = async (e) => {
    try {
      const confirmation = await confirm(
        "Are you sure you want to delete this item ?"
      );

      if (!confirmation) {
        return;
      }

      showLoader(); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.delete(
        endpoints.PROJECT_DELETE + "/" + e.projectId + "/" + true,
        {}
      ).then((response) => {
        console.log("delete project: ", response);
        if (response.data.isSuccess === true) {
          updateProjectList();
          hideLoader(); //Loading Indicator off
        } else {
          console.log(response.data.result.message);
          toast.error(response.data.result.message);
          hideLoader(); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      hideLoader(); //Loading Indicator off
    }
  };

  const editProject = async (p) => {
    try {
      showLoader();
      var detail = await getProjectByID(p.projectId);
      var e = detail?.projectDetails;

      if (!e) {
        return;
      }

      console.log("editProject :: ", e);
      setProjectId(e.projectId);
      setProjectName(e.projectName);
      setProjectPrivacyId(e.projectPrivacyId);
      setProjectTypeId(e.projectTypeId);
      setStartDate(e.startDate);
      setEndDate(e.endDate);
      setDuration(e.duration);
      setClientId(e.clientId);
      setGenres([]);

      //setShowCreateProjectPrompt(true);
      //setProjectSaveBtn("Update");

      const projectValues = {
        projectId: e.projectId,
        tenantId: e.tenantId,
        clientId: e.clientId,
        workingTitle: e.projectName,
        broadcastTitle: e.broadcastTitle,
        episodes: 1,
        duration: Number(e.duration),
        txDate: e.txDate,
        startDate: e.startDate,
        endDate: e.endDate,
        genre: null,
        language: {
          label: detail?.language?.languageName,
          value: detail?.language?.languageId,
        },
        logo: e.projectLogo,
      };

      //******** Get Project assigned Genres List ***********//
      //setShow(true);
      const endpoints = new API_ENDPOINTS();
      const response = await API_Project.get(
        endpoints.PROJECT_GENRES_GETBY_PROJECTID + "/" + e.projectId
      ).then((response) => {
        console.log("project selected genres:", response);
        if (response.data.isSuccess === true) {
          const selected_genere_list = response.data.result.map((item) => {
            return {
              label: item.projectGenresName,
              value: item.projectGenresId,
            };
          });
          setGenres(selected_genere_list);
          projectValues.genre = selected_genere_list || [];

          updateStepData("step_01", projectValues);
          updateStepData("step_02", projectValues);
          setActiveProjectId(e.projectId);
          ModalVisibility.open();

          hideLoader(); //Loading Indicator
        } else {
          console.log("fail response", response.data.displayMessage);
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          hideLoader(); //Loading Indicator
          return;
        }
      });

      //   setShow(true);
      //   const pf_endpoints = new API_ENDPOINTS();
      //   const pf_response = API_Project.get(
      //     pf_endpoints.PROJECT_GET_FEATURES_BY_PROJECTID + "/" + e.projectId
      //   ).then((pf_response) => {
      //     console.log("project selected features:", pf_response);
      //     if (pf_response.data.isSuccess === true) {
      //       //setAllSelectedProjectFeatures([]);
      //       pf_response.data.result.forEach((feature) => {
      //         selectedProjectFeatures.push({
      //           projectFeatureId: feature.projectFeatureId,
      //           projectFeatureName: feature.projectFeatureName,
      //         });
      //       });
      //       console.log("selectedProjectFeatures: ", selectedProjectFeatures);

      //       //*** Selected in UI ***//
      //       selectedProjectFeatures.forEach((projectfeature) => {
      //         var element = document.getElementById(
      //           projectfeature.projectFeatureId
      //         );
      //         console.log("selected pf:", element);
      //         element.removeAttribute("class");
      //         element.setAttribute("class", "col point-cursor select-pf");
      //       });

      //       setShow(false); //Loading Indicator
      //     } else {
      //       console.log("fail response", pf_response.data.displayMessage);
      //       console.log(pf_response.data.displayMessage);
      //       toast.error(pf_response.data.displayMessage);
      //       setShow(false); //Loading Indicator
      //       return;
      //     }
      //   });
    } catch (err) {
      console.log(err);
    }
  };

  const editProjectFeature = async (e) => {
    try {
      setProjectId(e.projectId);
      setProjectName(e.projectName);
      setProjectPrivacyId(e.projectPrivacyId);
      setProjectTypeId(e.projectTypeId);
      setStartDate(e.startDate);
      setEndDate(e.endDate);
      setDuration(e.duration);
      setClientId(e.clientId);
      setGenres([]);
      //setProjectSaveBtn("Update");
      //setShowProjectFeaturePrompt(true);

      showLoader();

      getGenresByProjectId(e.projectId).then((result) => {
        const gen_list = result.map((item) => {
          return { label: item.projectGenresName, value: item.projectGenresId };
        });
        const projectValues = {
          projectId: e.projectId,
          tenantId: e.tenantId,
          clientId: e.clientId,
          workingTitle: e.projectName,
          broadcastTitle: e.broadcastTitle,
          episodes: 1,
          duration: Number(e.duration),
          txDate: e.txDate,
          startDate: e.startDate,
          endDate: e.endDate,
          genre: gen_list,
          language: {
            label: e?.language?.languageName,
            value: e?.language?.languageId,
          },
          logo: e.projectLogo,
        };
        updateStepData("step_01", projectValues);
        updateStepData("step_02", projectValues);
        setSelectedGenres(gen_list);
      });

      setActiveProjectId(e.projectId);
      setCurrentStep("step_06");
      updateActionsState();
      ModalVisibility.open();

      //******** Get Project assigned Features List ***********//
      // setShow(true);
      // const pf_endpoints = new API_ENDPOINTS();
      // const pf_response = API_Project.get(
      //   pf_endpoints.PROJECT_GET_FEATURES_BY_PROJECTID + "/" + e.projectId
      // ).then((pf_response) => {
      //   console.log("project selected features:", pf_response);
      //   if (pf_response.data.isSuccess === true) {
      //     //setAllSelectedProjectFeatures([]);
      //     pf_response.data.result.forEach((feature) => {
      //       selectedProjectFeatures.push({
      //         projectFeatureId: feature.projectFeatureId,
      //         projectFeatureName: feature.projectFeatureName,
      //       });
      //     });
      //     console.log("selectedProjectFeatures: ", selectedProjectFeatures);

      //     //*** Selected in UI ***//
      //     selectedProjectFeatures.forEach((projectfeature) => {
      //       var element = document.getElementById(
      //         projectfeature.projectFeatureId
      //       );
      //       console.log("selected pf:", element);
      //       if (element !== null) {
      //         element.removeAttribute("class");
      //         element.setAttribute("class", "col point-cursor select-pf");
      //       }
      //     });

      //     setShow(false); //Loading Indicator
      //   } else {
      //     console.log("fail response", pf_response.data.displayMessage);
      //     console.log(pf_response.data.displayMessage);
      //     toast.error(pf_response.data.displayMessage);
      //     setShow(false); //Loading Indicator
      //     return;
      //   }
      // });
    } catch (err) {
      console.log(err);
    }
  };

  const projectBrief = async (e) => {
    try {
      console.log("p id ", e);
      localStorage.setItem("selected_project", JSON.stringify(e));
      // ReactDOM.render(
      //   <ProjectBrief />,
      //   document.getElementById("containerBody")
      // );

      // const path = generatePath(
      //   ROUTES_PATH.ProjectRoutePath.ProjectBrief.path,
      //   {
      //     id: e?.projectId || "-1",
      //   }
      // );

      navigate("/project/brief/" + e?.projectId || -1);
    } catch (err) {
      console.log(err);
    }
  };

  //*****/ GENRES ******//
  const getAndBindGenresList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Tenant.get(
        endpoints.PROJECT_GENRES_GETALL_BY_TENANT
      ).then((response) => {
        console.log("genres:", response);
        if (response.data.isSuccess === true) {
          GenresList = response.data.result;
          const genere_list = GenresList.map((item) => {
            return { label: item.genresName, value: item.genresId };
          });
          setAllGenres(genere_list);
          setShow(false); //Loading Indicator
        } else {
          console.log("fail response:", response);
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  //*****/ CLIENTS *****//
  const getAndBindClientList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API_Tenant.get(
        endpoints.GETALLTENANTCLIENTS + "/1/100"
      ).then((response) => {
        console.log("clients:", response);
        if (response.data.isSuccess === true) {
          ClientList = response.data.result.result;

          const client_list = ClientList.map((item) => {
            return { name: item.clientName, value: item.clientId };
          });

          setAllClients(client_list);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  //*****/ PROJECT PRIVACY ******//
  const getAndBindProjectPrivacyList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.PROJECT_GET_PROJECT_PRIVACY).then(
        (response) => {
          console.log("project privacy list:", response);
          if (response.data.isSuccess === true) {
            ProjectPrivacyList = response.data.result;
            const privacy_list = ProjectPrivacyList.map((item) => {
              return (
                <option value={item.projectPrivacyId}>
                  {item.projectPrivacyName}
                </option>
              );
            });
            setAllProjectPrivacies(privacy_list);
            setShow(false); //Loading Indicator
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator
            return;
          }
        }
      );
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  //*****/ PROJECT TYPES ******//
  const getAndBindProjectTypeList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.PROJECT_GET_PROJECT_TYPES).then(
        (response) => {
          console.log("Project type list:", response);
          if (response.data.isSuccess === true) {
            ProjectTypeList = response.data.result;
            const type_list = ProjectTypeList.map((item) => {
              return (
                <option value={item.projectTypeId}>
                  {item.projectTypeName}
                </option>
              );
            });
            setAllProjectTypes(type_list);
            setShow(false); //Loading Indicator
          } else {
            console.log(response.data.displayMessage);
            toast.error(response.data.displayMessage);
            setShow(false); //Loading Indicator
            return;
          }
        }
      );
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const rowClicked = async (e) => {
    try {
      console.log(e);
    } catch (err) {
      console.log(err);
    }
  };

  const selectProjectFeature = async (item) => {
    try {
      var element = document.getElementById(item.projectFeatureId);

      if (element?.classList?.contains("select-pf")) {
        console.log("Element contains class");
        element.removeAttribute("class");
        element.setAttribute("class", "col point-cursor");
      } else {
        console.log("Element NOT contains class");
        element.removeAttribute("class");
        element.setAttribute("class", "col point-cursor select-pf");
      }
      //////////////////////////////////////////////////////////
      if (containsObject(item, selectedProjectFeatures) === false) {
        selectedProjectFeatures.push({
          projectFeatureId: item.projectFeatureId,
          projectFeatureName: item.projectFeatureName,
        });
      } else {
        var index = selectedProjectFeatures.indexOf(item);
        selectedProjectFeatures.splice(index, 1);
      }
      setAllSelectedProjectFeatures(selectedProjectFeatures);
      console.log("selectedProjectFeatures: ", selectedProjectFeatures);
    } catch (err) {
      console.log(err);
    }
  };

  function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].projectFeatureId === obj.projectFeatureId) {
        return true;
      }
    }

    return false;
  }

  //*****/ PROJECT FEATURES ******//
  const getAndBindProjectFeatures = async (e) => {
    try {
      setShow(true); //Loading Indicator
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API.get(
        endpoints.PROJECT_FEATURES_GETALL_BY_TENANT + "/" + tenantId
      ).then((response) => {
        console.log("Project Features:", response);
        if (response.data.isSuccess === true) {
          ProjectFeatureList = response.data.result;

          const project_feature_list = ProjectFeatureList.map((item) => {
            return (
              <div
                className="col point-cursor"
                id={item.projectFeatureId}
                onClick={() => selectProjectFeature(item)}
              >
                <div className="feature">
                  <div className="purple">
                    <i className="icon icon-lp-star icon-35"></i>
                    <p>{item.projectFeatureName}</p>
                  </div>
                </div>
              </div>
            );
          });

          setAllProjectFeatures(project_feature_list);

          //UI Already Selectd Feature Tiles Un-select
          ProjectFeatureList.forEach((projectfeature) => {
            var element = document.getElementById(
              projectfeature.projectFeatureId
            );

            if (element?.classList?.contains("select-pf")) {
              console.log("Element contains class");
              element.removeAttribute("class");
              element.setAttribute("class", "col point-cursor");
            }
          });

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const addProjectFeatures = async (e) => {
    try {
      console.log(selectedProjectFeatures);
      if (selectedProjectFeatures.length === 0) {
        toast.error("Please select a project feature");
        return;
      }

      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.post(endpoints.PROJECT_FEATURES_ADD, {
        projectId: projectId,
        projectFeatureList: selectedProjectFeatures,
      }).then((response) => {
        console.log("added features:", response);
        if (response.data.isSuccess === true) {
          // toast.success("Project features saved successfully");
          getAndBindProjectList();
          getAndBindProjectFeatures();
          setShowCreateProjectPrompt(false);
          setShowProjectFeaturePrompt(false);
          setProjectId(0);
          setProjectName("");
          setProjectPrivacyId(0);
          setProjectTypeId(0);
          setStartDate("");
          setEndDate("");
          setDuration("");
          setClientId(0);
          setGenres([]);
          setAllSelectedProjectFeatures([]);

          setShow(false); //Loading Indicator off
        } else {
          console.log("fail-response", response);
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const Onboarding = async (e) => {
    try {
      showLoader();

      setGlobalProject(e?.projectId)
        .then((status) => {
          hideLoader();
          localStorage.setItem("selected_project", JSON.stringify(e));
          const { path: mainPath, child } =
            ROUTES_PATH.ProjectRoutePath.Onboarding;
          navigate(`${mainPath}/${child.AllMembers.path}`, { replace: true });
        })
        .catch((e) => {
          hideLoader();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onNavigateProjectSetting = (row) => {
    const pId = row.projectId;
    showLoader();

    setGlobalProject(pId)
      .then((status) => {
        hideLoader();
        const { path: mainPath, children } = ROUTES_PATH.SettingRoutePaths;

        const path = generatePath(`${mainPath}/${children.project.path}`, {
          id: pId,
        });

        navigate(path);
      })
      .catch((e) => {
        hideLoader();
      });
  };

  return (
    <>
      <ProjectContainer id="new-project" className="scroll--y">
        <PageContainer className="wrapper">
          <div className="project__wall">
            <div className="project__wall__header">
              <div className="project__wall__header__inner">
                <div className="title">Projects</div>
                <div className="actions">
                  {newProjectCreateAction?.permission?.canView ? (
                    <UIButton
                      size={UIButton.Sizes.Small}
                      onClick={ModalVisibility.open}
                      startIcon={<IconAdd />}
                    >
                      Create New Project
                    </UIButton>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="project__wall__content">
              <div className="project__wall__content__inner">
                <ProjectListView
                  data={relatedProjects}
                  handleBrief={
                    projectBriefAction.permission.canView
                      ? (row) => projectBrief(row)
                      : null
                  }
                  handleOnboarding={
                    onboardingAction.permission.canView
                      ? (row) => Onboarding(row)
                      : null
                  }
                  handleDelete={
                    deleteAction.permission.canView
                      ? (row) => deleteProject(row)
                      : null
                  }
                  handleFeature={
                    featuresAction.permission.canView
                      ? (row) => editProjectFeature(row)
                      : null
                  }
                  handleEdit={
                    editAction.permission.canView
                      ? (row) => editProject(row)
                      : null
                  }
                  handleProjectSetting={
                    editAction.permission.canView
                      ? onNavigateProjectSetting
                      : null
                  }
                />
              </div>
            </div>
          </div>

          {/* <div className="setting__content__container">
            <div className="setting__container">
              <div className="setting__container__top">
                <div className="setting__container__top__iW">
                  <h4 className="setting__container__top__section__title">
                    Projects
                  </h4>
                </div>
              </div>
              <div className="setting__view">
                <DataTable
                  columns={columns}
                  data={allTenantProjects}
                  onRowClicked={rowClicked}
                  noDataComponent="No projects available."
                />
              </div>
            </div>
          </div> */}
        </PageContainer>
      </ProjectContainer>

      {/* Create New Project  Prompt */}
      <React.Fragment>
        <Modal
          show={showCreateProjectPrompt}
          onClose={() => closeNewProjectPropt}
          id="__project_create_popup"
        >
          <div className="content">
            <div className="menu__content" style={{ minHeight: "90vh" }}>
              <div className="menu__content__panel">
                <div className="menu__content__panel__heading">
                  <h3>Welcome, {USER?.result?.displayName}</h3>
                  <p>to get start, create your first project</p>
                </div>
                <div className="menu__content__panel__input">
                  <form className="row g-3">
                    <div className="col-md-12">
                      <input
                        className="form-control"
                        type="text"
                        id="prject_name"
                        name="project_name"
                        placeholder="Name your project"
                        aria-label="project Name"
                        onChange={(e) => setProjectName(e.target.value)}
                        value={projectName}
                      />
                    </div>
                  </form>
                  <form className="row g-3">
                    <div className="col-md-12">
                      <div className="project_type">
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          style={{ margin: "5px" }}
                          onChange={(e) => setProjectPrivacyId(e.target.value)}
                          value={projectPrivacyId}
                        >
                          <option selected value={0}>
                            Project Privacy
                          </option>
                          {allProjectPrivacies}
                        </select>
                        {/* <i className="icon icon-lp-arrow-down icon-10"></i>
                        <p>Private</p> */}
                      </div>
                    </div>
                  </form>
                  <form className="row g-3">
                    <div className="col-md-6">
                      <div className="start">
                        <input
                          className="form-control start__date"
                          type="date"
                          id="start__date"
                          name="start__date"
                          placeholder="Start"
                          aria-label="Start Date"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={startDate}
                        />

                        <i className="icon icon-bs-calendar icon-15"></i>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="end">
                        <input
                          className="form-control end__date"
                          type="date"
                          id="end__date"
                          name="end__date"
                          placeholder="End"
                          aria-label="End Date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                        />
                        <i className="icon icon-bs-calendar icon-15"></i>
                      </div>
                    </div>
                  </form>
                  <form className="row g-3">
                    <div className="col-md-12">
                      <div className="client">
                        <SelectSearch
                          options={allClients}
                          value={clientId}
                          onChange={setClient}
                          search
                          filterOptions={fuzzySearch}
                          placeholder="Client"
                        />
                        <i className="icon icon-lp-arrow-down icon-10"></i>
                      </div>
                    </div>
                  </form>
                  <form className="row g-3">
                    <div className="col-md-12">
                      <div className="project_type">
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          style={{ margin: "5px" }}
                          onChange={(e) => setProjectTypeId(e.target.value)}
                          value={projectTypeId}
                        >
                          <option selected value={0}>
                            Project Type
                          </option>
                          {allProjectTypes}
                        </select>
                        {/* <i className="icon icon-lp-arrow-down icon-10"></i>
                        <p>Episode</p> */}
                      </div>
                    </div>
                  </form>
                  <form className="row g-3">
                    <div className="col-md-12">
                      <div className="duration">
                        <input
                          className="form-control"
                          type="number"
                          id="duration"
                          name="duration"
                          placeholder="Duration"
                          aria-label="Duration"
                          onChange={(e) => setDuration(e.target.value)}
                          value={duration}
                          min={1}
                          pattern="[0-9]+"
                        />
                        <p>{duration} min</p>
                      </div>
                    </div>
                  </form>
                  <form className="row g-3">
                    <div className="col-md-12">
                      <div className="genres">
                        <div className="">
                          <MultiSelect
                            options={allGenres}
                            value={genres}
                            onChange={setGenres}
                            labelledBy="Genres"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <form
                    className="row g-3"
                    style={{
                      margin: "15px",
                    }}
                  >
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {" "}
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{ marginRight: "10px", width: "50%" }}
                        onClick={() => {
                          closeNewProjectPropt();
                          updateSearchParams();
                        }}
                      >
                        Cancel
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginLeft: "10px", width: "50%" }}
                        onClick={saveProject}
                      >
                        {projectSaveBtn}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
      {/* Project Feature Prompt */}
      <React.Fragment>
        <Modal
          show={showProjectFeaturePrompt}
          onClose={() => setShowProjectFeaturePrompt(false)}
        >
          <div className="content">
            <div className="menu__content" style={{ minHeight: "90vh" }}>
              <div className="menu__content__panel">
                <div className="menu__content__panel__heading">
                  <h3>Select Project Features</h3>
                  <p>which features you wish to use in this project</p>
                </div>
                <div className="menu__content__panel__container">
                  <div className="menu__content__panel__container__scroll">
                    <div className="menu__content__panel__container__scroll__row">
                      {allProjectFeatures}
                    </div>
                  </div>
                </div>
                <form
                  className="row g-3"
                  style={{
                    margin: "15px",
                  }}
                >
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {" "}
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ marginRight: "10px", width: "50%" }}
                      onClick={() => setShowProjectFeaturePrompt(false)}
                    >
                      Cancel
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ marginLeft: "10px", width: "50%" }}
                      onClick={addProjectFeatures}
                    >
                      Apply
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default NewProject;
