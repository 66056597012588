import React from "react";
import { OnboardingActionType } from "../../../../../constant/ActionTypes";
import { ONBOARDING_STATUS } from "../../../../../constant/Status";
import OnboardingItems from "../OnboardingList";
import { openAddToProjectDialog } from "../../../../../shared/components/Modals/Onboarding/AddToProjectDialog/store";

const notAllowStatusToUpdateInitialData = [
  ONBOARDING_STATUS.InvitationRejected,
  ONBOARDING_STATUS.NDARejected,
  ONBOARDING_STATUS.ContractChangeRequested,
  ONBOARDING_STATUS.ContractGenerated,
  ONBOARDING_STATUS.ContractSent,
  ONBOARDING_STATUS.ContractSigned,
  ONBOARDING_STATUS.ContractAccepted,
  ONBOARDING_STATUS.ContractRejected,
  ONBOARDING_STATUS.ContractExecuted,
  ONBOARDING_STATUS.ContractRejectedIsAck,
  ONBOARDING_STATUS.Onboarded,
  ONBOARDING_STATUS.ContractAcceptingByHR,
  ONBOARDING_STATUS.ContractAcceptedByHR,
  ONBOARDING_STATUS.ContractApproved,
  ONBOARDING_STATUS.JoinRequestDeclined,
  ONBOARDING_STATUS.JoinDeclinedIsAcknowledged,
];

const notAllowStatusToRegenerate = [
  ONBOARDING_STATUS.Onboarded,
  ONBOARDING_STATUS.ContractRejected,
  ONBOARDING_STATUS.ContractRejectedIsAck,
  ONBOARDING_STATUS.MemberContractDeclined,
  ONBOARDING_STATUS.ContractAccepted,
  ONBOARDING_STATUS.ContractExecuted,
  ONBOARDING_STATUS.RatesAndPaymentScheduleRequested,
  ONBOARDING_STATUS.RatesAndPaymentScheduleChangeRequested,
  ONBOARDING_STATUS.CommonChangeRequest,
  ONBOARDING_STATUS.MemberRequestedContract,
];

const isDisabledUpdateInitialData = (statusId) =>
  notAllowStatusToUpdateInitialData.includes(statusId);

const HRListItem = (props) => {
  const {
    data: item,
    onActionClick,
    viewType,
    project,
    onNotesMarkAsRead,
  } = props;
  const [editMode, setEditMode] = React.useState(true);

  const onActionChangeHandler = (action) => {
    onActionClick && onActionClick(action);
  };

  const onRegenerateHandler = React.useCallback(() => {
    const _action = {
      type: OnboardingActionType.GENERATE_CONTRACT,
      payload: {
        member: {
          ...item,
          statusId: ONBOARDING_STATUS.ContractRequested,
          isRegenerate: true,
        },
      },
    };

    onActionClick && onActionClick(_action);
  }, [item, onActionClick]);

  const onSecondaryContractGenerateHandler = React.useCallback(() => {
    const _action = {
      type: OnboardingActionType.GENERATE_SECONDARY_CONTRACT,
      payload: {
        member: {
          ...item,
          statusId: ONBOARDING_STATUS.ContractRequested,
          isRegenerate: false,
        },
      },
    };

    onActionClick && onActionClick(_action);
  }, [item, onActionClick]);

  const { statusId } = item;

  // const navigateToDocument = (url) => {
  //   return () => {
  //     window.open(url, "_blank", "noopener,noreferrer");
  //   };
  // };

  const downloadDoc = (docId) => {
    var base_url = window.location.origin;
    window.open(base_url + "/onboardings/2/document-view/" + docId, "_blank");
  };

  // Status of the Button action
  // const actionButtonState = React.useMemo(() => {
  //   let _actionState = {
  //     actionText: "none",
  //     isDisabled: true,
  //   };

  //   // 6
  //   if (statusId === ONBOARDING_STATUS.ContractRequested) {
  //     _actionState = {
  //       actionText: "Generate Contract",
  //       isDisabled: false,
  //     };
  //   }
  //   // 7 View and can document edit also view comments
  //   if (statusId === ONBOARDING_STATUS.ContractChangeRequested) {
  //     _actionState = {
  //       actionText: "View or Generate Contract",
  //       isDisabled: false,
  //     };
  //   }

  //   // 8 Only view
  //   if (
  //     statusId === ONBOARDING_STATUS.ContractGenerated ||
  //     statusId === ONBOARDING_STATUS.ContractAcceptingByHR ||
  //     statusId === ONBOARDING_STATUS.ContractAcceptedByHR ||
  //     statusId === ONBOARDING_STATUS.Onboarded
  //   ) {
  //     _actionState = {
  //       actionText: "View Contract",
  //       isDisabled: false,
  //     };
  //   }

  //   // 10, 11, 13
  //   if (
  //     statusId === ONBOARDING_STATUS.ContractSigned ||
  //     statusId === ONBOARDING_STATUS.ContractExecuted ||
  //     statusId === ONBOARDING_STATUS.ContractAccepted
  //   ) {
  //     _actionState = {
  //       actionText: "Onboard",
  //       isDisabled: false,
  //     };
  //   }

  //   return _actionState;
  // }, [statusId]);

  // [15,12,14,20,11,13]

  const canContractReGenerated =
    ONBOARDING_STATUS.ContractGenerated <= statusId &&
    !notAllowStatusToRegenerate.includes(statusId);

  const menuItems = React.useMemo(() => {
    return [
      {
        eventKey: "re-generate",
        label: " Re-Generate Contract",
        disabled: !canContractReGenerated,
        onClick: canContractReGenerated ? onRegenerateHandler : null,
      },
      {
        eventKey: "secondary-contract-generate",
        label: " Add Contract Addendum",
        disabled: true,
        onClick: onSecondaryContractGenerateHandler,
      },
      {
        eventKey: "update-member-contract-initial-details",
        label: " Update initial Details",
        disabled: isDisabledUpdateInitialData(statusId),
        onClick: !isDisabledUpdateInitialData(statusId)
          ? () => openAddToProjectDialog(item, project, editMode)
          : null,
      },
    ];
  }, [
    canContractReGenerated,
    editMode,
    item,
    onRegenerateHandler,
    onSecondaryContractGenerateHandler,
    project,
    statusId,
  ]);

  const menuItemsWithDocs = [
    ...menuItems,
    ...item?.documents?.map((doc) => {
      if (doc?.documentTypeId === 1) {
        return {
          eventKey: `nda_download`,
          label: `NDA Download`,
          onClick: () => downloadDoc(doc?.userDocumentId || 0),
          disabled: !doc?.userDocumentId,
        };
      }
      if (doc?.documentTypeId === 2) {
        return {
          eventKey: `contract_download`,
          label: `Contract Download`,
          onClick: () => downloadDoc(doc?.userDocumentId || 0),
          disabled: !doc?.userDocumentId,
        };
      }
      return null;
    }),
  ];

  return (
    <OnboardingItems.Item
      data={item}
      viewType={viewType}
      hasActionPermission={true}
      onActionClick={onActionChangeHandler}
      menuItems={menuItemsWithDocs}
      pageType="hr"
      onNotesMarkAsRead={onNotesMarkAsRead}
    />
  );
};

export default HRListItem;
