import React from "react";
import config from "./config";
import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";

const children = config.children;

const ProjectBrief = React.lazy(() => import("./../screens/ProjectBrief"));

export const ChildrenRoutes = [
  /**
   * Project Brief
   */
  {
    id: children.projectBrief.id,
    path: children.projectBrief.path,
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <ProjectBrief />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <BreadcrumbLink label={children.projectBrief.label} />,
    },
    loader: async () => {
      return true;
      // return await permissionLoader(children.projectBrief.id);
    },
  },
];
