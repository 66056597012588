import React from "react";
import "./styles.scss";

import doc from "../../../data/toolbar_customization.json";

import {
  DocumentEditorContainerComponent,
  DocumentEditorComponent,
  Print,
} from "@syncfusion/ej2-react-documenteditor";
import { PageView } from "./styled";

DocumentEditorComponent.Inject(Print);

const DocumentViewNDA = (props) => {
  const { title, url, templateData } = props;

  const containerRef = React.useRef();

  const onLoadDefault = () => {
    containerRef.current.pageOutline = "red";
    containerRef.current.restrictEditing = true;
    containerRef.current.isReadOnly = true;
    containerRef.current.showPropertiesPane = false;
    containerRef.current.enableContextMenu = false;
    containerRef.current.enableToolbar = false;

    containerRef.current.enablePrint = false;

    let sfdt = `{
      "sections": [
          {
              "blocks": [
                  {
                      "inlines": [
                          {
                              "characterFormat": {
                                  "bold": true,
                                  "italic": true
                              },
                              "text": "Hello World"
                          }
                      ]
                  }
              ],
              "headersFooters": {
              }
          }
      ]
    }`;

    // containerRef.current.documentEditor.open(doc);
    // printWindow
    // console.log();

    //  containerRef.current.documentEditor.editor
  };

  const onCreated = () => {
    console.log("done");
    // var windowWidth = window.innerWidth;
    // var windowHeight = window.innerHeight;
    // this.containerRef.current;
    // containerRef.current.height='600px';
    // containerRef.current.documentEditor.height='100%';
    containerRef.current.resize();
  };

  React.useEffect(() => {
    onLoadDefault();
  }, []);

  React.useEffect(() => {
    if (templateData) {
      containerRef.current.documentEditor.open(templateData);
    }
  }, [templateData]);

  return (
    <div className="document">
      <PageView>
        <DocumentEditorContainerComponent
          serviceUrl="https://showruncs.blob.core.windows.net/loop-templates/MailMerge.docx"
          id="containerRef"
          ref={(scope) => {
            containerRef.current = scope;
          }}
          style={{ display: "block" }}
          height={"842px"}
          // minHeight={"100%"}
          locale="en-US"
          width={"100%"}
          enableToolbar={false}
          enablePrint={false}
          isReadOnly={false}
          enableEditor={false}
          enableOptionsPane={false}
          restrictEditing={false}
          showPropertiesPane={false}
          enableContextMenu={false}
          created={onCreated}
          layoutType="Continuous"
        />
      </PageView>
    </div>
  );
};

export default DocumentViewNDA;
