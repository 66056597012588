import React from "react";
import config from "./config";
import BreadcrumbLink from "../../../components/ui-components/Breadcrumb/BreadcrumbLink";
// import { permissionLoader } from "../../../routes/loaders";

const children = config.children;

const OnboardingRoot = React.lazy(() => import(".."));
const AllMembersScreen = React.lazy(() =>
  import("../screens/AllMembersScreen")
);
const ShortlistScreen = React.lazy(() => import("../screens/ShortlistScreen"));
const DocumentViewScreen = React.lazy(() => import("../screens/DocumentView"));
const HRListScreen = React.lazy(() => import("../screens/HRListScreen"));
const NDAChangeRequestScreen = React.lazy(() =>
  import("../screens/NDAChangeRequest")
);

export const ChildrenRoutes = [
  /**
   * Layout type 1
   */
  {
    id: children.layout1.id,
    path: children.layout1.path,
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <OnboardingRoot />
      </React.Suspense>
    ),
    children: [
      /**
       * All Member
       */
      {
        id: children.allMembers.id,
        path: children.allMembers.path,
        element: (
          <React.Suspense fallback={<>Loading...</>}>
            <AllMembersScreen />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <BreadcrumbLink label={children.allMembers.label} />,
        },
        loader: async () => {
          return true;
          // return await permissionLoader(children.planner.id);
        },
      },

      /**
       * Shortlist
       */
      {
        id: children.shortlist.id,
        path: children.shortlist.path,
        element: (
          <React.Suspense fallback={<>Loading...</>}>
            <ShortlistScreen />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <BreadcrumbLink label={children.shortlist.label} />,
        },
        loader: async () => {
          return true;
          // return await permissionLoader(children.planner.id);
        },
      },

      /**
       * HR List
       */
      {
        id: children.hr_list.id,
        path: children.hr_list.path,
        element: (
          <React.Suspense fallback={<>Loading...</>}>
            <HRListScreen />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <BreadcrumbLink label={children.hr_list.label} />,
        },
        loader: async () => {
          return true;
          // return await permissionLoader(children.planner.id);
        },
      },
    ],
  },

  /**
   * Layout type 2
   */
  {
    id: children.layout2.id,
    path: children.layout2.path,
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <OnboardingRoot layoutType={2} />
      </React.Suspense>
    ),
    children: [
      /**
       * NDA Change Request
       */
      {
        id: children.nda_change_request.id,
        path: children.nda_change_request.path,
        element: (
          <React.Suspense fallback={<>Loading...</>}>
            <NDAChangeRequestScreen />
          </React.Suspense>
        ),
        handle: {
          crumb: () => (
            <BreadcrumbLink label={children.nda_change_request.label} />
          ),
        },
        loader: async () => {
          return true;
          // return await permissionLoader(children.planner.id);
        },
      },

      /**
       * Document view
       */
      {
        id: children.docView.id,
        path: children.docView.path,
        element: (
          <React.Suspense fallback={<>Loading...</>}>
            <DocumentViewScreen />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <BreadcrumbLink label={children.docView.label} />,
        },
        loader: async () => {
          return true;
          // return await permissionLoader(children.planner.id);
        },
      },
    ],
  },
];
