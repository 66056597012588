const config = {
  id: "common",
  menuIcon: "menu-budget-01",
  label: "General",
  path: "",
  children: {
    rateAndPayment: {
      id: "rate-card-and-payment-schedule-page",
      path: "rate-and-payment/:projectMemberId/:projectId",
      label: "Rates and Payments",
    },

    viewNDA: {
      id: "common/viewNDA",
      path: "view-nda",
      label: "View NDA",
    },
  },
};

export default config;
