import produce from "immer";
import { create } from "zustand";

let store = (set, get) => ({
  visibility: false,
  meta: null,
});

export const useNotesViewDialog = create(store);

const state = useNotesViewDialog;

export const openNotesDialog = (meta) => {
  state.setState(
    produce((draft) => {
      draft.visibility = true;
      draft.meta = meta;
    })
  );
};

export const hideNotesDialog = () => {
  state.setState(
    produce((draft) => {
      draft.visibility = false;
      draft.meta = null;
    })
  );
};
