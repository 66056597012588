import React from "react";
import { Container } from "./styled";

/**
 * Renders the Version component.
 *
 * This component displays version information for the showrun Frontend,
 * including the current version, build version, dev version, and dev release date.
 *
 * @returns {JSX.Element} A Container element with version information.
 */
const Version = () => {
  return (
    <Container>
      <h1>showrun Frontend</h1>
      <div className="content">
        <h3>Version : 0.4.8 </h3>
        <h3>Build Version : 0.4.8.3 </h3>
        <h3>Dev Version : 0.9.4 </h3>
        <h5>Dev Release Date : 2025/01/24 </h5>
      </div>
      {/* Note
        [MAJOR.MINOR.REVISION.BUILDNUMBER] */}
    </Container>
  );
};

export default Version;
