import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.11123 0C5.50829 0 3.39005 2.11835 3.39005 4.72118C3.39005 6.43542 4.31165 7.93509 5.68241 8.76227C2.95409 9.75655 1 12.3776 1 15.4462C1 15.7525 1.24749 16 1.55382 16C1.86014 16 2.10763 15.7525 2.10763 15.4462C2.10763 12.1355 4.80051 9.44236 8.11146 9.44236C10.7144 9.44236 12.8326 7.32401 12.8326 4.72118C12.8326 2.11835 10.7141 0 8.11123 0ZM8.11123 8.33406C6.11922 8.33406 4.49769 6.71328 4.49769 4.72052C4.49769 2.7285 6.11847 1.10697 8.11123 1.10697C10.1032 1.10697 11.7248 2.72775 11.7248 4.72052C11.7248 6.71253 10.104 8.33406 8.11123 8.33406ZM15.2227 15.4455C15.2227 15.7518 14.9752 15.9993 14.6689 15.9993C14.3625 15.9993 14.1151 15.7518 14.1151 15.4455C14.1151 13.842 13.4903 12.3346 12.3567 11.2002C12.1404 10.9838 12.1404 10.6334 12.3567 10.417C12.573 10.2007 12.9235 10.2007 13.1398 10.417C14.4828 11.76 15.2227 13.5461 15.2227 15.4455Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
