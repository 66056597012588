import Icon from "../Icon";
import IconPDF from "../../icons/Fill/pdf";

function Item({ label }) {
  return (
    <div className="css-flex css-gap-8 css-max-w-[200px] css-overflow-hidden">
      <a
        href="#doc"
        target="_blank"
        className="css-p-8 css-pr-28 css-max-w-full css-bg-primary-100 hover:css-bg-primary-75 css-rounded-8"
        title={label}
      >
        <div className="css-flex css-gap-8 css-items-center css-text-white hover:css-text-white">
          <div className="css-text-white css-flex">
            <Icon icon={<IconPDF />} size={16} inheritParentColor />
          </div>

          <div className="css-font-small-400 css-overflow-hidden">
            <div className="css-truncate">{label}</div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default Item;
