import React from "react";
import Trademark from "../../../../../../common/components/UI/Trademark";

const Header = ({ companyData, status }) => {
  return (
    <div className="css-flex css-px-40 css-py-36 css-items-center css-justify-between css-bg-neutral-10 css-shadow-primary-5">
      <div className="css-flex css-gap-16">
        <Trademark src="https://s3-alpha-sig.figma.com/img/d5fd/9a29/3926b04722ae834e0997afdb4d93db73?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XaE9OgQjTwgpGiUZyOLqR~xj7jof2GSRD1tpYluDBMgA9pi5~e1cJahe6va-B6U8CbRsSMwrL5qtw0bZMZ98x4JFZy-kYyQEhOzWTMFTTL5hkuX8wq7lA2K5yjP~OKvxBDivGZ3dP~AdlYjrwpSWGAb5TAcA4xxCnO-svbIh8cWyfES18yhWVfLw0vimykXgZrD~-sAXPU97DHY~s9nz2QdwVNGgIieUECzjLEXxvagk0lRw2UxK43oMKRBhyubMZf~L7NQHe96eM3XQ0RjBewngjfyHCNcMM5WSd5jmiwVCeMo-JrMGtUWAwuSuUQp1H9t270GskgWTKuUTAjNnRQ__" />
        <div className="css-grid css-text-black">
          <div className="css-font-base-400">Production Company</div>
          <div className="css-font-h4-600">{companyData?.companyName}</div>
        </div>
      </div>

      <div className="css-text-info-200 css-bg-info-50 css-px-16 css-py-8 css-rounded-8">
        <div className="css-font-x-small-600">{status}</div>
      </div>
    </div>
  );
};

export default Header;
