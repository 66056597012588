import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.03378 7.12801C0.464083 7.12801 0 7.5921 0 8.1618V14.9662C0 15.5359 0.464083 16 1.03378 16H12.0886C12.6583 16 13.1223 15.5359 13.1223 14.9662V9.01315L14.6714 8.59388C15.2219 8.44665 15.5452 7.87695 15.398 7.32645L13.6184 0.762076C13.468 0.211578 12.9015 -0.11168 12.351 0.0355464L1.6803 2.92886C1.1298 3.07929 0.803344 3.64579 0.953771 4.19629L1.59709 6.57752L1.74751 7.13121H1.03378V7.12801ZM13.6792 3.43775L2.25 6.53591L1.94915 5.42211L13.3752 2.32396L13.6792 3.43775ZM1.03378 7.76813H12.0886C12.3062 7.76813 12.4822 7.94416 12.4822 8.1618V8.7091C12.4726 8.7475 12.4726 8.78911 12.4822 8.83072V8.97154H0.640114V8.1618C0.640114 7.94416 0.816146 7.76813 1.03378 7.76813ZM0.640114 9.60846H12.479V10.7607H0.640114V9.60846ZM12.0886 15.3567H1.03378C0.816146 15.3567 0.640114 15.1807 0.640114 14.963V11.404H12.479V14.9662C12.4822 15.1807 12.3062 15.3567 12.0886 15.3567ZM14.5018 7.97297L13.1191 8.34743V8.1586C13.1191 7.5889 12.6551 7.12481 12.0854 7.12481H2.51565L13.8457 4.05226L14.777 7.48968C14.8347 7.70092 14.7098 7.91535 14.5018 7.97297ZM1.60989 3.72581C1.6611 3.63619 1.74751 3.56898 1.84673 3.54337L12.5174 0.646856C12.7255 0.592446 12.9431 0.714068 12.9975 0.922105L13.2088 1.70304L1.78272 4.8012L1.57148 4.02026C1.54268 3.92104 1.55548 3.81542 1.60989 3.72581Z"
      fill="currentColor"
    />
    <path
      d="M10.5843 12.1401C10.3154 12.1401 10.0562 12.2361 9.84496 12.3962C9.63692 12.2393 9.38088 12.1433 9.10243 12.1433C8.42071 12.1433 7.86381 12.697 7.86381 13.3819C7.86381 14.0637 8.41751 14.6206 9.10243 14.6206C9.38088 14.6206 9.63692 14.5245 9.84496 14.3677C10.0562 14.5277 10.3154 14.6206 10.5843 14.6206C11.266 14.6206 11.8197 14.0669 11.8197 13.3851C11.8197 12.697 11.266 12.1401 10.5843 12.1401ZM9.10243 13.9772C8.77277 13.9772 8.50392 13.7084 8.50392 13.3787C8.50392 13.0491 8.77277 12.7802 9.10243 12.7802C9.43209 12.7802 9.70093 13.0491 9.70093 13.3787C9.70093 13.7084 9.43209 13.9772 9.10243 13.9772ZM10.5843 13.9772C10.4595 13.9772 10.341 13.9356 10.2418 13.862C10.3058 13.7148 10.341 13.5484 10.341 13.3787C10.341 13.2059 10.3058 13.0427 10.2418 12.8954C10.341 12.825 10.4595 12.7802 10.5843 12.7802C10.914 12.7802 11.1796 13.0491 11.1796 13.3787C11.1796 13.7116 10.914 13.9772 10.5843 13.9772Z"
      fill="currentColor"
    />
    <path
      d="M3.78308 13.0587H1.62269C1.44666 13.0587 1.30263 13.2027 1.30263 13.3787C1.30263 13.5548 1.44666 13.6988 1.62269 13.6988H3.78308C3.95911 13.6988 4.10313 13.5548 4.10313 13.3787C4.10313 13.2027 3.95911 13.0587 3.78308 13.0587Z"
      fill="currentColor"
    />
    <path
      d="M5.32895 13.0587H4.80406C4.62803 13.0587 4.484 13.2027 4.484 13.3787C4.484 13.5548 4.62803 13.6988 4.80406 13.6988H5.32895C5.50498 13.6988 5.64901 13.5548 5.64901 13.3787C5.64901 13.2027 5.50818 13.0587 5.32895 13.0587Z"
      fill="currentColor"
    />
    <path
      d="M6.91964 13.0587H6.39474C6.21871 13.0587 6.07469 13.2027 6.07469 13.3787C6.07469 13.5548 6.21871 13.6988 6.39474 13.6988H6.91964C7.09567 13.6988 7.23969 13.5548 7.23969 13.3787C7.23969 13.2027 7.09567 13.0587 6.91964 13.0587Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
